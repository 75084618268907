import { ReactElement, useState } from "react";
import styled from "styled-components";
import ModalCloseIcon from "../modalCloseIcon/ModalCloseIcon";
import { useAdPublicTemplateInfoQuery } from "../../../../query/ad/publicTemplate/useAdPublicTemplateInfoQuery";
import TemplateInfoModalContents from "./components/templateInfoModalContents/TemplateInfoModalContents";
import TemplateInfoModalPreview from "./components/TemplateInfoModalPreview";
import { PathAfterResolveType } from "../../../../hooks/modal/useOpenModal";
import { useGetMyAdOfferInfo } from "../../../../query/my/myAd/useGetMyAdOfferInfo";
import { DEFAULT_TEMPLATE_INFO_TYPE_DATA } from "../../../../types/ad/templateInfo/TemplateInfoType";
import { changeOfferSpaceListToTemplateSpaceList } from "../../../../types/ad/adMy/myAd__sale/myAdOfferInfo/MyAdOfferInfoOfferSpaceType";

export const TemplateInfoModalWrapper = styled.div<{
    isPublicTemplate: boolean;
}>`
    width: 592px;
    text-align: center;
    padding: ${({ isPublicTemplate }) =>
        isPublicTemplate ? "24px 0" : "24px 0 0 0"};

    @media ${(props) => props.theme.mobileL} {
        padding: 0;
        width: 100%;
    }

    transition: height 300ms ease;
`;

export const TEMPLATE_INFO_MODAL_CONTENTS = "contents";
export const TEMPLATE_INFO_MODAL_PREVIEW = "preview";

export const TEMPLATE_RESULT_TYPE_PRESV = "presv";
export const TEMPLATE_RESULT_TYPE_PUBLIC = "public";

export type TemplateInfoModalTargetInfoType = {
    templateIdx?: string;
    adIdx?: string;
    offerIdx?: string;
};

export type TemplateResultType = "presv" | "public";

type Props = {
    resolve: (value: PathAfterResolveType | boolean) => void;
    targetInfo: TemplateInfoModalTargetInfoType;
    searchInput?: string;
    setSearchInput?: (value: string) => void;
    templateResultType: TemplateResultType;
};

const TemplateInfoModal = (props: Props): ReactElement | null => {
    const { data: rawPublicTemplateData } = useAdPublicTemplateInfoQuery(
        props.targetInfo.templateIdx || "",
        {
            enabled:
                typeof props.targetInfo.templateIdx !== "undefined" &&
                props.templateResultType === TEMPLATE_RESULT_TYPE_PUBLIC,
            cacheTime: 60000,
            staleTime: 500,
        },
    );

    const { data: rawOfferData } = useGetMyAdOfferInfo(
        props.targetInfo.adIdx || "",
        props.targetInfo.offerIdx || "",
        typeof props.targetInfo.adIdx !== "undefined" &&
            typeof props.targetInfo.offerIdx !== "undefined" &&
            props.templateResultType === TEMPLATE_RESULT_TYPE_PRESV,
    );
    const isPublicTemplate =
        props.templateResultType === TEMPLATE_RESULT_TYPE_PUBLIC;

    const templateInfo = isPublicTemplate
        ? rawPublicTemplateData.templateInfo
        : {
              ...DEFAULT_TEMPLATE_INFO_TYPE_DATA,
              user_idx: rawOfferData.templateInfo.user_idx,
              user_nick: rawOfferData.templateInfo.user_nick,
              user_influencer_bool:
                  rawOfferData.templateInfo.user_influencer_bool,
              template_name: rawOfferData.offerInfo.template_name,
              template_description: rawOfferData.offerInfo.template_description,
              endPoint: rawOfferData.offerInfo.endPoint,
              broad_live_redirect_bool:
                  rawOfferData.offerInfo.broad_live_redirect_user_id !== "",
              broad_live_redirect_user_id:
                  rawOfferData.offerInfo.broad_live_redirect_user_id,
              template_success_count:
                  rawOfferData.templateInfo.template_success_count,
              template_space: changeOfferSpaceListToTemplateSpaceList(
                  rawOfferData.offerInfo.offer_space,
              ),
              created_at: rawOfferData.templateInfo.created_at,
          };

    const brandInfo = isPublicTemplate ? rawPublicTemplateData.brandInfo : null;

    const campaignInfo = isPublicTemplate
        ? rawPublicTemplateData.campaignInfo
        : null;

    const [pageType, setPageType] = useState(TEMPLATE_INFO_MODAL_CONTENTS);

    return (
        <TemplateInfoModalWrapper isPublicTemplate={isPublicTemplate}>
            <ModalCloseIcon resolve={props.resolve} />

            {pageType === TEMPLATE_INFO_MODAL_CONTENTS && (
                <TemplateInfoModalContents
                    resolve={props.resolve}
                    templateInfo={templateInfo}
                    brandInfo={brandInfo}
                    campaignInfo={campaignInfo}
                    templateResultType={props.templateResultType}
                    pageType={pageType}
                    setPageType={setPageType}
                    searchInput={props.searchInput}
                    setSearchInput={props.setSearchInput}
                />
            )}

            {pageType === TEMPLATE_INFO_MODAL_PREVIEW && (
                <TemplateInfoModalPreview
                    templateInfo={templateInfo}
                    setPageType={setPageType}
                />
            )}
        </TemplateInfoModalWrapper>
    );
};

export default TemplateInfoModal;
