export const INTRO_PAGE_REG_EXP = /^\/intro/;

export const AD_BID_PAGE_REG_EXP = /\/ad\/\d+\/date\/\d{6}$/;

export const AD_BIDING_PAGE_REG_EXP = /\/ad\/\d+\/date\/\d{6}\/bid/;

export const AD_BUYING_PAGE_REG_EXP = /\/ad\/\d+\/date\/\d{6}\/buy/;

export const AD_BIDING_COMPLETE_PAGE_REG_EXP =
    /\/ad\/\d+\/date\/\d{6}\/offer\/\d+\/complete/;

export const AD_MANAGE_AD_REGISTER_PAGE_REG_EXP = /\/ad\/register\/\d+/;

export const AD_MANAGE_AD_ADJUST_PAGE_REG_EXP = /\/ad\/adjust\/\d+/;

export const AD_MANAGE_TEMPLATE_REGISTER_PAGE_REG_EXP =
    /\/ad\/template-register\/\d+/;

export const AD_MANAGE_TEMPLATE_ADJUST_PAGE_REG_EXP =
    /\/ad\/template-adjust\/\d+/;

export const PROJECT_PAGE_REG_EXP = /\/market\/\d+$/;

export const ITEM_PAGE_REG_EXP = /\/market\/\d+\/\d+/;

export const POINT_PAGE_REG_EXP = /\/market\/point\/\d+/;

export const WALLET_PAGE_REG_EXP = /\/wallet\/[a-zA-Z0-9]+/;

export const HOME_PAGE_PATHNAME = "/";

export const AD_HOME_PAGE_PATHNAME = "/ad";

export const AD_MANAGE_PAGE_PATHNAME = "/ad/manage";

export const AD_MANAGE_SALE = "/ad/manage/sale";

export const AD_MANAGE_SALE_SCHEDULE = "/ad/manage/sale/schedule";

export const AD_MANAGE_SALE_HISTORY = "/ad/manage/sale/history/list";

export const AD_MANAGE_SALE_ANALYSIS = "/ad/manage/sale/history/analysis";

export const AD_MANAGE_BUY_MY_TEMPLATES = "/ad/manage/buy/my/templates";

export const AD_MANAGE_BUY_PUBLIC_TEMPLATES = "/ad/manage/buy/public/templates";

export const AD_MANAGE_BUY_HISTORY = "/ad/manage/buy/history/list";

export const AD_MANAGE_BUY_ANALYSIS = "/ad/manage/buy/history/analysis";

export const MARKET_HOME_PAGE_PATHNAME = "/market";

export const MARKET_ITEM_MANAGE_PAGE_PATHNAME = "/market/manage/item";

export const MARKET_POINT_MANAGE_PAGE_PATHNAME = "/market/manage/point";

export const WALLET_POINT_AND_TOKEN_PAGE_PATHNAME = "/wallet";

export const WALLET_MERGE_PAGE_PATHNAME = "/wallet/merge";

export const MY_PROFILE_PAGE_PATHNAME = "/my";

export const MY_PAYMENTS_PAGE_PATHNAME = "/my/payments";

export const MY_NOTIFICATION_SETTING_PAGE_PATHNAME = "/my/notification";

export const MY_ORDER_HISTORY_PAGE_PATHNAME = "/my/order-history";
