import { ReactElement } from "react";
import styled from "styled-components";
import ModalCloseIcon from "../modalCloseIcon/ModalCloseIcon";
import {
    ModalButton,
    ModalButtonContainer,
    ModalContainer,
    ModalDesc,
    ModalTitle,
} from "../ModalStyle";
import { CommonBtnStringType } from "../../commonStyle/CommonButtonStyle";

export const CommonAlertModalContainer = styled.div``;

export type CommonAlertModalProps = {
    resolve: (value: boolean) => void;
    title?: string;
    desc: string | ReactElement;
    buttonTitle?: string;
    btnType?: CommonBtnStringType;
    hasCloseBtn: boolean;
};

const CommonAlertModal = (
    props: CommonAlertModalProps,
): ReactElement | null => {
    const hasTitle = typeof props.title !== "undefined" && props.title !== "";
    const hasButtonTitle =
        typeof props.buttonTitle !== "undefined" && props.buttonTitle !== "";

    return (
        <ModalContainer isNoPadding={false}>
            {props.hasCloseBtn && <ModalCloseIcon resolve={props.resolve} />}

            {hasTitle && <ModalTitle>{props.title}</ModalTitle>}
            <ModalDesc
                style={{
                    marginTop: hasTitle ? "16px" : "8px",
                }}
            >
                {props.desc}
            </ModalDesc>
            <ModalButtonContainer>
                <ModalButton
                    btnType={props.btnType ? props.btnType : "filled-primary"}
                    onClick={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        props.resolve(true);
                    }}
                >
                    {hasButtonTitle ? props.buttonTitle : "확인"}
                </ModalButton>
            </ModalButtonContainer>
        </ModalContainer>
    );
};

export default CommonAlertModal;

CommonAlertModal.defaultProps = {
    hasCloseBtn: true,
};
