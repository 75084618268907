import { ReactElement } from "react";
import styled from "styled-components";
import { TemplateInfoSpaceTypeWithIsSelected } from "../TemplateInfoModalTemplateSpaceInfo";

export const TemplateInfoModalTemplateSpaceInfoHeaderContainer = styled.div`
    width: 168px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;

    @media ${(props) => props.theme.mobileL} {
        flex-direction: row;
        width: calc(100% - 16px);
        margin-left: 16px;
        gap: 8px;
        overflow: scroll;
        &::-webkit-scrollbar {
            display: none;
        }

        scrollbar-width: none;
        -ms-overflow-style: none;
    }
`;

export const TemplateInfoModalTemplateSpaceInfoHeaderTemplateSpaceListItem = styled.div<{
    isSelected: boolean;
}>`
    display: flex;
    height: 52px;
    padding: 0 14px;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    gap: 10px;
    border-radius: 12px;
    cursor: pointer;
    background: ${({ isSelected }) => (isSelected ? "#FBF2FC" : "transparent")};
    color: ${({ isSelected }) => (isSelected ? "#FA54FA" : "#000")};
    font-size: 14px;
    font-style: normal;
    font-weight: ${({ isSelected }) => (isSelected ? "500" : "400")};
    line-height: 140%; /* 19.6px */
    transition: all 300ms ease;
    word-break: keep-all;
    flex-shrink: 0;

    @media ${(props) => props.theme.mobileL} {
        height: 46px;
        font-size: 13px;
        background: ${({ isSelected }) => (isSelected ? "#FBF2FC" : "#F5F5F7")};
        border: ${({ isSelected }) =>
            isSelected ? "1px solid #FA54FA" : "1px solid #F5F5F7"};
    }
`;

type Props = {
    templateSpaceList: TemplateInfoSpaceTypeWithIsSelected[];
    setTemplateSpaceList: (
        value: TemplateInfoSpaceTypeWithIsSelected[],
    ) => void;
};

const TemplateInfoModalTemplateSpaceInfoHeader = (
    props: Props,
): ReactElement | null => {
    return (
        <TemplateInfoModalTemplateSpaceInfoHeaderContainer>
            {props.templateSpaceList.map((templateSpace, templateSpaceIdx) => {
                return (
                    <TemplateInfoModalTemplateSpaceInfoHeaderTemplateSpaceListItem
                        isSelected={templateSpace.isSelected}
                        key={templateSpace.template_space_idx}
                        onClick={() => {
                            props.setTemplateSpaceList(
                                props.templateSpaceList.map(
                                    (
                                        innerTemplateSpace,
                                        innerTemplateSpaceIdx,
                                    ) => {
                                        return {
                                            ...innerTemplateSpace,
                                            isSelected:
                                                templateSpaceIdx ===
                                                innerTemplateSpaceIdx,
                                        };
                                    },
                                ),
                            );
                        }}
                    >
                        {templateSpace.presv_campaign_space_name}
                    </TemplateInfoModalTemplateSpaceInfoHeaderTemplateSpaceListItem>
                );
            })}
        </TemplateInfoModalTemplateSpaceInfoHeaderContainer>
    );
};

export default TemplateInfoModalTemplateSpaceInfoHeader;
