import { PathAfterResolveType, useOpenModal } from "../useOpenModal";
import AdBidingSelectTemplateModal from "../../../components/ad/adBiding/components/AdBidingSelectTemplate/components/AdBidingSelectTemplateModal/AdBidingSelectTemplateModal";
import { AD_BIDING_SELECT_IMAGE_MODAL_ID } from "../../../components/ad/adBiding/components/AdBidingSelectTemplate/AdBidingSelectTemplate";
import { TemplateInfoType } from "../../../types/ad/templateInfo/TemplateInfoType";

export function useOpenAdBidingSelectTemplateModal() {
    const { openModalWithReturnValue } = useOpenModal();

    const openAdBidingSelectTemplateModal = async (
        campaignIdx: string,
        adIdx: string,
        setSelectedTemplate: (value: TemplateInfoType | null) => void,
    ): Promise<PathAfterResolveType | boolean> => {
        return await openModalWithReturnValue(
            AD_BIDING_SELECT_IMAGE_MODAL_ID,
            "bottomSheet",
            (resolve) => {
                return (
                    <AdBidingSelectTemplateModal
                        resolve={resolve}
                        campaignIdx={campaignIdx}
                        adIdx={adIdx}
                        setSelectedTemplate={setSelectedTemplate}
                    />
                );
            },
        );
    };

    return {
        openAdBidingSelectTemplateModal,
    };
}
