import React, { ReactElement } from "react";
import styled from "styled-components";
import ApiResBoundary from "../../apiResBoundary/ApiResBoundary";
import Spinner from "../../commonUnitComponent/spinner/Spinner";
import MiniProfileModalUserInfo from "./components/MiniProfileModalUserInfo";
import MiniProfileModalBrandList from "./components/MiniProfileModalBrandList";
import MiniProfileModalTitleItemList from "./components/MiniProfileModalTitleItemList";
import { CarouselDefaultArrowButton } from "../../commonUnitComponent/carousel/CarouselStyle";
import { URL_CONSTANTS } from "../../../../constants/UrlConstants";
import { useGetUserProfileQuery } from "../../../../query/user/useGetUserProfileQuery";
import { ModalButton, ModalButtonContainer } from "../ModalStyle";
import { PathAfterResolveType } from "../../../../hooks/modal/useOpenModal";

export const MiniProfileModalContainer = styled.div`
    position: relative;
    width: 352px;
    overflow: hidden;
    min-height: 258px;
    transition: all 300ms ease;
    @media ${(props) => props.theme.mobileL} {
        width: 100%;
        overflow: visible;
    }
`;

export const MiniProfileModalBrandIdsContainer = styled.div``;

export const MiniProfileModalUserCarouselsContainer = styled.div<{
    isNoTitleList: boolean;
}>`
    width: calc(100% + 6px);
    margin-top: 12px;
    transition: all 300mse ease;
    min-height: ${({ isNoTitleList }) => (isNoTitleList ? "46px" : "186px")};
    @media ${(props) => props.theme.mobileL} {
        width: calc(100%);
    }
`;

export const MiniProfileModalBrandListOneItemContainer = styled.div`
    width: 50%;
`;

export const MiniProfileModalBrandListDoubleItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const MiniProfileModalTitleItemContainer = styled.div`
    margin-top: 24px;
    width: 100%;
    @media ${(props) => props.theme.mobileL} {
        width: calc(100% + 16px);
    }
`;

export const MiniProfileModalUserItemCarouselArrowButton = styled(
    CarouselDefaultArrowButton,
)<{
    direction: string;
}>`
    width: 32px;
    height: 32px;
    top: calc(50% - 16px);
    ${({ direction }) => {
        let size = -2;
        return direction === "left"
            ? { left: -size + "px" }
            : { right: -size + 8 + "px" };
    }}
    border: 1px solid #e5e5e5;
    background-color: #ffffff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 60px;
    background-image: ${({ direction }) => {
        return (
            "url(" +
            URL_CONSTANTS.ASSET_URL +
            "/icon/icon-carousel-arrow-" +
            direction +
            ".png)"
        );
    }};
    background-size: 8px 14px;
    background-position: center;

    @media ${(props) => props.theme.mobileL} {
        display: none;
    }
`;

export const MiniProfileModalButtonContainer = styled.div`
    margin-top: 24px;
`;

export const MiniProfileLoadingContainer = styled(MiniProfileModalContainer)`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 390px;
    position: absolute;
`;

type Props = {
    userIdx: string;
    resolve: (value: boolean | PathAfterResolveType) => void;
    isNestedModal?: boolean;
    isOpenBlankCollection?: boolean;
};

const MiniProfileModal = (props: Props): ReactElement | null => {
    const {
        data: rawUserProfileData,
        isLoading: isRawUserProfileDataLoading,
        error: rawUserProfileDataError,
        refetch: refetchUserProfileData,
    } = useGetUserProfileQuery(props.userIdx, {
        enabled: true,
    });

    return (
        <MiniProfileModalContainer>
            <ApiResBoundary
                isLoading={isRawUserProfileDataLoading}
                error={rawUserProfileDataError}
                isNoData={rawUserProfileData.userInfo.user_idx === "0"}
                isAbsoluteLoading={true}
                loadingFallback={
                    <MiniProfileLoadingContainer>
                        <Spinner />
                    </MiniProfileLoadingContainer>
                }
            >
                <>
                    <MiniProfileModalUserInfo
                        data={rawUserProfileData}
                        refetchUserProfileData={refetchUserProfileData}
                    />
                    <MiniProfileModalUserCarouselsContainer
                        isNoTitleList={
                            rawUserProfileData.titleList.itemList.length === 0
                        }
                    >
                        <MiniProfileModalBrandList data={rawUserProfileData} />
                        {rawUserProfileData.titleList.itemList.length > 0 && (
                            <MiniProfileModalTitleItemList
                                data={rawUserProfileData}
                            />
                        )}
                    </MiniProfileModalUserCarouselsContainer>

                    <MiniProfileModalButtonContainer>
                        <ModalButtonContainer>
                            <ModalButton
                                btnType={"filled-secondary"}
                                onClick={() => {
                                    props.resolve(false);
                                }}
                            >
                                취소
                            </ModalButton>

                            <ModalButton
                                btnType={"filled-primary"}
                                onClick={() => {
                                    if (
                                        typeof props.isOpenBlankCollection ===
                                            "undefined" ||
                                        props.isOpenBlankCollection === false
                                    ) {
                                        props.resolve(
                                            props.isNestedModal
                                                ? {
                                                      pathAfterNestedResolve: `/wallet/${rawUserProfileData.userInfo.user_id}`,
                                                  }
                                                : {
                                                      pathAfterResolve: `/wallet/${rawUserProfileData.userInfo.user_id}`,
                                                  },
                                        );
                                    } else {
                                        window.open(
                                            `/wallet/${rawUserProfileData.userInfo.user_id}`,
                                            "_blank",
                                        );
                                    }
                                }}
                            >
                                컬렉션으로 이동
                            </ModalButton>
                        </ModalButtonContainer>
                    </MiniProfileModalButtonContainer>
                </>
            </ApiResBoundary>
        </MiniProfileModalContainer>
    );
};

export default MiniProfileModal;
