import { useOpenModal } from "../useOpenModal";
import MiniProfileModal from "../../../components/common/modal/miniProfileModal/MiniProfileModal";
import React from "react";
import { useNavigate } from "react-router-dom";

export function useOpenMiniProfileModal(
    hasPrevResolve?: boolean,
    isOpenBlankCollection?: boolean,
) {
    const { openModalWithReturnValue } = useOpenModal();
    const navigate = useNavigate();

    return {
        openMiniProfileModal: async (
            userIdx: string,
            isNestedModal?: boolean,
        ) => {
            return await openModalWithReturnValue(
                "mini-profile-modal-" + userIdx,
                "bottomSheet",
                (resolve) => {
                    return (
                        <MiniProfileModal
                            resolve={resolve}
                            userIdx={userIdx}
                            isNestedModal={isNestedModal}
                            isOpenBlankCollection={isOpenBlankCollection}
                        />
                    );
                },
            );
        },
    };
}
