import React, { ReactElement } from "react";
import styled from "styled-components";
import { format } from "date-fns";
import Scrollbar from "../../../../../../common/commonUiComponent/scrollbar/Scrollbar";
import ModalCloseIcon from "../../../../../../common/modal/modalCloseIcon/ModalCloseIcon";
import { ModalTitle } from "../../../../../../common/modal/ModalStyle";
import {
    convertSafariAdSalesDate,
    numberToStringWithComma,
} from "../../../../../../../utils/Utils";
import {
    CommonButtonStyle,
    CommonButtonType,
} from "../../../../../../common/commonStyle/CommonButtonStyle";
import viewHeightAtom from "../../../../../../../store/scroll/viewHeightAtom";
import { useAtom } from "jotai";
import {
    MyAdAnalysisAdSpaceType,
    MyAdAnalysisListItemType,
} from "../../../../../../../query/my/myAd/useGetMyAdAnalysisListQuery";
import AdManageCommonAnalysisTableModalSpaceInfoRow from "./components/AdManageCommonAnalysisTableModalSpaceInfoRow";
import { Link } from "react-router-dom";
import { AD_BUY_ANALYSIS } from "../../../../_adManageLayout/AdManageTableContentsLayout";
import { SCROLL_BAR_NORMAL_TYPE } from "../../../../../../common/commonUiComponent/scrollbar/ScrollbarType";

export const AdManageCommonAnalysisTableModalContainer = styled.div`
    width: 432px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media ${(props) => props.theme.mobileL} {
        width: 100%;
    }
`;

export const AdManageCommonAnalysisTableModalAdActualTime = styled.div`
    color: #555;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
    margin-top: 14px;
`;

export const AdManageCommonAnalysisTableModalAdTime = styled.div`
    color: #999;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */
    margin-top: 8px;
`;

export const AdManageCommonAnalysisTableModalHeader = styled.div`
    display: flex;
    width: calc(100% - 20px);
    margin-top: 24px;
    border-radius: 8px;
    background: var(
        --Light-Gray,
        linear-gradient(0deg, #f5f5f7 0%, #f5f5f7 100%),
        #eaf2fe
    );
    padding: 10px;
    gap: 8px;
`;

export const AdManageCommonAnalysisTableModalSpaceAnalysisListContainer = styled.div<{
    vh: number;
}>`
    position: relative;
    height: ${({ vh }) => "calc(" + 100 * vh + "px - 318px)"};
    max-height: 388px;
    width: 100%;

    @media ${(props) => props.theme.mobileL} {
        height: ${({ vh }) => "calc(" + 100 * vh + "px - 324px)"};
        max-height: ${({ vh }) => "calc(" + 100 * vh + "px - 364px)"};
    }
`;

export const AdManageCommonAnalysisTableModalSpaceAnalysisList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const AdManageCommonAnalysisTableModalButtonContainer = styled.div`
    width: calc(100%);
    padding: 16px 24px 0 24px;

    border-top: 1px #ebebeb solid;
    display: flex;
    gap: 10px;

    @media ${(props) => props.theme.mobileL} {
        padding: 16px 16px 0 16px;
    }
`;

export const AdManageCommonAnalysisTableModalButtonLink = styled(Link)`
    width: 100%;
`;

export const AdManageCommonAnalysisTableModalButton = styled.div<CommonButtonType>`
    ${CommonButtonStyle};
    width: 100%;
`;

export const getMyAnalysisModalInfo = (target: MyAdAnalysisListItemType) => {
    return {
        totalClickCnt: target.total_click_count,
        totalExposeCnt: target.total_impression_count,
        adSpaceAnalysisList: target.advertisement_space,
        campaignName: target.campaignInfo.campaign_name,
        adSalesDate: convertSafariAdSalesDate(
            target.advertisementDailyInfo.ad_sales_date,
        ),
        adIdx: target.advertisementInfo.ad_idx,
        adOfferIdx:
            target.advertisementDailyInfo.otherOfferSuccessInfo?.offer_idx ||
            "0",
        templateIdx: target.advertisementDailyInfo.template_idx || "0",
    };
};

export type MyAnalysisModalProps = {
    totalClickCnt: number;
    totalExposeCnt: number;
    adSpaceAnalysisList: MyAdAnalysisAdSpaceType[];
    campaignName: string;
    adSalesDate: string;
    adIdx: string;
    adOfferIdx: string;
    templateIdx: string;
};

type Props = {
    type: string;
    resolve: (value: boolean) => void;
    myAnalysisModalInfo: MyAnalysisModalProps;
};

const AdManageCommonAnalysisTableModal = (
    props: Props,
): ReactElement | null => {
    const [vh] = useAtom(viewHeightAtom);

    return (
        <AdManageCommonAnalysisTableModalContainer>
            <ModalCloseIcon resolve={props.resolve} />
            <ModalTitle>{props.myAnalysisModalInfo.campaignName}</ModalTitle>
            <AdManageCommonAnalysisTableModalAdActualTime>
                실제 :{" "}
                {format(
                    new Date(props.myAnalysisModalInfo.adSalesDate),
                    "yyyy-MM-dd",
                )}
            </AdManageCommonAnalysisTableModalAdActualTime>

            <AdManageCommonAnalysisTableModalHeader>
                <AdManageCommonAnalysisTableModalSpaceInfoRow
                    isBold={false}
                    isHeader={true}
                    title={"광고지면"}
                    clickCnt={"클릭수"}
                    exposeCnt={"노출수"}
                    rate={"비율(%)"}
                />
            </AdManageCommonAnalysisTableModalHeader>
            <AdManageCommonAnalysisTableModalSpaceAnalysisListContainer vh={vh}>
                <Scrollbar
                    isThin={true}
                    type={SCROLL_BAR_NORMAL_TYPE}
                    id={
                        "ad-manage-common-analysis-table-modal-space-analysis-list"
                    }
                >
                    <AdManageCommonAnalysisTableModalSpaceAnalysisList>
                        <AdManageCommonAnalysisTableModalSpaceInfoRow
                            isBold={true}
                            isHeader={false}
                            title={"전체"}
                            clickCnt={numberToStringWithComma(
                                props.myAnalysisModalInfo.totalClickCnt,
                            )}
                            exposeCnt={numberToStringWithComma(
                                props.myAnalysisModalInfo.totalExposeCnt,
                            )}
                            rate={
                                props.myAnalysisModalInfo.totalExposeCnt !== 0
                                    ? Math.round(
                                          (props.myAnalysisModalInfo
                                              .totalClickCnt /
                                              props.myAnalysisModalInfo
                                                  .totalExposeCnt) *
                                              10000,
                                      ) /
                                          100 +
                                      "%"
                                    : "-"
                            }
                        />

                        {props.myAnalysisModalInfo.adSpaceAnalysisList.map(
                            (item, idx) => {
                                return (
                                    <AdManageCommonAnalysisTableModalSpaceInfoRow
                                        key={idx}
                                        isBold={false}
                                        isHeader={false}
                                        title={item.presv_campaign_space_name}
                                        clickCnt={numberToStringWithComma(
                                            item.total_click_cnt,
                                        )}
                                        exposeCnt={numberToStringWithComma(
                                            item.impression_cnt,
                                        )}
                                        rate={
                                            item.impression_cnt !== 0
                                                ? Math.round(
                                                      (item.total_click_cnt /
                                                          item.impression_cnt) *
                                                          10000,
                                                  ) /
                                                      100 +
                                                  "%"
                                                : "-"
                                        }
                                    />
                                );
                            },
                        )}
                    </AdManageCommonAnalysisTableModalSpaceAnalysisList>
                </Scrollbar>
            </AdManageCommonAnalysisTableModalSpaceAnalysisListContainer>
            <AdManageCommonAnalysisTableModalButtonContainer>
                <AdManageCommonAnalysisTableModalButton
                    btnType={"filled-secondary"}
                    onClick={() => {
                        props.resolve(false);
                    }}
                >
                    닫기
                </AdManageCommonAnalysisTableModalButton>
                <AdManageCommonAnalysisTableModalButtonLink
                    to={
                        props.type === AD_BUY_ANALYSIS
                            ? `/ad/template/${props.myAnalysisModalInfo.templateIdx}`
                            : `/ad/${props.myAnalysisModalInfo.adIdx}/offer/${props.myAnalysisModalInfo.adOfferIdx}/template`
                    }
                    target={"_blank"}
                    rel="noopener noreferrer"
                >
                    <AdManageCommonAnalysisTableModalButton
                        btnType={"filled-primary"}
                    >
                        템플릿 정보 보기
                    </AdManageCommonAnalysisTableModalButton>
                </AdManageCommonAnalysisTableModalButtonLink>
            </AdManageCommonAnalysisTableModalButtonContainer>
        </AdManageCommonAnalysisTableModalContainer>
    );
};

export default AdManageCommonAnalysisTableModal;
