import { useAtom } from "jotai";
import { ReactElement, useEffect } from "react";
import styled from "styled-components";
import {
    CoachMarkInfoAtom,
    CoachMarkListItemType,
} from "../../../../store/ui/coachMarkInfoAtom";

export const CoachMarkContainer = styled.div`
    position: absolute;
    z-index: 2333;
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;
    background: #8c56ff;
    min-width: 268px;
    top: 18px;
    left: 50%;
    transform: translate(-50%, 0);
    max-width: 268px;

    ::after {
        content: "";
        position: absolute;
        top: -16px;
        left: 50%;
        border-width: 8px;
        border-style: solid;
        border-color: transparent transparent #8c56ff transparent;
        transform: translate(-50%, 0);
    }
`;

export const CoachMarkDescription = styled.div`
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
`;

export const CoachMarkBtnContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export const CoachMarkBtnRightContainer = styled.div`
    display: flex;
    gap: 8px;
    justify-content: space-between;
`;

export const CoachMarkOnlyTextBtn = styled.div`
    color: rgba(255, 255, 255, 0.75);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
    padding: 10px 4px;
    cursor: pointer;
`;

export const CoachMarkFilledBtn = styled.div`
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #fff;
    color: #333;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
    cursor: pointer;
`;

type Props = {
    isLast: boolean;
    isOnlyOne: boolean;
    coachMarkOrder: number;
    setCoachMarkOrder: (value: number) => void;
    coachMarkInfoItem: CoachMarkListItemType;
    idx: number;
};

const CoachMark = (props: Props): ReactElement | null => {
    const [coachMarkInfo, setCoachMarkInfo] = useAtom(CoachMarkInfoAtom);
    useEffect(() => {
        const inputTags =
            props.coachMarkInfoItem.containerRef.current?.querySelectorAll(
                "input",
            ) || [];

        const textareaTags =
            props.coachMarkInfoItem.containerRef.current?.querySelectorAll(
                "textarea",
            ) || [];

        if (inputTags.length > 0) {
            inputTags[0].focus();
        } else if (textareaTags.length > 0) {
            textareaTags[0].focus();
        }
    }, []);

    return (
        <CoachMarkContainer>
            <CoachMarkDescription>
                {props.coachMarkInfoItem.desc}
            </CoachMarkDescription>
            <CoachMarkBtnContainer>
                <CoachMarkOnlyTextBtn
                    onClick={async () => {
                        setCoachMarkInfo({
                            isHideForever: true,
                            coachMarkList: [],
                        });

                        if (props.coachMarkInfoItem.resolve)
                            await props.coachMarkInfoItem.resolve(false);
                    }}
                >
                    도움말 보지 않기
                </CoachMarkOnlyTextBtn>

                <CoachMarkBtnRightContainer>
                    {props.idx > 0 && (
                        <CoachMarkOnlyTextBtn
                            onClick={async (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                props.setCoachMarkOrder(
                                    props.coachMarkOrder - 1 >= 0
                                        ? props.coachMarkOrder - 1
                                        : 0,
                                );

                                if (props.coachMarkInfoItem.resolve) {
                                    await props.coachMarkInfoItem.resolve(
                                        false,
                                    );
                                }
                            }}
                        >
                            이전
                        </CoachMarkOnlyTextBtn>
                    )}

                    {!props.isLast ? (
                        <CoachMarkFilledBtn
                            onClick={async (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                props.setCoachMarkOrder(
                                    props.coachMarkOrder + 1,
                                );
                                if (props.coachMarkInfoItem.resolve)
                                    await props.coachMarkInfoItem.resolve(true);
                            }}
                        >
                            다음
                        </CoachMarkFilledBtn>
                    ) : (
                        <CoachMarkFilledBtn
                            onClick={async (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setCoachMarkInfo({
                                    ...coachMarkInfo,
                                    coachMarkList: [],
                                });

                                if (props.coachMarkInfoItem.resolve)
                                    await props.coachMarkInfoItem.resolve(true);
                            }}
                        >
                            확인
                        </CoachMarkFilledBtn>
                    )}
                </CoachMarkBtnRightContainer>
            </CoachMarkBtnContainer>
        </CoachMarkContainer>
    );
};

export default CoachMark;
