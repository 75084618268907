import React, { ReactElement, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ChipType } from "../../commonUiComponent/doubleSideBar/leftSideBar/LeftSideBar";
import { URL_CONSTANTS } from "../../../../constants/UrlConstants";
import { useScrollXSlideHooks } from "../../../../hooks/ui/scroll/xScroll/useScrollXSlideHooks";
import { useWindowSize } from "usehooks-ts";

export const ChipsContainer = styled.div<{
    containerPadding: string;
    containerHeight?: string;
}>`
    height: ${({ containerHeight }) =>
        containerHeight ? containerHeight : "44px"};
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 6px;
    padding-left: ${({ containerPadding }) => containerPadding};
    padding-right: ${({ containerPadding }) => containerPadding};
    overflow-x: scroll;

    &::-webkit-scrollbar {
        display: none;
    }

    scrollbar-width: none;
`;

export const ChipsArrowWrapper = styled.div<{
    direction: string;
    isMouseOver: boolean;
    isScrollState: boolean;
    hasArrowBackground: boolean;
}>`
    position: absolute;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ hasArrowBackground, direction }) =>
        hasArrowBackground
            ? `linear-gradient(
        ${direction === "left" ? "90deg" : "270deg"},
        #fff 79.89%,
        rgba(255, 255, 255, 0) 100%
    );`
            : "transparent"};

    ${({ direction }) => (direction === "left" ? "left: 0;" : "right: 0;")};
    visibility: ${({ isMouseOver, isScrollState }) =>
        isMouseOver && isScrollState ? "visible" : "hidden"};
    opacity: ${({ isMouseOver, isScrollState }) =>
        isMouseOver && isScrollState ? 1 : 0};
    transition: visibility 300ms ease, opacity 300ms ease;
    cursor: pointer;

    @media ${(props) => props.theme.mobileL} {
        visibility: ${({ isScrollState }) =>
            isScrollState ? "visible" : "hidden"};
        opacity: ${({ isScrollState }) => (isScrollState ? 1 : 0)};

        @media (hover: hover) and (pointer: fine) {
            &:hover {
                visibility: visible;
                opacity: 1;
            }
        }
    }
`;

export const ChipsArrowContainer = styled.div<{}>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    border-radius: 32px;
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.8);

    top: calc(50% - 16px);
`;

export const ChipsArrowImg = styled.img`
    width: 8px;
    height: 14px;
`;
export const Chip = styled.div<{
    isSelected: boolean;
    isHide?: boolean;
}>`
    padding: 9px 14px;
    display: ${({ isHide }) => (isHide ? "none" : "inline-block")};
    justify-content: center;
    align-items: center;

    background: ${({ isSelected }) => (isSelected ? "#FA54FA" : "#f0f0f2")};
    border-radius: 16px;
    flex-shrink: 0;
    font-weight: ${({ isSelected }) => (isSelected ? 700 : 400)};
    font-size: 14px;
    line-height: 14px;

    color: ${({ isSelected }) => (isSelected ? "white" : "black")};
    cursor: pointer;

    @media (hover: hover) and (pointer: fine) {
        ${({ isSelected }) => {
            if (!isSelected)
                return `&:hover {
                        background: #E2E2E3;
                    }`;
        }}
    }
    transition: all 300ms ease;
`;

export const CustomChipContainer = styled.div<{
    isHide?: boolean;
}>`
    flex-shrink: 0;
    display: ${({ isHide }) => (isHide ? "none" : "inline-block")};
`;

type Props = {
    isAllShow: boolean;
    isOnlyOneSelected: boolean;
    chips: ChipType[];
    setChips: (value: ChipType[]) => void;
    containerPadding: string;
    containerHeight?: string;
    hasArrowBackground: boolean;
    renderCustomChip?: (idx: number, chip: ChipType) => ReactElement;
};

const Chips = (props: Props): ReactElement | null => {
    const { width } = useWindowSize();
    const isAllSelected = () => {
        return props.chips.every((chip) => chip.isSelected);
    };
    const isAllNotSelected = () => {
        return props.chips.every((chip) => !chip.isSelected);
    };

    const chipsContainerRef = useRef<HTMLDivElement>(null);

    // hover 로 props 로 변수 받아서 동적 변형이 안되는데 이유를 모르겠음..
    const [isMouseOver, setIsMouseOver] = useState(false);

    const moveChipsContainerScroll = (direction: string) => {
        if (chipsContainerRef.current) {
            if (direction === "right") {
                chipsContainerRef.current.scroll({
                    left: chipsContainerRef.current.scrollLeft + 100,
                    behavior: "smooth",
                });
            } else {
                chipsContainerRef.current.scroll({
                    left: chipsContainerRef.current.scrollLeft - 100,
                    behavior: "smooth",
                });
            }
        }
    };

    const { startEvent } = useScrollXSlideHooks(chipsContainerRef);

    const [isScrollZero, setIsScrollZero] = useState(false);
    const [isScrollFull, setIsScrollFull] = useState(false);

    async function oneChipClickHandler(idx: number) {
        if (chipsContainerRef.current) {
            props.setChips(
                props.chips.map((chip, innerIdx) => {
                    return {
                        ...chip,
                        isSelected: idx === innerIdx,
                    };
                }),
            );
        }
    }

    useEffect(() => {
        setIsScrollFull(
            chipsContainerRef.current
                ? Math.ceil(chipsContainerRef.current?.scrollLeft) >=
                      Math.floor(
                          chipsContainerRef.current?.scrollWidth -
                              chipsContainerRef.current?.offsetWidth,
                      )
                : false,
        );

        setIsScrollZero(chipsContainerRef.current?.scrollLeft !== 0);
    }, [width]);

    return (
        <ChipsContainer
            containerPadding={props.containerPadding}
            containerHeight={props.containerHeight}
            ref={chipsContainerRef}
            onMouseDown={(e) => {
                startEvent(e.clientX);
            }}
            onTouchStart={(e) => {
                startEvent(e.touches[0].clientX);
            }}
            onMouseEnter={() => {
                setIsMouseOver(true);
            }}
            onMouseLeave={() => {
                setIsMouseOver(false);
            }}
            onScroll={() => {
                setIsScrollFull(
                    chipsContainerRef.current
                        ? Math.ceil(chipsContainerRef.current?.scrollLeft) >=
                              Math.floor(
                                  chipsContainerRef.current?.scrollWidth -
                                      chipsContainerRef.current?.offsetWidth,
                              )
                        : false,
                );

                setIsScrollZero(chipsContainerRef.current?.scrollLeft !== 0);
            }}
        >
            {chipsContainerRef.current && (
                <>
                    <ChipsArrowWrapper
                        direction={"left"}
                        hasArrowBackground={props.hasArrowBackground}
                        isScrollState={isScrollZero}
                        isMouseOver={isMouseOver}
                    >
                        <ChipsArrowContainer
                            onClick={() => {
                                moveChipsContainerScroll("left");
                            }}
                        >
                            <ChipsArrowImg
                                src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-carousel-arrow-left.png`}
                            />
                        </ChipsArrowContainer>
                    </ChipsArrowWrapper>
                    <ChipsArrowWrapper
                        direction={"right"}
                        hasArrowBackground={props.hasArrowBackground}
                        isScrollState={!isScrollFull}
                        isMouseOver={isMouseOver}
                    >
                        <ChipsArrowContainer
                            onClick={() => {
                                moveChipsContainerScroll("right");
                            }}
                        >
                            <ChipsArrowImg
                                src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-carousel-arrow-right.png`}
                            />
                        </ChipsArrowContainer>
                    </ChipsArrowWrapper>
                </>
            )}

            {props.isAllShow && (
                <Chip
                    isSelected={isAllNotSelected()}
                    onClick={async () => {
                        props.setChips(
                            props.chips.map((chip) => {
                                return {
                                    ...chip,
                                    isSelected: false,
                                };
                            }),
                        );
                    }}
                >
                    전체
                </Chip>
            )}
            {props.chips.map((chip, idx) => {
                return props.renderCustomChip ? (
                    <CustomChipContainer
                        key={idx}
                        onClick={async () => {
                            await oneChipClickHandler(idx);
                        }}
                    >
                        {props.renderCustomChip(idx, chip)}
                    </CustomChipContainer>
                ) : (
                    <Chip
                        key={idx}
                        isSelected={chip.isSelected}
                        onClick={async () => {
                            await oneChipClickHandler(idx);
                        }}
                    >
                        {chip.title}
                    </Chip>
                );
            })}
        </ChipsContainer>
    );
};

export default Chips;

Chips.defaultProps = {
    isAllShow: true,
    isOnlyOneSelected: false,
    containerPadding: "15px",
    hasArrowBackground: true,
};
