import { ReactElement, useState } from "react";
import styled from "styled-components";
import AdManageContentsHeader from "../../commonComponents/adManageContentsHeader/AdManageContentsHeader";
import ApiResBoundary from "../../../../common/apiResBoundary/ApiResBoundary";
import AdManageMyTemplateList from "./components/AdManageMyTemplateList";
import { DropdownListItemType } from "../../../../common/commonUnitComponent/commonDropdownBtn/CommonDropdownBtn";
import { ChipType } from "../../../../common/commonUiComponent/doubleSideBar/leftSideBar/LeftSideBar";
import { useAdCampaignSimpleListQuery } from "../../../../../query/ad/campaign/useAdCampaignSimpleListQuery";
import {
    BRAND_ID_SAYBUZZ,
    BRAND_ID_SOOP,
    BRAND_NAME_SAYBUZZ,
    BRAND_NAME_SOOP,
} from "../../../../../constants/brand/Brand";

export const AdBuyManageTemplatesContentsContainer = styled.div``;

export const AdManageMyTemplateListBodyContainer = styled.div``;

export const AdManageMyTemplateBrandList: DropdownListItemType[] = [
    {
        value: "전체",
        key: "",
        isSelected: true,
    },
    {
        value: BRAND_NAME_SAYBUZZ,
        key: BRAND_ID_SAYBUZZ,
        isSelected: false,
    },
    {
        value: BRAND_NAME_SOOP,
        key: BRAND_ID_SOOP,
        isSelected: false,
    },
];

export const AdManageMyTemplateStatusChips: ChipType[] = [
    {
        title: "사용가능",
        key: "2",
        isSelected: false,
    },
    {
        title: "검토중",
        key: "1",
        isSelected: false,
    },
    {
        title: "반려",
        key: "3",
        isSelected: false,
    },
];

type Props = {};

const AdBuyManageMyTemplatesContents = (props: Props): ReactElement | null => {
    const [brandDropdownList, setBrandDropdownList] = useState<
        DropdownListItemType[]
    >(AdManageMyTemplateBrandList);

    const [templateChips, setTemplateChips] = useState<ChipType[]>(
        AdManageMyTemplateStatusChips,
    );

    const selectedBrandKey = brandDropdownList.find(
        (filter) => filter.isSelected,
    )?.key;

    const {
        data: rawAdCampaignSimpleList,
        error: errorAdCampaignSimpleList,
        isLoading: isAdCampaignSimpleListLoading,
    } = useAdCampaignSimpleListQuery({
        brand_id_array: selectedBrandKey || "",
        campaign_type_array: "1, 2",
    });

    return (
        <AdBuyManageTemplatesContentsContainer>
            <AdManageContentsHeader
                brandDropdownList={brandDropdownList}
                setBrandDropdownList={setBrandDropdownList}
                templateChips={templateChips}
                setTemplateChips={setTemplateChips}
                isChipsAllShow={true}
            />

            <ApiResBoundary
                isLoading={isAdCampaignSimpleListLoading}
                isNoData={rawAdCampaignSimpleList.campaignList.length === 0}
                error={errorAdCampaignSimpleList}
                defaultLoadingFallbackContainerHeight={"600px"}
            >
                <AdManageMyTemplateListBodyContainer>
                    {rawAdCampaignSimpleList.campaignList.map(
                        (campaign, campaignIdx) => (
                            <AdManageMyTemplateList
                                key={campaign.campaign_idx}
                                campaignSimpleListItem={campaign}
                                campaignDataOrderIdx={campaignIdx}
                                templateChips={templateChips}
                                brandDropdownList={brandDropdownList}
                            />
                        ),
                    )}
                </AdManageMyTemplateListBodyContainer>
            </ApiResBoundary>
        </AdBuyManageTemplatesContentsContainer>
    );
};

export default AdBuyManageMyTemplatesContents;
