import { convertSafariAdSalesDate, equalDate } from "../../utils/Utils";
import { DEFAULT_MY_AD_DAILY_LIST_ITEM_TYPE_DATA } from "../../types/ad/adMy/myAd__sale/myAdDailyList/MyAdDailyListItemType";
import { useAdInfoQuery } from "../../query/ad/ad/useAdInfoQuery";
import { AdSaleManageCalendarCellCommonProps } from "../../components/ad/adManage/adSaleManage/adSaleManageSchedule/components/adSaleManageScheduleCalendar/components/useGetAdSaleManageCalendarCellByCode";
import { getIsTodaySale } from "./staticConvertAdStatusKR";

// type AdSaleManageCalendarCellCommonProps = {
//     cellDate: Date;
//     adIdx: string;
//     myAdDailyList: MyAdDailyListItemType[]
// };

export function useGetAdMyDailyDateData(
    params: AdSaleManageCalendarCellCommonProps,
) {
    const {
        data: rawAdData,
        isLoading: isAdDataLoading,
        isSuccess: isAdDataSuccess,
        error: errorAdData,
    } = useAdInfoQuery(params.adIdx);

    const targetDateInfo =
        params.myAdDailyList.find((dailyInfo) =>
            equalDate(
                new Date(convertSafariAdSalesDate(dailyInfo.ad_sales_date)),
                params.cellDate,
            ),
        ) || DEFAULT_MY_AD_DAILY_LIST_ITEM_TYPE_DATA;

    return {
        targetDateInfo: targetDateInfo,
        isTodaySale: getIsTodaySale(
            targetDateInfo.adSaleInfo.ad_sales_daily_status,
        ),
        isAdStop: rawAdData.advertisementInfo.ad_stop_switch_bool,
        hasOfferSuccess: typeof targetDateInfo.offerSuccessInfo !== "undefined",
        hasHighestOffer: typeof targetDateInfo.highestOfferInfo !== "undefined",
    };
}
