import { ReactElement } from "react";
import styled from "styled-components";
import { numberToStringWithComma } from "../../../../../utils/Utils";

export const ItemTransferCountInputContainer = styled.div``;

export const ItemSendUseLayerInfoInputInfoContainer = styled.div`
    width: 100%;
    position: relative;
`;

export const ItemSendUseLayerInfoInputInfoTitle = styled.div`
    color: #222;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 15px */
`;

export const ItemSendUseLayerInfoNumberContainer = styled.div`
    display: flex;
    gap: 6px;
    margin-top: 10px;
    align-items: center;
`;

export const ItemSendUseLayerInfoNumberButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px transparent solid;
    background: rgba(13, 13, 26, 0.06);
    width: 40px;
    height: 40px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
`;

export const ItemSendUseLayerInfoNumberInput = styled.input`
    display: flex;
    text-align: right;
    height: 40px;
    width: 60px;
    padding: 0 9px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;

    &:focus,
    &:active {
        outline: none;
    }

    ::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

export const ItemSendUseLayerRemainCountContainer = styled.div`
    color: #888;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 13px */
    margin-top: 10px;
    width: 100%;
`;

type Props = {
    count: number;
    setCount: (value: number) => void;
    maxCount: number;
    refetchMaxCount: () => Promise<any>;
    title: string;
};

const ItemTransferCountInput = (props: Props): ReactElement | null => {
    return (
        <ItemTransferCountInputContainer>
            <ItemSendUseLayerInfoInputInfoContainer>
                <ItemSendUseLayerInfoInputInfoTitle>
                    {props.title} 개수
                    <ItemSendUseLayerInfoNumberContainer>
                        <ItemSendUseLayerInfoNumberButton
                            onClick={async () => {
                                if (props.count > 0)
                                    props.setCount(props.count - 1);
                                await props.refetchMaxCount();
                            }}
                        >
                            -
                        </ItemSendUseLayerInfoNumberButton>
                        <ItemSendUseLayerInfoNumberInput
                            type="number"
                            value={props.count}
                            onChange={async (e) => {
                                if (Number(e.target.value) >= 0)
                                    props.setCount(Number(e.target.value));
                                await props.refetchMaxCount();
                            }}
                        />
                        <ItemSendUseLayerInfoNumberButton
                            onClick={async () => {
                                if (props.count < props.maxCount) {
                                    props.setCount(props.count + 1);
                                }
                                await props.refetchMaxCount();
                            }}
                        >
                            +
                        </ItemSendUseLayerInfoNumberButton>
                    </ItemSendUseLayerInfoNumberContainer>
                </ItemSendUseLayerInfoInputInfoTitle>
            </ItemSendUseLayerInfoInputInfoContainer>
            <ItemSendUseLayerRemainCountContainer>
                {props.title} 가능 개수{" "}
                {numberToStringWithComma(props.maxCount)}
            </ItemSendUseLayerRemainCountContainer>
        </ItemTransferCountInputContainer>
    );
};

export default ItemTransferCountInput;
