import { ReactElement } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../constants/UrlConstants";

export const AdManageCommonMoreBtnContainer = styled.div<{ mb: number }>`
    position: relative;
    z-index: 1;
    margin-top: 0;
    margin-bottom: ${({ mb }) => mb + "px"};
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;

    @media ${(props) => props.theme.mobileL} {
        margin-top: 0;
    }
`;

export const AdManageCommonMoreBtnContentsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 14px;
    /* identical to box height, or 88% */
    color: #777777;
    padding: 10px 12px;
    cursor: pointer;
`;

export const AdManageCommonMoreBtnIcon = styled.img`
    width: 8.5px;
    height: 12.5px;
    margin-left: 6px;
`;

type Props = { clickEvent: () => void; mb: number };

const AdManageCommonMoreBtn = (props: Props): ReactElement | null => {
    return (
        <AdManageCommonMoreBtnContainer mb={props.mb}>
            <AdManageCommonMoreBtnContentsContainer
                onClick={() => {
                    props.clickEvent();
                }}
            >
                더 보기
                <AdManageCommonMoreBtnIcon
                    src={URL_CONSTANTS.ASSET_URL + "/icon/icon-more.svg"}
                />
            </AdManageCommonMoreBtnContentsContainer>
        </AdManageCommonMoreBtnContainer>
    );
};

export default AdManageCommonMoreBtn;
