import React, { ReactElement } from "react";
import styled from "styled-components";
import ModalCloseIcon from "../../../../modal/modalCloseIcon/ModalCloseIcon";
import {
    ModalButton,
    ModalContainer,
    ModalDesc,
    ModalTitle,
} from "../../../../modal/ModalStyle";
import { URL_CONSTANTS } from "../../../../../../constants/UrlConstants";
import { useLocalStorage } from "usehooks-ts";

export const WebPushDeniedConfirmModalImg = styled.img`
    width: 219px;
    height: 140px;
`;

export const WebPushDeniedConfirmModalButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 26px;
`;

type Props = {
    resolve: (value: boolean) => void;
};

const WebPushDeniedConfirmModal = (props: Props): ReactElement | null => {
    const [
        isNotiDeniedConfirmModalHiddenForWeekExpiredTime,
        setIsNotiDeniedConfirmModalHiddenForWeekExpiredTime,
    ] = useLocalStorage("isNotiDeniedConfirmModalHiddenForWeekExpiredTime", 0);

    return (
        <ModalContainer isNoPadding={false}>
            <ModalCloseIcon resolve={props.resolve} />
            <WebPushDeniedConfirmModalImg
                src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-noti-denied.svg`}
            />
            <ModalTitle mt={"32px"}>기기 알림이 꺼져있어요!</ModalTitle>
            <ModalDesc>
                기기 알림을 켜고 이벤트 및 각 서비스의 체결 상태를 확인해보세요
            </ModalDesc>
            <WebPushDeniedConfirmModalButtonContainer>
                <ModalButton
                    btnType={"filled-primary"}
                    onClick={() => {
                        props.resolve(true);
                    }}
                >
                    기기 알림 켜기
                </ModalButton>
                <ModalButton
                    btnType={"text-secondary"}
                    onClick={() => {
                        let expire = new Date();
                        expire.setTime(
                            expire.getTime() + 7 * 24 * 60 * 60 * 1000,
                        );
                        setIsNotiDeniedConfirmModalHiddenForWeekExpiredTime(
                            expire.getTime(),
                        );
                        props.resolve(false);
                    }}
                >
                    7일간 보지 않기
                </ModalButton>
            </WebPushDeniedConfirmModalButtonContainer>
        </ModalContainer>
    );
};

export default WebPushDeniedConfirmModal;
