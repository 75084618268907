import React, { ReactElement } from "react";
import styled from "styled-components";
import {
    ModalButton,
    ModalButtonContainer,
    ModalContainer,
    ModalSmallTitle,
} from "../ModalStyle";
import { CommonButtonType } from "../../commonStyle/CommonButtonStyle";

export const CommonErrorModalTextArea = styled.textarea`
    display: flex;
    height: 40px;
    padding: 10px;
    width: calc(100% - 20px);
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #e5e5e5;
    margin-top: 16px;
    color: #000;
    font-family: Noto Sans KR, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    resize: none;

    :focus {
        outline: none;
    }

    &:disabled {
        opacity: 1;
        background: transparent;
    }
`;

type Props = {
    resolve: (value: any) => void;
    title: string;
    banwords?: string;
    imgComponent?: ReactElement;
    btnType?: string;
} & CommonButtonType;

const CommonErrorModal = (props: Props): ReactElement | null => {
    return (
        <ModalContainer isNoPadding={false}>
            <ModalSmallTitle>{props.title}</ModalSmallTitle>
            {props.banwords && (
                <CommonErrorModalTextArea
                    disabled
                    readOnly={true}
                    value={props.banwords}
                />
            )}
            <ModalButtonContainer>
                <ModalButton
                    btnType={props.btnType}
                    onClick={async (e) => {
                        props.resolve(true);
                    }}
                >
                    확인
                </ModalButton>
            </ModalButtonContainer>
        </ModalContainer>
    );
};

export default CommonErrorModal;
CommonErrorModal.defaultProps = {
    btnType: "filled-secondary",
};
