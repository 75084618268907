import { ReactElement, useEffect, useRef } from "react";
import styled from "styled-components";
import ApiResBoundary from "../../../../../../../../common/apiResBoundary/ApiResBoundary";
import AdBidingSelectTemplateModalNoData from "../AdBidingSelectTemplateModalNoData";
import Scrollbar from "../../../../../../../../common/commonUiComponent/scrollbar/Scrollbar";
import { SCROLL_BAR_NORMAL_TYPE } from "../../../../../../../../common/commonUiComponent/scrollbar/ScrollbarType";
import { TemplateListItemType } from "../../../../../../../../../types/ad/templateInfo/TemplateListItemType";
import AdBidingSelectTemplateListItem from "../AdBidingSelectTemplateListItem";
import viewHeightAtom from "../../../../../../../../../store/scroll/viewHeightAtom";
import {
    spreadAdTemplateListPages,
    useAdMyTemplateListQuery,
} from "../../../../../../../../../query/ad/myTemplate/useAdMyTemplateListQuery";
import { useAtom } from "jotai";
import AdBidingSelectMyTemplateListHeader from "./components/AdBidingSelectMyTemplateListHeader";
import { TemplateInfoType } from "../../../../../../../../../types/ad/templateInfo/TemplateInfoType";
import { PathAfterResolveType } from "../../../../../../../../../hooks/modal/useOpenModal";

export const AdBidingSelectMyTemplateListWrapper = styled.div`
    margin-top: 16px;
`;

export const AdBidingSelectMyTemplateListScrollbarWrapper = styled.div<{
    vh: number;
}>`
    width: calc(100% - 48px);
    margin: 24px 24px 0 24px;

    height: ${({ vh }) => "calc(" + 100 * vh + "px - 354px)"};
    max-height: 490px;
    position: relative;

    @media ${(props) => props.theme.mobileL} {
        margin: 24px 16px 0 16px;
        width: calc(100% - 32px);
    }
`;

export const AdBidingSelectMyTemplateListContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 24px;
    padding-bottom: 24px;

    @media ${(props) => props.theme.mobileL} {
        gap: 16px 12px;
    }
`;

type Props = {
    resolve: (value: PathAfterResolveType | boolean) => void;
    campaignIdx: string;
    setSelectedPreviewTemplateIdx: (value: string) => void;
    selectTemplate: (templateInfo: TemplateInfoType) => void;
};

const AdBidingSelectMyTemplateList = (props: Props): ReactElement | null => {
    const [vh] = useAtom(viewHeightAtom);

    const {
        data: rawTemplateList,
        fetchNextPage: nextFetchTemplateList,
        hasNextPage: _hasNextTemplateList,
        error: errorTemplateList,
        isLoading: isTemplateListLoading,
    } = useAdMyTemplateListQuery({
        campaign_idx: props.campaignIdx,
        template_status: "2",

        limit: 8,
        page_no: 1,
    });
    const myTemplateList = spreadAdTemplateListPages(rawTemplateList.pages);
    const hasNextTemplateListRef = useRef(_hasNextTemplateList);

    useEffect(() => {
        hasNextTemplateListRef.current = _hasNextTemplateList;
    }, [_hasNextTemplateList]);

    return (
        <AdBidingSelectMyTemplateListWrapper>
            <AdBidingSelectMyTemplateListHeader
                resolve={props.resolve}
                isNoData={myTemplateList.length === 0}
                campaignIdx={props.campaignIdx}
            />

            <ApiResBoundary
                isLoading={isTemplateListLoading}
                isNoData={myTemplateList.length === 0}
                noDataFallback={
                    <AdBidingSelectTemplateModalNoData
                        resolve={props.resolve}
                        campaignIdx={props.campaignIdx}
                    />
                }
                error={errorTemplateList}
            >
                <AdBidingSelectMyTemplateListScrollbarWrapper vh={vh}>
                    <Scrollbar
                        id={"ad-biding-select-template-modal-body"}
                        type={SCROLL_BAR_NORMAL_TYPE}
                        isThin={true}
                        infiniteScrollCallback={async (
                            showSpinner: () => void,
                        ) => {
                            if (hasNextTemplateListRef.current) {
                                showSpinner();
                                await nextFetchTemplateList();
                            }
                        }}
                    >
                        <AdBidingSelectMyTemplateListContainer>
                            {myTemplateList.map(
                                (
                                    templateListItem: TemplateListItemType,
                                    idx: number,
                                ) => (
                                    <AdBidingSelectTemplateListItem
                                        key={`${idx}-${templateListItem.templateInfo.template_idx}`}
                                        resolve={props.resolve}
                                        isPublicTemplate={false}
                                        templateListItem={templateListItem}
                                        setSelectedPreviewTemplateIdx={
                                            props.setSelectedPreviewTemplateIdx
                                        }
                                        selectTemplate={props.selectTemplate}
                                    />
                                ),
                            )}
                        </AdBidingSelectMyTemplateListContainer>
                    </Scrollbar>
                </AdBidingSelectMyTemplateListScrollbarWrapper>
            </ApiResBoundary>
        </AdBidingSelectMyTemplateListWrapper>
    );
};

export default AdBidingSelectMyTemplateList;
