import { ReactElement } from "react";
import styled from "styled-components";
import { ModalButton, ModalTitle } from "../../../ModalStyle";
import Scrollbar from "../../../../commonUiComponent/scrollbar/Scrollbar";
import { SCROLL_BAR_NORMAL_TYPE } from "../../../../commonUiComponent/scrollbar/ScrollbarType";
import TemplateInfoModalBrandInfo from "./components/TemplateInfoModalBrandInfo";
import TemplateInfoModalTemplateInfo from "./components/TemplateInfoModalTemplateInfo";
import TemplateInfoModalTemplateSpaceInfo from "./components/templateInfoModalTemplateSpaceInfo/TemplateInfoModalTemplateSpaceInfo";
import viewHeightAtom from "../../../../../../store/scroll/viewHeightAtom";
import { useAtom } from "jotai";
import { PathAfterResolveType } from "../../../../../../hooks/modal/useOpenModal";
import { useIsMe } from "../../../../../../hooks/common/useIsMe";
import { queryClient } from "../../../../../../App";
import { useOpenCommonConfirmModal } from "../../../../../../hooks/modal/openModal/useOpenCommonConfirmModal";
import { useAdMyTemplateAdjustAfterApproveMutation } from "../../../../../../query/ad/myTemplate/useAdMyTemplateAdjustAfterApproveMutation";
import { Query } from "react-query";
import { TemplateInfoType } from "../../../../../../types/ad/templateInfo/TemplateInfoType";
import { BrandInfoType } from "../../../../../../types/brand/BrandInfoType";
import { CampaignInfoType } from "../../../../../../types/ad/camapaignInfo/CampaignInfoType";
import {
    TEMPLATE_RESULT_TYPE_PRESV,
    TEMPLATE_RESULT_TYPE_PUBLIC,
    TemplateResultType,
} from "../../TemplateInfoModal";

export const TemplateInfoModalContentsWrapper = styled.div``;

export const TemplateInfoModalContour = styled.div<{
    isTop: boolean;
}>`
    position: absolute;
    z-index: 1;
    ${({ isTop }) =>
        isTop
            ? `
        top: 0;
    `
            : `
        bottom: 0;
    `};

    width: 100%;
    height: 1px;
    background: #ebebeb;

    @media ${(props) => props.theme.mobileL} {
        display: none;
    }
`;

export const TemplateInfoModalContentsScrollbarWrapper = styled.div<{
    vh: number;
    isPublicTemplate: boolean;
}>`
    position: relative;
    height: ${({ isPublicTemplate }) => (isPublicTemplate ? "464px" : "488px")};
    margin-top: ${({ isPublicTemplate }) => (isPublicTemplate ? "24px" : 0)};

    @media ${(props) => props.theme.mobileL} {
        height: unset;
        max-height: ${({ vh }) => `calc(${100 * vh}px - 308px)`};
        padding-bottom: ${({ isPublicTemplate }) =>
            isPublicTemplate ? "98px" : 0};
    }
`;

export const TemplateInfoModalContentsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
    padding: 24px 0;
`;

export const TemplateInfoModalButtonFixedContainer = styled.div`
    margin-top: 24px;
    display: flex;
    width: calc(100% - 48px);
    padding: 0 24px;
    justify-content: space-between;
    gap: 10px;
    z-index: 1;

    @media ${(props) => props.theme.mobileL} {
        margin-top: 0;
        position: fixed;
        bottom: 0;
        left: 0;
        width: calc(100% - 34px);
        padding: 16px;
        background: white;
        border: 1px solid #ebebeb;
    }
`;

type Props = {
    resolve: (value: PathAfterResolveType | boolean) => void;
    templateInfo: TemplateInfoType;
    brandInfo: BrandInfoType | null;
    campaignInfo: CampaignInfoType | null;
    templateResultType: TemplateResultType;
    pageType: string;
    setPageType: (pageType: string) => void;
    searchInput?: string;
    // 현재는 미사용
    setSearchInput?: (value: string) => void;
};

const TemplateInfoModalContents = (props: Props): ReactElement | null => {
    const [vh] = useAtom(viewHeightAtom);

    const isMe = useIsMe();
    const { openCommonConfirmModal } = useOpenCommonConfirmModal();
    const { mutateAsync: adjustTemplatePublicBoolAfterApprove } =
        useAdMyTemplateAdjustAfterApproveMutation(
            props.templateInfo.template_idx,
            "template_public_bool",
            "공용 여부 설정이",
            {
                template_public_bool: false,
            },
        );

    const isPublicTemplate =
        props.templateResultType === TEMPLATE_RESULT_TYPE_PUBLIC;

    return (
        <TemplateInfoModalContentsWrapper>
            <ModalTitle>{props.templateInfo.template_name}</ModalTitle>

            <TemplateInfoModalContentsScrollbarWrapper
                vh={vh}
                isPublicTemplate={isPublicTemplate}
            >
                {isPublicTemplate && <TemplateInfoModalContour isTop={true} />}
                <Scrollbar
                    id={"template-info-modal"}
                    type={SCROLL_BAR_NORMAL_TYPE}
                    isThin={false}
                >
                    <TemplateInfoModalContentsContainer>
                        {props.brandInfo && props.campaignInfo && (
                            <TemplateInfoModalBrandInfo
                                brandInfo={props.brandInfo}
                                campaignInfo={props.campaignInfo}
                                setPageType={props.setPageType}
                            />
                        )}

                        <TemplateInfoModalTemplateInfo
                            templateInfo={props.templateInfo}
                            searchInput={props.searchInput}
                            resolve={props.resolve}
                        />

                        <TemplateInfoModalTemplateSpaceInfo
                            templateInfo={props.templateInfo}
                        />
                    </TemplateInfoModalContentsContainer>
                </Scrollbar>
                {isPublicTemplate && <TemplateInfoModalContour isTop={false} />}
            </TemplateInfoModalContentsScrollbarWrapper>

            {props.templateResultType !== TEMPLATE_RESULT_TYPE_PRESV && (
                <TemplateInfoModalButtonFixedContainer>
                    <ModalButton
                        btnType={"filled-secondary"}
                        onClick={() => {
                            props.resolve(false);
                        }}
                    >
                        취소
                    </ModalButton>
                    {!isMe(props.templateInfo.user_idx) ? (
                        <ModalButton
                            btnType={"filled-primary"}
                            onClick={() => {
                                props.resolve({
                                    pathAfterResolve: `/ad/template-copy/${props.templateInfo.template_idx}`,
                                    state: {
                                        isMyTemplate: isMe(
                                            props.templateInfo.user_idx,
                                        ),
                                    },
                                });
                            }}
                        >
                            복제하기
                        </ModalButton>
                    ) : (
                        <ModalButton
                            btnType={"filled-primary"}
                            onClick={async () => {
                                const confirmResult =
                                    await openCommonConfirmModal({
                                        title: "비공개로 설정",
                                        desc: "해당 템플릿을 비공개로 설정하시겠습니까?",
                                    });
                                if (confirmResult) {
                                    await adjustTemplatePublicBoolAfterApprove();
                                    await queryClient.refetchQueries({
                                        predicate: (query: Query) =>
                                            query.queryKey.includes(
                                                "ad-public-template-list",
                                            ),
                                    });
                                    await queryClient.resetQueries({
                                        predicate: (query: Query) =>
                                            query.queryKey.includes(
                                                "ad-template-list",
                                            ),
                                    });
                                }
                                props.resolve(true);
                            }}
                        >
                            비공개로 설정
                        </ModalButton>
                    )}
                </TemplateInfoModalButtonFixedContainer>
            )}
        </TemplateInfoModalContentsWrapper>
    );
};

export default TemplateInfoModalContents;
