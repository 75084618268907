import { ReactElement, useState } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../../constants/UrlConstants";
import { TEMPLATE_INFO_MODAL_CONTENTS } from "../TemplateInfoModal";
import Carousel, {
    RenderComponentDataType,
} from "../../../commonUnitComponent/carousel/Carousel";
import { onErrorImg } from "../../../../../utils/Utils";

import { AdCarouselArrowButton } from "../../../commonUnitComponent/carousel/components/carouselArrowButton/CarouselArrowButtonStyle";
import { NormalCarouselIndicator } from "../../../commonUnitComponent/carousel/components/carouselPageIndicator/CarouselPageIndicatorStyle";
import { TemplateInfoType } from "../../../../../types/ad/templateInfo/TemplateInfoType";

export const TemplateInfoModalPreviewWrapper = styled.div`
    padding: 0 24px;

    @media ${(props) => props.theme.mobileL} {
        padding: 0 16px 16px 16px;
    }
`;

export const TemplateInfoModalPreviewHeader = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const TemplateInfoModalPreviewHeaderBackBtn = styled.img`
    width: 32px;
    height: 32px;
    cursor: pointer;
`;

export const TemplateInfoModalPreviewHeaderTitle = styled.div`
    color: #000;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 100% */
`;

export const TemplateInfoModalPreviewContainer = styled.div`
    margin-top: 24px;
`;

export const TemplateInfoModalPreviewCarouselContainer = styled.div`
    background: #f5f5f7;
    border-radius: 8px;
`;

export const TemplateInfoModalPreviewCarouselImgContainer = styled.div`
    width: 100%;
    height: 490px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const TemplateInfoModalPreviewCarouselImg = styled.img`
    padding: 10px;
    width: calc(100% - 20px);
    height: 95%;
    margin: 0 auto;
    max-height: 100%;
    object-fit: contain;
`;

export const TemplateInfoModalPreviewCarouselIndicator = styled(
    NormalCarouselIndicator,
)`
    bottom: 10px;
    right: 10px;
`;

export const TemplateInfoModalPreviewCarouselArrowButton = styled(
    AdCarouselArrowButton,
)<{
    direction: string;
}>``;

export const TemplateInfoModalPreviewSpaceName = styled.div`
    color: #222;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 16px */
    margin-top: 16px;
`;

type Props = {
    templateInfo: TemplateInfoType;
    setPageType: (value: string) => void;
};

const TemplateInfoModalPreview = (props: Props): ReactElement | null => {
    const [carouselCurrentIdx, setCarouselCurrentIdx] = useState(0);

    return (
        <TemplateInfoModalPreviewWrapper>
            <TemplateInfoModalPreviewHeader>
                <TemplateInfoModalPreviewHeaderBackBtn
                    src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-arrow-left.svg`}
                    onClick={() => {
                        props.setPageType(TEMPLATE_INFO_MODAL_CONTENTS);
                    }}
                />
                <TemplateInfoModalPreviewHeaderTitle>
                    노출 영역 예시
                </TemplateInfoModalPreviewHeaderTitle>
            </TemplateInfoModalPreviewHeader>
            <TemplateInfoModalPreviewContainer>
                <TemplateInfoModalPreviewCarouselContainer>
                    <Carousel
                        isShowPagination={true}
                        responsiveDisplayLength={[1, 1, 1, 1, 1, 1]}
                        dataList={props.templateInfo.template_space.map(
                            (space) =>
                                space.presv_campaign_space_preview_background_path,
                        )}
                        displayLength={1}
                        renderComponent={(data: RenderComponentDataType) => (
                            <TemplateInfoModalPreviewCarouselImgContainer
                                key={data.idx}
                            >
                                <TemplateInfoModalPreviewCarouselImg
                                    onError={onErrorImg}
                                    src={data.listItem}
                                />
                            </TemplateInfoModalPreviewCarouselImgContainer>
                        )}
                        renderArrow={(data) => (
                            <TemplateInfoModalPreviewCarouselArrowButton
                                direction={data.type}
                            />
                        )}
                        renderPagination={(data) => (
                            <TemplateInfoModalPreviewCarouselIndicator>
                                {data.currentIndex + 1}/{data.length}
                            </TemplateInfoModalPreviewCarouselIndicator>
                        )}
                        getterCurrentIndex={setCarouselCurrentIdx}
                    />
                </TemplateInfoModalPreviewCarouselContainer>
                <TemplateInfoModalPreviewSpaceName>
                    {
                        props.templateInfo.template_space[carouselCurrentIdx]
                            .presv_campaign_space_name
                    }
                </TemplateInfoModalPreviewSpaceName>
            </TemplateInfoModalPreviewContainer>
        </TemplateInfoModalPreviewWrapper>
    );
};

export default TemplateInfoModalPreview;
