import { useAtom } from "jotai";
import { ReactElement, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import viewHeightAtom from "../../../../store/scroll/viewHeightAtom";
import { ModalWrapper } from "../../modal/ModalStyle";
import { onErrorImg } from "../../../../utils/Utils";
import { URL_CONSTANTS } from "../../../../constants/UrlConstants";
import CssFilterConverter from "css-filter-converter";
import imageFullSizeViewInfoAtom from "../../../../store/ui/imageFullSizeViewInfoAtom";
import { CSSTransition } from "react-transition-group";

export const ImageFullSizeViewProviderContainer = styled(ModalWrapper)<{
    isOpen: boolean;
    vh: number;
}>`
    background: #000;
`;

export const ImageFullSizeContentsImg = styled.img<{ ratio: number }>`
    transform: ${({ ratio }) => `scale(${ratio})`};
    transition: all 300ms ease;

    @media ${(props) => props.theme.mobileL} {
        max-width: 90%;
    }
`;

export const ImageFullSizeContentsHoverArea = styled.div`
    display: none;

    ${ImageFullSizeViewProviderContainer}:hover & {
        display: block;
    }

    @media ${(props) => props.theme.mobileL} {
        display: block;
    }
`;

export const ImageFullSizeViewContentsInfoContainer = styled.div`
    position: absolute;
    top: 20px;
    left: 20px;

    display: flex;
    max-width: 280px;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 12px;
    background: rgba(34, 34, 34, 0.8);
`;

export const ImageFullSizeViewContentsInfoTitle = styled.div`
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 16px */
    letter-spacing: -0.32px;
`;

export const ImageFullSizeViewContentsInfoDesc = styled.div`
    color: #fff;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 13px */
    letter-spacing: -0.26px;
`;

export const ImageFullSizeViewContentsCloseButtonContainer = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
`;
export const ImageFullSizeViewContentsSizeUpDownButtonContainer = styled.div`
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    gap: 12px;
    @media ${(props) => props.theme.mobileL} {
        right: 50%;
        transform: translate(50%, 0);
    }
`;

export const ImageFullSizeViewContentsButtonContainer = styled.div<{
    isDisabled: boolean;
}>`
    background: #333333;
    width: 40px;
    height: 40px;
    border-radius: 50%;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: ${({ isDisabled }) =>
                isDisabled ? "#333333" : "#ffffff"};
        }
    }

    transition: all 300ms ease;
`;

export const ImageFullSizeViewContentsButtonImg = styled.img<{
    isDisabled: boolean;
}>`
    width: 40px;
    height: 40px;
    border-radius: 50%;

    &:hover {
        filter: ${({ isDisabled }) =>
            isDisabled ? "" : CssFilterConverter.hexToFilter("#000000").color};
    }

    opacity: ${({ isDisabled }) => (isDisabled ? 0.3 : 1)};
    transition: all 300ms ease;
`;

type Props = {};

const ImageFullSizeViewProvider = (props: Props): ReactElement | null => {
    const [vh] = useAtom(viewHeightAtom);
    const [ratio, setRatio] = useState(1);
    const imgRef = useRef<HTMLImageElement>(null);
    const [imageFullSizeViewInfo, setImageFullSizeViewInfo] = useAtom(
        imageFullSizeViewInfoAtom,
    );

    useEffect(() => {
        if (imageFullSizeViewInfo.src !== "") {
            setRatio(1);
        }
    }, [imageFullSizeViewInfo]);

    const initImageFullSizeViewInfo = function () {
        setImageFullSizeViewInfo({
            src: "",
            info: [],
        });
    };

    return (
        <CSSTransition timeout={300} classNames={"fade"}>
            <ImageFullSizeViewProviderContainer
                vh={vh}
                isOpen={imageFullSizeViewInfo.src !== ""}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    initImageFullSizeViewInfo();
                }}
            >
                {imageFullSizeViewInfo.src !== "" && (
                    <>
                        <ImageFullSizeContentsImg
                            src={imageFullSizeViewInfo.src}
                            ratio={ratio}
                            ref={imgRef}
                            onError={onErrorImg}
                        />
                        <ImageFullSizeContentsHoverArea>
                            {imageFullSizeViewInfo.info.length !== 0 && (
                                <ImageFullSizeViewContentsInfoContainer>
                                    {imageFullSizeViewInfo.info.map(
                                        (infoItem: string, idx: number) => {
                                            if (idx === 0) {
                                                return (
                                                    <ImageFullSizeViewContentsInfoTitle
                                                        key={idx}
                                                    >
                                                        {infoItem}
                                                    </ImageFullSizeViewContentsInfoTitle>
                                                );
                                            } else {
                                                return (
                                                    <ImageFullSizeViewContentsInfoDesc
                                                        key={idx}
                                                    >
                                                        {infoItem}
                                                    </ImageFullSizeViewContentsInfoDesc>
                                                );
                                            }
                                        },
                                    )}
                                </ImageFullSizeViewContentsInfoContainer>
                            )}
                            <ImageFullSizeViewContentsCloseButtonContainer>
                                <ImageFullSizeViewContentsButtonContainer
                                    isDisabled={false}
                                >
                                    <ImageFullSizeViewContentsButtonImg
                                        src={
                                            URL_CONSTANTS.ASSET_URL +
                                            "/icon/icon-close-white.svg"
                                        }
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            initImageFullSizeViewInfo();
                                        }}
                                        isDisabled={false}
                                    />
                                </ImageFullSizeViewContentsButtonContainer>
                            </ImageFullSizeViewContentsCloseButtonContainer>
                            <ImageFullSizeViewContentsSizeUpDownButtonContainer>
                                <ImageFullSizeViewContentsButtonContainer
                                    isDisabled={ratio >= 2}
                                >
                                    <ImageFullSizeViewContentsButtonImg
                                        src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-size-up.svg`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            if (ratio < 2) {
                                                setRatio(ratio + 0.1);
                                            }
                                        }}
                                        isDisabled={ratio >= 2}
                                    />
                                </ImageFullSizeViewContentsButtonContainer>
                                <ImageFullSizeViewContentsButtonContainer
                                    isDisabled={ratio <= 0.5}
                                >
                                    <ImageFullSizeViewContentsButtonImg
                                        src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-size-down.svg`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            if (ratio > 0.5)
                                                setRatio(ratio - 0.1);
                                        }}
                                        isDisabled={ratio <= 0.5}
                                    />
                                </ImageFullSizeViewContentsButtonContainer>
                            </ImageFullSizeViewContentsSizeUpDownButtonContainer>
                        </ImageFullSizeContentsHoverArea>
                    </>
                )}
            </ImageFullSizeViewProviderContainer>
        </CSSTransition>
    );
};

export default ImageFullSizeViewProvider;
