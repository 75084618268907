import { ReactElement, useEffect } from "react";
import styled from "styled-components";
import DayPickerLayer from "../../../../../../common/commonUnitComponent/dayPickerLayer/DayPickerLayer";
import SearchFilter, {
    FilterType,
} from "../../../../../../common/commonUiComponent/searchFilter/SearchFilter";
import SortingButton from "../../../../../../common/commonUnitComponent/sortingButton/SortingButton";
import SubSearch from "../../../../../../common/commonUiComponent/subSearch/SubSearch";
import {
    CommonButtonStyle,
    CommonButtonType,
} from "../../../../../../common/commonStyle/CommonButtonStyle";
import { useIsMe } from "../../../../../../../hooks/common/useIsMe";
import { DateRange } from "react-day-picker";
import {
    ChipType,
    SortingItemType,
} from "../../../../../../common/commonUiComponent/doubleSideBar/leftSideBar/LeftSideBar";
import { Link } from "react-router-dom";
import {
    COLLECTION_COMMUNITY_TAB,
    COLLECTION_CREATED_TAB,
    COLLECTION_HISTORY_TAB,
    COLLECTION_OWNED_TAB,
} from "../../WalletCollectionHub";
import SwitchTab, {
    SwitchTabType,
} from "../../../../../../common/commonUiComponent/switchTab/SwitchTab";
import {
    COLLECTION_DEFAULT_VIEW_TYPE,
    WalletCollectionFilters,
} from "../../etc/WalletCollectionItemListConsntants";
import { useLocalStorage } from "usehooks-ts";
import { ExchangeListFilters } from "../../../../../../../constants/exchangeListFilters/ExchagneListFilters";
import { SubTabType } from "../../../../../../common/commonUiComponent/subTab/SubTab";
import ChipsNoScroll from "../../../../../../common/commonUnitComponent/chips/ChipsNoScroll";
import WalletCollectionIsOnlyShowGroupingCheckBox from "./components/WalletCollectionIsOnlyShowGroupingCheckBox";
import { useOpenCollectionGroupEditModal } from "../../../../../../../hooks/modal/openModal/useOpenCollectionGroupEditModal";
import { queryClient } from "../../../../../../../App";

export const WalletCollectionHubHeaderWrapper = styled.div`
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
`;

export const WalletCollectionHubHeaderChipsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const WalletCollectionHubHeaderFunctionContainer = styled.div<{
    isShow: boolean;
}>`
    position: relative;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    display: ${({ isShow }) => (isShow ? "flex" : "none")};

    @media ${(props) => props.theme.tabletM} {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        gap: 10px;
        justify-content: flex-start;
    }
`;

export const WalletCollectionHubLeftFunctionHeader = styled.div<{
    selectedSubIndex: string;
}>`
    display: flex;
    flex-shrink: 0;
    position: relative;
    justify-content: space-between;
    align-items: center;
    gap: auto;
    width: ${({ selectedSubIndex }) =>
        selectedSubIndex !== COLLECTION_HISTORY_TAB
            ? "calc(100% - 464px)"
            : "100%"};

    @media ${(props) => props.theme.tabletM} {
        width: 100%;
    }
`;

export const WalletCollectionHubLeftInLeftFunctionHeader = styled.div`
    flex-shrink: 0;
`;

export const WalletCollectionHubLeftFunctionHeaderDayPickerContainer = styled.div`
    flex-shrink: 0;
`;

export const WalletCollectionHubLeftFunctionHeaderAddItemContainer = styled.div`
    flex-shrink: 0;
`;

export const WalletCollectionHubLeftFunctionHeaderShowNotGroupingCheckBoxTabletLOverContainer = styled.div`
    flex-shrink: 0;

    @media ${(props) => props.theme.tabletM} {
        display: none;
    }
`;

export const WalletCollectionHubLeftFunctionHeaderShowNotGroupingCheckBoxTabletLUnderWrapper = styled.div`
    flex-shrink: 0;
    display: none;
    width: auto;

    @media ${(props) => props.theme.tabletM} {
        display: inline-felx;
    }
`;

export const WalletCollectionHubLeftFunctionHeaderShowNotGroupingCheckBoxTabletLUnderContainer = styled.div`
    display: inline;
`;

export const WalletCollectionHubLeftFunctionHeaderShowNotGroupingCheckBoxLabel = styled.div`
    color: #555;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 100% */
    margin-left: 8px;
    display: flex;
    align-items: center;
`;

export const WalletCollectionHubLeftFunctionHeaderAddItemBtnLink = styled(Link)`
    display: inline-flex;
    width: 100px;
    flex-shrink: 0;
`;

export const WalletCollectionHubLeftFunctionHeaderAddItemBtn = styled.div<CommonButtonType>`
    ${CommonButtonStyle};
    display: inline-flex;
    width: 100px;
    height: 32px;
    font-size: 14px;
    line-height: 100%;
    flex-shrink: 0;
`;

export const WalletCollectionHubLeftFunctionHeaderFilterContainer = styled.div`
    display: flex;
`;

export const WalletCollectionHubRightFunctionHeader = styled.div`
    display: flex;
    width: 444px;
    gap: 10px;

    @media ${(props) => props.theme.tabletM} {
        width: 100%;
    }
`;
export const WalletCollectionHubRightFunctionHeaderSwitchTabContainer = styled.div`
    width: 74px;
    height: 40px;
`;

export const WalletCollectionHubRightFunctionHeaderSearchContainer = styled.div`
    width: 360px;

    @media ${(props) => props.theme.tabletM} {
        width: 100%;
    }
`;

type Props = {
    viewTypeSwitchTabList: SwitchTabType[];
    setViewTypeSwitchTabList: (value: SwitchTabType[]) => void;
    selectedSubTabKey: string;
    selectedDateRange: DateRange;
    setSelectedDateRange: (value: DateRange) => void;
    filters: FilterType[];
    setFilters: (value: FilterType[]) => void;
    sortingList: SortingItemType[];
    setSortingList: (value: SortingItemType[]) => void;
    subTabList: SubTabType[];
    searchInput: string;
    setSearchInput: (value: string) => void;
    groupChips: ChipType[];
    setGroupChips: (value: ChipType[]) => void;
    isOnlyShowNotGrouping: boolean;
    setIsOnlyShowNotGrouping: (value: boolean) => void;
    userIdx: string;
};

const WalletCollectionHubHeader = (props: Props): ReactElement | null => {
    const isMe = useIsMe();
    const [, setItemListViewType] = useLocalStorage(
        "collectionItemListViewType",
        COLLECTION_DEFAULT_VIEW_TYPE,
    );

    useEffect(() => {
        props.setFilters(WalletCollectionFilters);
        props.setSearchInput("");
        queryClient.removeQueries({
            predicate: (query) => query.queryKey.includes("item-list"),
        });

        // 탭 마다 필터 초기화
        if (props.selectedSubTabKey === COLLECTION_HISTORY_TAB) {
            props.setFilters(ExchangeListFilters);
        } else {
            props.setFilters(WalletCollectionFilters);
        }
    }, [props.selectedSubTabKey]);

    // 선택한 스위치 탭 로컬 스토리지에 저장.
    useEffect(() => {
        setItemListViewType(
            props.viewTypeSwitchTabList.find((tab) => tab.isSelected)?.key ||
                "default",
        );
    }, [props.viewTypeSwitchTabList]);

    const { openCollectionGroupEditModal } = useOpenCollectionGroupEditModal();

    return (
        <WalletCollectionHubHeaderWrapper>
            {props.selectedSubTabKey === COLLECTION_OWNED_TAB && (
                <WalletCollectionHubHeaderChipsContainer>
                    {
                        <ChipsNoScroll
                            userIdx={props.userIdx}
                            chips={props.groupChips}
                            setChips={props.setGroupChips}
                            isAllShow={true}
                            isOnlyOneSelected={true}
                            containerHeight={"32px"}
                            showingRowIndex={3}
                            onEditClickHandler={async () => {
                                await openCollectionGroupEditModal(
                                    props.userIdx,
                                );
                            }}
                        />
                    }
                    <WalletCollectionHubLeftFunctionHeaderShowNotGroupingCheckBoxTabletLUnderWrapper>
                        <WalletCollectionHubLeftFunctionHeaderShowNotGroupingCheckBoxTabletLUnderContainer>
                            <WalletCollectionIsOnlyShowGroupingCheckBox
                                userIdx={props.userIdx}
                                groupChips={props.groupChips}
                                id={
                                    "wallet-collection-not-grouping-show-check-box-mobile"
                                }
                                isOnlyShowNotGrouping={
                                    props.isOnlyShowNotGrouping
                                }
                                setIsOnlyShowNotGrouping={
                                    props.setIsOnlyShowNotGrouping
                                }
                            />
                        </WalletCollectionHubLeftFunctionHeaderShowNotGroupingCheckBoxTabletLUnderContainer>
                    </WalletCollectionHubLeftFunctionHeaderShowNotGroupingCheckBoxTabletLUnderWrapper>
                </WalletCollectionHubHeaderChipsContainer>
            )}
            <WalletCollectionHubHeaderFunctionContainer
                isShow={props.selectedSubTabKey !== COLLECTION_COMMUNITY_TAB}
            >
                <WalletCollectionHubLeftFunctionHeader
                    selectedSubIndex={props.selectedSubTabKey}
                >
                    <WalletCollectionHubLeftInLeftFunctionHeader>
                        {props.selectedSubTabKey === COLLECTION_HISTORY_TAB && (
                            <WalletCollectionHubLeftFunctionHeaderDayPickerContainer>
                                <DayPickerLayer
                                    isShow={true}
                                    isMobileTitleHide={false}
                                    selectedDateRange={props.selectedDateRange}
                                    setSelectedDateRange={
                                        props.setSelectedDateRange
                                    }
                                />
                            </WalletCollectionHubLeftFunctionHeaderDayPickerContainer>
                        )}
                        {props.selectedSubTabKey === COLLECTION_CREATED_TAB &&
                            isMe(props.userIdx) && (
                                <WalletCollectionHubLeftFunctionHeaderAddItemContainer>
                                    <WalletCollectionHubLeftFunctionHeaderAddItemBtnLink
                                        to={"/market/add-item"}
                                    >
                                        <WalletCollectionHubLeftFunctionHeaderAddItemBtn
                                            btnType={"outlined-primary"}
                                        >
                                            아이템 등록
                                        </WalletCollectionHubLeftFunctionHeaderAddItemBtn>
                                    </WalletCollectionHubLeftFunctionHeaderAddItemBtnLink>
                                </WalletCollectionHubLeftFunctionHeaderAddItemContainer>
                            )}
                        {props.selectedSubTabKey === COLLECTION_OWNED_TAB && (
                            <WalletCollectionHubLeftFunctionHeaderShowNotGroupingCheckBoxTabletLOverContainer>
                                <WalletCollectionIsOnlyShowGroupingCheckBox
                                    groupChips={props.groupChips}
                                    userIdx={props.userIdx}
                                    id={
                                        "wallet-collection-not-grouping-show-check-box"
                                    }
                                    isOnlyShowNotGrouping={
                                        props.isOnlyShowNotGrouping
                                    }
                                    setIsOnlyShowNotGrouping={
                                        props.setIsOnlyShowNotGrouping
                                    }
                                />
                            </WalletCollectionHubLeftFunctionHeaderShowNotGroupingCheckBoxTabletLOverContainer>
                        )}
                    </WalletCollectionHubLeftInLeftFunctionHeader>

                    <WalletCollectionHubLeftFunctionHeaderFilterContainer>
                        {props.selectedSubTabKey !== COLLECTION_HISTORY_TAB && (
                            <SearchFilter
                                id={"wallet-collection-item"}
                                title={"아이템 검색 필터"} // < 컬렉션
                                layerPositionStyleString={""}
                                filters={props.filters}
                                setFilters={props.setFilters}
                                filterItemWidth={"160px"}
                                isForceLayerType={
                                    props.selectedSubTabKey ===
                                    COLLECTION_HISTORY_TAB
                                }
                            />
                        )}
                        {props.selectedSubTabKey !== COLLECTION_HISTORY_TAB &&
                            props.sortingList.length !== 0 && (
                                <SortingButton
                                    sortingList={props.sortingList}
                                    setSortingList={props.setSortingList}
                                />
                            )}
                    </WalletCollectionHubLeftFunctionHeaderFilterContainer>
                </WalletCollectionHubLeftFunctionHeader>
                {props.selectedSubTabKey !== COLLECTION_HISTORY_TAB && (
                    <WalletCollectionHubRightFunctionHeader>
                        <WalletCollectionHubRightFunctionHeaderSwitchTabContainer>
                            <SwitchTab
                                switchTabList={props.viewTypeSwitchTabList}
                                setSwitchTabList={
                                    props.setViewTypeSwitchTabList
                                }
                            />
                        </WalletCollectionHubRightFunctionHeaderSwitchTabContainer>
                        <WalletCollectionHubRightFunctionHeaderSearchContainer>
                            <SubSearch
                                mobileWidth={"100%"}
                                searchInput={props.searchInput}
                                setSearchInput={props.setSearchInput}
                                placeholder={"아이템명을 입력해주세요."}
                                blurHandler={async () => {
                                    queryClient.removeQueries({
                                        predicate: (query) =>
                                            query.queryKey.includes(
                                                "item-list",
                                            ),
                                    });
                                }}
                            />
                        </WalletCollectionHubRightFunctionHeaderSearchContainer>
                    </WalletCollectionHubRightFunctionHeader>
                )}
            </WalletCollectionHubHeaderFunctionContainer>
        </WalletCollectionHubHeaderWrapper>
    );
};

export default WalletCollectionHubHeader;
