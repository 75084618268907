import { useInfiniteQuery } from "react-query";
import { TemplateListItemType } from "../../../types/ad/templateInfo/TemplateListItemType";
import { AdMyTemplateApi } from "../../../apis/adApi/AdMyTemplateApi";

export type AdMyTemplateListApiResultType = {
    templateList: TemplateListItemType[];
    totalCount: number;
    nextPageNumber?: number;
};

export type AdMyTemplateListApiParamsType = {
    [key: string]: any;
    page_no?: number;
    limit?: number;
    campaign_idx: string;
    template_status?: string;
};

export const useAdMyTemplateListQuery = (
    params: AdMyTemplateListApiParamsType,
    isEnabled = true,
) => {
    const queryResult = useInfiniteQuery<AdMyTemplateListApiResultType, Error>({
        queryKey: [
            "ad-template-list-" + JSON.stringify(params),
            `ad-template-list-${params.campaign_idx}-${params.template_status}`,
            "ad-template-list",
        ],
        queryFn: ({ pageParam = 1 }) => {
            return AdMyTemplateApi.getMyTemplateList({
                ...params,
                page_no: pageParam,
            });
        },
        refetchOnWindowFocus: false,
        suspense: false,
        getNextPageParam: (lastPage, pages) => {
            return lastPage.nextPageNumber;
        },
        onError: (err) => {},
        enabled: isEnabled,
    });
    const defaultData: AdMyTemplateListApiResultType[] = [
        { templateList: [], totalCount: 0 },
    ];
    return {
        ...queryResult,
        data: queryResult.data || {
            pages: defaultData,
            pageParams: {},
        },
    };
};

export function spreadAdTemplateListPages(
    pages: AdMyTemplateListApiResultType[],
) {
    return pages
        .map((result) => result.templateList)
        .reduce((prev, next) => prev.concat(next));
}
