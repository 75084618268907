import { ReactElement } from "react";
import styled from "styled-components";
import { TemplateListItemType } from "../../../../../../../../types/ad/templateInfo/TemplateListItemType";
import ThreeDotButton from "../../../../../../../common/commonUnitComponent/threeDotButton/ThreeDotButton";
import ChipsNoScroll from "../../../../../../../common/commonUnitComponent/chips/ChipsNoScroll";
import { useOpenTemplateInfoModal } from "../../../../../../../../hooks/modal/openModal/useOpenTemplateInfoModal";
import { useNavigate } from "react-router-dom";
import { useIsMe } from "../../../../../../../../hooks/common/useIsMe";
import { useAdMyTemplateAdjustAfterApproveMutation } from "../../../../../../../../query/ad/myTemplate/useAdMyTemplateAdjustAfterApproveMutation";
import { queryClient } from "../../../../../../../../App";
import { TemplateInfoModalTemplateInfoTag } from "../../../../../../../common/modal/templateInfoModal/components/templateInfoModalContents/components/TemplateInfoModalTemplateInfo";
import Nickname from "../../../../../../../common/commonUnitComponent/nickname/Nickname";
import {
    numberToStringWithComma,
    splitByTargetWord,
} from "../../../../../../../../utils/Utils";
import {
    AdPublicTemplateListParamsType,
    getAdPublicTemplateListQueryKey,
} from "../../../../../../../../query/ad/publicTemplate/useAdPublicTemplateListQuery";
import { TEMPLATE_RESULT_TYPE_PUBLIC } from "../../../../../../../common/modal/templateInfoModal/TemplateInfoModal";

export const AdBuyManagePublicTemplateListItemContainer = styled.div`
    width: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    border: 1px solid #ebebeb;
    background: #fff;
    overflow: visible;
    cursor: pointer;
`;

export const AdBuyManagePublicTemplateListItemThumbnailContainer = styled.div`
    width: 100%;
    height: 200px;
    background: var(
        --Light-Gray,
        linear-gradient(0deg, #f5f5f7 0%, #f5f5f7 100%),
        #eaf2fe
    );

    display: flex;
    justify-content: center;
    align-items: center;
`;

export const AdBuyManagePublicTemplateListItemThumbnail = styled.img`
    width: auto;
    height: auto;
    max-width: 95%;
    max-height: 95%;
    object-fit: fill;
`;

export const AdBuyManagePublicTemplateListItemInfoContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 14px;
    align-items: flex-start;
    padding: 16px;
    box-sizing: border-box;
    position: relative;

    @media ${(props) => props.theme.mobileL} {
        padding: 12px 8px;
    }
`;

export const AdBuyManagePublicTemplateListItemThreeDotBtnContainer = styled.div`
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
`;

export const AdBuyManagePublicTemplateListItemInfoTitle = styled.div`
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 18px */

    @media ${(props) => props.theme.mobileL} {
        font-size: 15px;
    }
`;

export const AdBuyManagePublicTemplateListItemInfoTitleBoldSpan = styled.span`
    color: #fa54fa;
`;

export const AdBuyManagePublicTemplateListItemInfoDescContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const AdBuyManagePublicTemplateListItemInfoDesc = styled.div`
    overflow: hidden;
    color: #999;
    text-overflow: ellipsis;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
    display: flex;
`;

export const AdBuyManagePublicTemplateListItemInfoDescNicknameContainer = styled.div`
    text-decoration-line: underline;
    margin-left: 4px;
`;

export const AdBuyManagePublicTemplateListItemInfoTagContainer = styled.div`
    display: flex;
    gap: 4px;
`;

export const AdBuyManagePublicTemplateListItemInfoTag = styled(
    TemplateInfoModalTemplateInfoTag,
)<{
    isSelected: boolean;
}>``;

type Props = {
    templateListItem: TemplateListItemType;
    searchInput: string;
    setSearchInput: (value: string) => void;
    adPublicTemplateListQueryParams: AdPublicTemplateListParamsType;
};

const AdBuyManagePublicTemplateListItem = (
    props: Props,
): ReactElement | null => {
    const navigate = useNavigate();
    const isMe = useIsMe();
    const { openTemplateInfoModal } = useOpenTemplateInfoModal();
    const { mutateAsync: adjustTemplatePublicBoolAfterApprove } =
        useAdMyTemplateAdjustAfterApproveMutation(
            props.templateListItem.templateInfo.template_idx,
            "template_public_bool",
            "공용 여부 설정이",
            {
                template_public_bool: false,
            },
        );

    const moreThreeDotConfig = [
        {
            title: "템플릿 복제",
            clickHandler: async () => {
                navigate(
                    `/ad/template-copy/${props.templateListItem.templateInfo.template_idx}`,
                );
            },
            isShow: !isMe(props.templateListItem.templateInfo.user_idx),
            isGoOutIcon: false,
        },
        {
            title: "비공개로 설정",
            clickHandler: async () => {
                await adjustTemplatePublicBoolAfterApprove();

                await queryClient.refetchQueries({
                    predicate: (query) =>
                        query.queryKey.includes(
                            getAdPublicTemplateListQueryKey(
                                props.adPublicTemplateListQueryParams,
                            ),
                        ),
                });
            },
            isShow: isMe(props.templateListItem.templateInfo.user_idx),
            isGoOutIcon: false,
        },
    ];
    return (
        <AdBuyManagePublicTemplateListItemContainer
            onClick={async () => {
                const result = await openTemplateInfoModal(
                    {
                        templateIdx:
                            props.templateListItem.templateInfo.template_idx,
                    },
                    TEMPLATE_RESULT_TYPE_PUBLIC,
                    props.searchInput,
                    props.setSearchInput,
                );
            }}
        >
            <AdBuyManagePublicTemplateListItemThumbnailContainer>
                <AdBuyManagePublicTemplateListItemThumbnail
                    src={
                        props.templateListItem.templateInfo.template_space[0]
                            .template_space_element[0]
                            .template_space_element_img_path
                    }
                />
            </AdBuyManagePublicTemplateListItemThumbnailContainer>
            <AdBuyManagePublicTemplateListItemInfoContainer>
                <AdBuyManagePublicTemplateListItemThreeDotBtnContainer>
                    <ThreeDotButton
                        config={moreThreeDotConfig}
                        hasBorder={false}
                    />
                </AdBuyManagePublicTemplateListItemThreeDotBtnContainer>
                <AdBuyManagePublicTemplateListItemInfoTitle>
                    {splitByTargetWord(
                        props.templateListItem.templateInfo.template_name,
                        props.searchInput,
                    ).map((str: string, idx: number) => {
                        return str === props.searchInput ? (
                            <AdBuyManagePublicTemplateListItemInfoTitleBoldSpan
                                key={idx}
                            >
                                {str}
                            </AdBuyManagePublicTemplateListItemInfoTitleBoldSpan>
                        ) : (
                            <span key={idx}>{str}</span>
                        );
                    })}
                </AdBuyManagePublicTemplateListItemInfoTitle>
                <AdBuyManagePublicTemplateListItemInfoDescContainer>
                    <AdBuyManagePublicTemplateListItemInfoDesc>
                        · 제작자 :
                        <Nickname
                            userIdx={
                                props.templateListItem.templateInfo.user_idx
                            }
                            nickname={
                                props.templateListItem.templateInfo.user_nick
                            }
                            nicknameStyledComponent={
                                AdBuyManagePublicTemplateListItemInfoDescNicknameContainer
                            }
                            isVerified={
                                props.templateListItem.templateInfo
                                    .user_influencer_bool
                            }
                        />
                    </AdBuyManagePublicTemplateListItemInfoDesc>
                    <AdBuyManagePublicTemplateListItemInfoDesc>
                        ·{" "}
                        {props.adPublicTemplateListQueryParams.sort_type ===
                            "template_success_count_desc" &&
                        props.adPublicTemplateListQueryParams.daysOffset !== "0"
                            ? `${props.adPublicTemplateListQueryParams.daysOffset}일 동안`
                            : "총"}{" "}
                        낙찰횟수 :{" "}
                        {numberToStringWithComma(
                            props.templateListItem.templateInfo
                                .template_success_count,
                        )}
                    </AdBuyManagePublicTemplateListItemInfoDesc>
                </AdBuyManagePublicTemplateListItemInfoDescContainer>
                {props.templateListItem.templateInfo.template_tag.length !==
                    0 && (
                    <AdBuyManagePublicTemplateListItemInfoTagContainer>
                        <ChipsNoScroll
                            userIdx={"-9999"}
                            chips={props.templateListItem.templateInfo.template_tag.map(
                                (tag) => {
                                    return {
                                        title: tag.template_tag_name,
                                        key: tag.template_tag_idx,
                                        isSelected: false,
                                    };
                                },
                            )}
                            showingRowIndex={1}
                            setChips={() => null}
                            isAllShow={false}
                            isOnlyOneSelected={false}
                            containerHeight={"22px"}
                            renderCustomChip={(idx, chip) => {
                                return (
                                    <AdBuyManagePublicTemplateListItemInfoTag
                                        isSelected={
                                            chip.title === props.searchInput
                                        }
                                        key={chip.key}
                                    >
                                        {chip.title}
                                    </AdBuyManagePublicTemplateListItemInfoTag>
                                );
                            }}
                            onChipClickHandler={async (idx: number) => {
                                if (
                                    props.templateListItem.templateInfo
                                        .template_tag[idx].template_tag_name ===
                                    props.searchInput
                                ) {
                                    props.setSearchInput("");
                                } else {
                                    props.setSearchInput(
                                        props.templateListItem.templateInfo
                                            .template_tag[idx]
                                            .template_tag_name,
                                    );
                                }
                            }}
                        />
                    </AdBuyManagePublicTemplateListItemInfoTagContainer>
                )}
            </AdBuyManagePublicTemplateListItemInfoContainer>
        </AdBuyManagePublicTemplateListItemContainer>
    );
};

export default AdBuyManagePublicTemplateListItem;
