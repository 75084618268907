import { URL_CONSTANTS } from "../../constants/UrlConstants";
import { getAuthKey } from "../../store/auth/authKeyStore";
import axios from "axios";
import {
    AdPublicTemplateListApiResultType,
    AdPublicTemplateListParamsType,
} from "../../query/ad/publicTemplate/useAdPublicTemplateListQuery";
import { AdPublicTemplateInfoApiResultType } from "../../query/ad/publicTemplate/useAdPublicTemplateInfoQuery";

export const AdTemplateApi = {
    getTemplateList: async (
        params: AdPublicTemplateListParamsType,
    ): Promise<AdPublicTemplateListApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(URL_CONSTANTS.API_URL + "/v2/ad/template/list", {
                    params: params,
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    const data = res.data.data;
                    resolve({
                        ...data,
                        nextPageNumber:
                            params.limit &&
                            params.page_no &&
                            res.data.data.totalCount >
                                params.limit * params.page_no
                                ? params.page_no + 1
                                : undefined,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getTemplateInfo: async (
        template_idx: string,
    ): Promise<AdPublicTemplateInfoApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${URL_CONSTANTS.API_URL}/v2/ad/template/${template_idx}`,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
