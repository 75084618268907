import { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import AdBuyManagePublicTemplatesCampaignSelector from "./components/adBuyManagePublicTemplatesCampaignSelector/AdBuyManagePublicTemplatesCampaignSelector";
import { useAdCampaignSimpleListQuery } from "../../../../../query/ad/campaign/useAdCampaignSimpleListQuery";
import AdBuyManagePublicTemplateLitHub from "./components/adBuyManagePublicTemplateListHub/AdBuyManagePublicTemplateLitHub";
import ApiResBoundary from "../../../../common/apiResBoundary/ApiResBoundary";

export const AdBuyManagePublicTemplatesContentsWrapper = styled.div`
    margin-top: 48px;
`;

type Props = {};

// 공유 광고 템플릿 contents
const AdBuyManagePublicTemplatesContents = (
    props: Props,
): ReactElement | null => {
    const [selectedCampaignIdx, setSelectedCampaignIdx] = useState("-1");

    const {
        data: rawAdCampaignSimpleList,
        error: errorAdCampaignSimpleList,
        isLoading: isAdCampaignSimpleListLoading,
        isSuccess: isAdCampaignSimpleListSuccess,
    } = useAdCampaignSimpleListQuery({
        brand_id_array: "",
        campaign_type_array: "1, 2",
    });

    useEffect(() => {
        if (isAdCampaignSimpleListSuccess) {
            setSelectedCampaignIdx(
                rawAdCampaignSimpleList.campaignList.find(
                    (campaign) => campaign.campaign_type === "1",
                )?.campaign_idx || "0",
            );
        }
    }, [isAdCampaignSimpleListSuccess, rawAdCampaignSimpleList]);

    return (
        <ApiResBoundary
            isLoading={isAdCampaignSimpleListLoading}
            isNoData={rawAdCampaignSimpleList.campaignList.length === 0}
            isLoadingSpinnerShow={false}
            error={errorAdCampaignSimpleList}
        >
            <AdBuyManagePublicTemplatesContentsWrapper>
                <AdBuyManagePublicTemplatesCampaignSelector
                    selectedCampaignIdx={selectedCampaignIdx}
                    setSelectedCampaignIdx={setSelectedCampaignIdx}
                    campaignList={[
                        ...rawAdCampaignSimpleList.campaignList.filter(
                            (campaign) => campaign.campaign_type === "1",
                        ),
                        ...rawAdCampaignSimpleList.campaignList.filter(
                            (campaign) => campaign.campaign_type === "2",
                        ),
                    ]}
                />
                <AdBuyManagePublicTemplateLitHub
                    selectedCampaignIdx={selectedCampaignIdx}
                />
            </AdBuyManagePublicTemplatesContentsWrapper>
        </ApiResBoundary>
    );
};

export default AdBuyManagePublicTemplatesContents;
