import React, { ReactElement, useState } from "react";
import styled from "styled-components";
import AdBidingSelectModalTabSelector from "./components/AdBidingSelectModalTabSelector";
import AdBidingSelectMyTemplateList from "./components/adBidingSelectMyTemplateList/AdBidingSelectMyTemplateList";
import AdBidingSelectPublicTemplateHub from "./components/adBidingSelectPublicTemplateHub/AdBidingSelectPublicTemplateHub";
import { TemplateInfoType } from "../../../../../../../types/ad/templateInfo/TemplateInfoType";
import { URL_CONSTANTS } from "../../../../../../../constants/UrlConstants";
import AdBidingSelectTemplatePreview from "./components/AdBidingSelectTemplatePreview";
import { PathAfterResolveType } from "../../../../../../../hooks/modal/useOpenModal";

export const AdBidingSelectTemplateModalWrapper = styled.div`
    width: 592px;
    position: relative;
    overflow: hidden;
    border-radius: 16px;

    @media ${(props) => props.theme.mobileL} {
        width: 100%;
    }
`;

export const AdBidingSelectTemplateModalCloseIcon = styled.img`
    z-index: 2;
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;

    @media ${(props) => props.theme.mobileL} {
        width: 40px;
        height: 40px;
        display: none;
    }
`;

type Props = {
    resolve: (value: PathAfterResolveType | boolean) => void;
    campaignIdx: string;
    adIdx: string;
    setSelectedTemplate: (value: TemplateInfoType | null) => void;
};

const AdBidingSelectTemplateModal = (props: Props): ReactElement | null => {
    const [isPublicTemplateTab, setIsPublicTemplateTab] = useState(false);

    const selectTemplate = async (templateInfo: TemplateInfoType) => {
        props.setSelectedTemplate(templateInfo);

        props.resolve(true);
    };

    const [selectedPreviewTemplateIdx, setSelectedPreviewTemplateIdx] =
        useState("-1");

    return (
        // 광고 템플릿 선택
        <AdBidingSelectTemplateModalWrapper>
            <AdBidingSelectTemplateModalCloseIcon
                onClick={() => {
                    props.resolve(false);
                }}
                src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-close.svg`}
            />

            <AdBidingSelectModalTabSelector
                isPublicTemplateTab={isPublicTemplateTab}
                setIsPublicTemplateTab={setIsPublicTemplateTab}
            />

            {!isPublicTemplateTab ? (
                <AdBidingSelectMyTemplateList
                    resolve={props.resolve}
                    campaignIdx={props.campaignIdx}
                    selectTemplate={selectTemplate}
                    setSelectedPreviewTemplateIdx={
                        setSelectedPreviewTemplateIdx
                    }
                />
            ) : (
                <AdBidingSelectPublicTemplateHub
                    resolve={props.resolve}
                    campaignIdx={props.campaignIdx}
                    adIdx={props.adIdx}
                    setSelectedPreviewTemplateIdx={
                        setSelectedPreviewTemplateIdx
                    }
                />
            )}

            <AdBidingSelectTemplatePreview
                selectedPreviewTemplateIdx={selectedPreviewTemplateIdx}
                setSelectedPreviewTemplateIdx={setSelectedPreviewTemplateIdx}
                selectTemplate={selectTemplate}
                isPublicTemplateTab={isPublicTemplateTab}
                resolve={props.resolve}
            />
        </AdBidingSelectTemplateModalWrapper>
    );
};

export default AdBidingSelectTemplateModal;
