import { useAtom } from "jotai";
import { useQuery } from "react-query";
import { ItemApi } from "../../apis/ItemApi";
import userInfoAtom from "../../store/userInfo/userInfoAtom";

export type OwnItemCntType = {
    own_item_cnt: number;
    ad_sell_bool: boolean;
    daily_transfer_max_cnt: number;
    daily_transfer_remaining_cnt: number;
};

export const useGetOwnItemCnt = (itemIdx: string, isEnabled = true) => {
    const [userInfo] = useAtom(userInfoAtom);

    const queryResult = useQuery<OwnItemCntType, Error>({
        queryFn: () => ItemApi.getOwnItemCnt(itemIdx),
        queryKey: [
            `item-own-item-cnt-${itemIdx}-${userInfo.user_idx}`,
            `item-info-refresh-target-${itemIdx}`,
        ],
        cacheTime: 0,
        staleTime: 0,
        onError: async (error) => {},
        onSuccess: () => {},
        enabled: isEnabled,
    });

    return {
        ...queryResult,
        data: queryResult.data || {
            own_item_cnt: 0,
            ad_sell_bool: false,
            daily_transfer_max_cnt: 0,
            daily_transfer_remaining_cnt: 0,
        },
    };
};
