import { format } from "date-fns";
import { ReactElement } from "react";
import styled from "styled-components";
import { numberToStringWithComma } from "../../../../../../../../utils/Utils";
import { MyAdByDateOfferListItemOfferInfoType } from "../../../../../../../../types/ad/adMy/myAd__sale/myAdByDateOfferList/MyAdByDateOfferListItemOfferInfoType";
import { MyAdOfferInfoOfferSpaceType } from "../../../../../../../../types/ad/adMy/myAd__sale/myAdOfferInfo/MyAdOfferInfoOfferSpaceType";
import { Link } from "react-router-dom";

export const AdSaleManageOfferListModalPreviewInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 14px;
    height: 78.4px;
`;

export const AdSaleManageOfferListModalPreviewInfoMsg = styled.div`
    color: #777;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */
    display: flex;
`;

export const AdSaleManageOfferListModalPreviewInfoMsgKey = styled.div`
    width: 66px;
    flex-shrink: 0;
`;

export const AdSaleManageOfferListModalPreviewInfoMsgValue = styled.div`
    color: #555;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
`;

export const AdSaleManageOfferListModalPreviewInfoMsgValueLink = styled(Link)`
    color: #555;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;

    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
`;

type Props = {
    offerInfo: MyAdByDateOfferListItemOfferInfoType & {
        offer_space: MyAdOfferInfoOfferSpaceType[];
    };
};

const AdSaleManageOfferListModalPreviewInfo = (
    props: Props,
): ReactElement | null => {
    return (
        <AdSaleManageOfferListModalPreviewInfoContainer>
            <AdSaleManageOfferListModalPreviewInfoMsg>
                <AdSaleManageOfferListModalPreviewInfoMsgKey>
                    · 입찰일시
                </AdSaleManageOfferListModalPreviewInfoMsgKey>
                <AdSaleManageOfferListModalPreviewInfoMsgValue>
                    {props.offerInfo.created_at !== "" &&
                        format(
                            new Date(props.offerInfo.created_at),
                            "yyyy-MM-dd HH:mm:ss",
                        )}
                </AdSaleManageOfferListModalPreviewInfoMsgValue>
            </AdSaleManageOfferListModalPreviewInfoMsg>
            <AdSaleManageOfferListModalPreviewInfoMsg>
                <AdSaleManageOfferListModalPreviewInfoMsgKey>
                    · 낙찰건수
                </AdSaleManageOfferListModalPreviewInfoMsgKey>
                <AdSaleManageOfferListModalPreviewInfoMsgValue>
                    {numberToStringWithComma(
                        props.offerInfo.user_success_count,
                    )}
                </AdSaleManageOfferListModalPreviewInfoMsgValue>
            </AdSaleManageOfferListModalPreviewInfoMsg>
            <AdSaleManageOfferListModalPreviewInfoMsg>
                <AdSaleManageOfferListModalPreviewInfoMsgKey>
                    · URL
                </AdSaleManageOfferListModalPreviewInfoMsgKey>
                <AdSaleManageOfferListModalPreviewInfoMsgValue>
                    <AdSaleManageOfferListModalPreviewInfoMsgValueLink
                        to={props.offerInfo.endPoint}
                        target={"_blank"}
                    >
                        {props.offerInfo.endPoint}
                    </AdSaleManageOfferListModalPreviewInfoMsgValueLink>
                </AdSaleManageOfferListModalPreviewInfoMsgValue>
            </AdSaleManageOfferListModalPreviewInfoMsg>
        </AdSaleManageOfferListModalPreviewInfoContainer>
    );
};

export default AdSaleManageOfferListModalPreviewInfo;
