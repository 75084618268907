import { ReactElement } from "react";
import styled from "styled-components";
import {
    ModalButton,
    ModalButtonContainer,
    ModalInfoContainer,
} from "../../../../modal/ModalStyle";
import {
    InfoTableColumnBoldValue,
    InfoTableColumnUnit,
    InfoTableColumnValue,
    InfoTableMajorRow,
    InfoTableRowContainer,
    InfoTableSubColumnValue,
    InfoTableSubRow,
    InfoTableUnderLine,
} from "../../../../../../styles/InfoTableStyle";
import { numberToStringWithComma } from "../../../../../../utils/Utils";
import { useProjectDataQuery } from "../../../../../../query/project/useProjectDataQuery";
import { OrderItemReceiptInfoType } from "../OrderItemReceiptModal";
import { PathAfterResolveType } from "../../../../../../hooks/modal/useOpenModal";

export const OrderItemReceiptSellCancelCompleteInfoContainer = styled.div`
    width: 100%;
`;

type Props = {
    receiptInfo: OrderItemReceiptInfoType;
    resolve: (value: PathAfterResolveType | boolean) => void;
};

const OrderItemReceiptSellCancelCompleteInfo = (
    props: Props,
): ReactElement | null => {
    const { data: rawProjectData } = useProjectDataQuery(
        props.receiptInfo.project_idx,
    );

    return (
        <OrderItemReceiptSellCancelCompleteInfoContainer>
            <ModalInfoContainer width={"280px"} mt={"13px"}>
                <InfoTableRowContainer>
                    <InfoTableMajorRow>
                        총 등록 수량
                        <InfoTableColumnValue>
                            {numberToStringWithComma(
                                props.receiptInfo.order_quantity,
                            )}
                            <InfoTableColumnUnit>개</InfoTableColumnUnit>
                        </InfoTableColumnValue>
                    </InfoTableMajorRow>
                </InfoTableRowContainer>
                <InfoTableUnderLine />
                <InfoTableRowContainer>
                    <InfoTableMajorRow>
                        발생 수익
                        <InfoTableColumnBoldValue>
                            {numberToStringWithComma(
                                props.receiptInfo.settled_quantity_sum *
                                    props.receiptInfo.order_unit_price,
                            )}
                            <InfoTableColumnUnit>
                                {rawProjectData.project_currency}
                            </InfoTableColumnUnit>
                        </InfoTableColumnBoldValue>
                    </InfoTableMajorRow>
                    <InfoTableSubRow>
                        체결 완료 수량
                        <InfoTableSubColumnValue>
                            {numberToStringWithComma(
                                props.receiptInfo.settled_quantity_sum,
                            )}
                            <InfoTableColumnUnit>개</InfoTableColumnUnit>
                        </InfoTableSubColumnValue>
                    </InfoTableSubRow>
                    <InfoTableSubRow>
                        판매 단가
                        <InfoTableSubColumnValue>
                            {numberToStringWithComma(
                                props.receiptInfo.order_unit_price,
                            )}
                            <InfoTableColumnUnit>
                                {rawProjectData.project_currency}
                            </InfoTableColumnUnit>
                        </InfoTableSubColumnValue>
                    </InfoTableSubRow>
                </InfoTableRowContainer>
                <InfoTableUnderLine />
                <InfoTableRowContainer>
                    <InfoTableMajorRow>최종 환급 수량</InfoTableMajorRow>
                    <InfoTableSubRow>
                        환급 판매 로열티
                        <InfoTableSubColumnValue>
                            {numberToStringWithComma(
                                (props.receiptInfo.unit_creator_royalty +
                                    props.receiptInfo.unit_brand_royalty) *
                                    (props.receiptInfo.order_quantity -
                                        props.receiptInfo.settled_quantity_sum),
                            )}
                            <InfoTableColumnUnit>RP</InfoTableColumnUnit>
                        </InfoTableSubColumnValue>
                    </InfoTableSubRow>
                    <InfoTableSubRow>
                        판매 취소 수량
                        <InfoTableSubColumnValue>
                            {numberToStringWithComma(
                                props.receiptInfo.order_quantity -
                                    props.receiptInfo.settled_quantity_sum,
                            )}
                            <InfoTableColumnUnit>개</InfoTableColumnUnit>
                        </InfoTableSubColumnValue>
                    </InfoTableSubRow>
                </InfoTableRowContainer>
            </ModalInfoContainer>
            <ModalButtonContainer>
                <ModalButton
                    btnType={"filled-secondary"}
                    onClick={() => {
                        props.resolve(false);
                    }}
                >
                    닫기
                </ModalButton>
                <ModalButton
                    btnType={"filled-secondary"}
                    onClick={() => {
                        props.resolve({
                            pathAfterResolve: "/my-wallet",
                        });
                    }}
                >
                    지갑으로 이동
                </ModalButton>
            </ModalButtonContainer>
        </OrderItemReceiptSellCancelCompleteInfoContainer>
    );
};

export default OrderItemReceiptSellCancelCompleteInfo;
