import { createRef, ReactElement, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ChipType } from "../../commonUiComponent/doubleSideBar/leftSideBar/LeftSideBar";
import { Chip, CustomChipContainer } from "./Chips";
import { URL_CONSTANTS } from "../../../../constants/UrlConstants";
import { useDebounce, useWindowSize } from "usehooks-ts";
import { useIsMe } from "../../../../hooks/common/useIsMe";
import { pxToNumber } from "../../../../utils/Utils";

export const ChipsNoScrollContainer = styled.div<{
    containerPadding?: string;
    containerHeight?: string;
}>`
    display: flex;
    flex-wrap: wrap;

    width: 100%;
    justify-content: left;
    align-items: center;
    gap: 6px;
    padding-left: ${({ containerPadding }) => containerPadding};
    padding-right: ${({ containerPadding }) => containerPadding};
`;

export const EditChipContainer = styled.div<{
    containerPadding?: string;
    containerHeight?: string;
}>`
    cursor: pointer;
    display: flex;
    gap: 4px;
    height: ${({ containerHeight }) =>
        containerHeight ? containerHeight : "44px"};
    padding: 0 12px;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 100% */
`;

export const EditChipIcon = styled.img``;

export const FoldChipIcon = styled.img<{
    containerHeight?: string;
}>`
    display: block;
    width: ${({ containerHeight }) => containerHeight || "22px"};
    height: ${({ containerHeight }) => containerHeight || "22px"};
`;

type Props = {
    userIdx: string;
    isAllShow: boolean;
    showingRowIndex: number;
    allBtnTitle?: string;
    isOnlyOneSelected: boolean;
    chips: ChipType[];
    setChips: (value: ChipType[]) => void;
    containerPadding?: string;
    containerHeight?: string;
    renderCustomChip?: (idx: number, chip: ChipType) => ReactElement;
    onEditClickHandler?: () => Promise<void> | void;
    onChipClickHandler?: (idx: number) => Promise<void> | void;
};

const ChipsNoScroll = (props: Props): JSX.Element => {
    const { width } = useWindowSize();
    const debouncedWidth = useDebounce<number>(width, 100);
    const containerRef = useRef<HTMLDivElement>(null);
    const isAllNotSelected = () => {
        return props.chips.every((chip) => !chip.isSelected);
    };
    const isMe = useIsMe();
    const [isFold, setIsFold] = useState(true);
    const [foldedRowStartIndex, setFoldedRowStartIndex] = useState(-1);
    const [isForceShow, setIsForceShow] = useState(false);
    const [isDomPainted, setIsDomPainted] = useState(false);
    const [rowChangingIndexList, setRowChangingIndexList] = useState<number[]>(
        [],
    );

    const chipRefList = [
        ...props.chips.map(() => {
            return createRef<HTMLDivElement>();
        }),
        createRef<HTMLDivElement>(),
        createRef<HTMLDivElement>(),
    ];

    async function oneChipClickHandler(idx: number) {
        props.setChips(
            props.chips.map((chip, innerIdx) => {
                return {
                    ...chip,
                    isSelected: idx === innerIdx,
                };
            }),
        );
    }

    function setHideChips() {
        const chipRefTopList = chipRefList.map(
            (ref) => ref.current?.offsetTop || -1,
        );
        let wouldRowChangingIndexList: number[] = [0];

        for (let i = 1; i < chipRefTopList.length - 2; i++) {
            if (chipRefTopList[i] !== chipRefTopList[i - 1]) {
                wouldRowChangingIndexList.push(i);
            }
        }
        setRowChangingIndexList(wouldRowChangingIndexList);

        // 노출되는 버튼 width 합
        const sumBtnsWidth = (isMe(props.userIdx) ? 72 : 0) + 36 + 6;
        let sumWidth = 0;
        let foldedRowWStartIndexWithoutBtns =
            wouldRowChangingIndexList.length > props.showingRowIndex
                ? wouldRowChangingIndexList[props.showingRowIndex]
                : 0;

        // 폴딩이 생기면
        if (props.showingRowIndex > 0) {
            for (
                let i = wouldRowChangingIndexList[props.showingRowIndex - 1];
                i < wouldRowChangingIndexList[props.showingRowIndex];
                i++
            ) {
                sumWidth += chipRefList[i].current?.clientWidth || 0;
                foldedRowWStartIndexWithoutBtns = i;
                if (
                    containerRef.current &&
                    sumWidth + sumBtnsWidth > containerRef.current?.clientWidth
                ) {
                    break;
                }
            }
        }
        setFoldedRowStartIndex(foldedRowWStartIndexWithoutBtns);
        setIsForceShow(false);
    }

    useEffect(() => {
        setIsForceShow(true);
    }, [width]);

    useEffect(() => {
        setHideChips();
    }, [debouncedWidth]);

    useEffect(() => {
        if (props.chips.length !== 0 && !isDomPainted) {
            setHideChips();
            setIsDomPainted(true);
        }
    }, [props.chips.length]);

    return (
        <ChipsNoScrollContainer
            ref={containerRef}
            containerPadding={props.containerPadding}
            containerHeight={props.containerHeight}
        >
            {props.isAllShow && (
                <Chip
                    isSelected={isAllNotSelected()}
                    onClick={async () => {
                        props.setChips(
                            props.chips.map((chip) => {
                                return {
                                    ...chip,
                                    isSelected: false,
                                };
                            }),
                        );
                    }}
                >
                    {props.allBtnTitle ? props.allBtnTitle : "전체"}
                </Chip>
            )}
            {props.chips.map((chip, idx) => {
                return props.renderCustomChip ? (
                    <CustomChipContainer
                        ref={chipRefList[idx]}
                        key={idx}
                        onClick={async (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            await oneChipClickHandler(idx);
                            if (props.onChipClickHandler) {
                                await props.onChipClickHandler(idx);
                            }
                        }}
                        isHide={
                            !isForceShow &&
                            isFold &&
                            foldedRowStartIndex !== 0 &&
                            foldedRowStartIndex !== -1 &&
                            idx >= foldedRowStartIndex
                        }
                    >
                        {props.renderCustomChip(idx, chip)}
                    </CustomChipContainer>
                ) : (
                    <Chip
                        ref={chipRefList[idx]}
                        key={idx}
                        isSelected={chip.isSelected}
                        onClick={async (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            await oneChipClickHandler(idx);
                            if (props.onChipClickHandler) {
                                await props.onChipClickHandler(idx);
                            }
                        }}
                        isHide={
                            !isForceShow &&
                            isFold &&
                            foldedRowStartIndex !== 0 &&
                            foldedRowStartIndex !== -1 &&
                            idx >= foldedRowStartIndex
                        }
                    >
                        {chip.title}
                    </Chip>
                );
            })}

            {foldedRowStartIndex !== 0 && (
                <Chip
                    ref={chipRefList[props.chips.length]}
                    isSelected={false}
                    style={{
                        padding: "5px 6px",
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        setIsFold(!isFold);
                    }}
                >
                    <FoldChipIcon
                        containerHeight={
                            pxToNumber(props.containerHeight || "32") -
                            10 +
                            "px"
                        }
                        src={`${URL_CONSTANTS.ASSET_URL}/icon/${
                            isFold
                                ? "icon-more-horizontal.svg"
                                : "icon-arrow-up-small.svg"
                        }`}
                    />
                </Chip>
            )}
            {isMe(props.userIdx) && (
                <EditChipContainer
                    ref={chipRefList[props.chips.length + 1]}
                    containerPadding={props.containerPadding}
                    containerHeight={props.containerHeight}
                    onClick={async () => {
                        if (props.onEditClickHandler) {
                            props.onEditClickHandler();
                        }
                    }}
                >
                    <EditChipIcon
                        src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-edit.svg`}
                    />
                    편집
                </EditChipContainer>
            )}
        </ChipsNoScrollContainer>
    );
};

export default ChipsNoScroll;
