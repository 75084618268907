import { ReactElement } from "react";
import styled from "styled-components";
import DefaultNoDataComponent from "./DefaultNoDataComponent";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./apiResBoundaryTransition.css";
import DefaultLoadingComponent from "./DefaultLoadingComponent";
import DefaultErrorComponent from "./DefaultErrorComponent";

export const ApiResBoundaryTransitionGroup = styled(TransitionGroup)`
    width: auto;
    position: relative;
    height: inherit;
`;

type Props = {
    errorFallback: (value?: any) => ReactElement;
    loadingFallback?: ReactElement;
    isAbsoluteLoading: boolean;
    noDataFallback: ReactElement | null;
    children: ReactElement;
    defaultLoadingFallbackContainerHeight?: string;

    isNoData: boolean;
    error: any;
    isLoading: boolean;
    id?: string;
    isLoadingSpinnerShow: boolean;
    isUiTreeReset: boolean;

    debug?: boolean;
};

const ApiResBoundary = (props: Props): ReactElement | null => {
    // const isLoading = props.debug ? !props.debug : true; //props.isLoading;
    const isLoading = props.isLoading;
    // children key 를 별개의 키로 지정할 경우 기존 UI tree 완전히 리셋 되어 state 싹 날림
    const childrenKey = props.isUiTreeReset
        ? "children-" + Math.random()
        : "children";
    const time = 200;

    return (
        <ApiResBoundaryTransitionGroup>
            {isLoading ? (
                <CSSTransition
                    key={childrenKey + "loading"}
                    timeout={time}
                    classNames={`b-${
                        props.isAbsoluteLoading ? "absolute-" : ""
                    }loading-fade`}
                    in={isLoading}
                >
                    {props.isLoadingSpinnerShow ? (
                        props.loadingFallback ? (
                            props.loadingFallback
                        ) : (
                            <DefaultLoadingComponent
                                containerHeight={
                                    props.defaultLoadingFallbackContainerHeight
                                }
                            />
                        )
                    ) : (
                        <div></div>
                    )}
                </CSSTransition>
            ) : props.error ? (
                <CSSTransition key={childrenKey + "error"} timeout={0}>
                    {props.errorFallback(props.error)}
                </CSSTransition>
            ) : props.isNoData ? (
                props.noDataFallback ? (
                    <CSSTransition
                        key={childrenKey + "no-data"}
                        timeout={300}
                        classNames={"b-fade"}
                        in={
                            !isLoading && props.error === null && props.isNoData
                        }
                        mountOnEnter={true}
                        unmountOnExit={true}
                    >
                        {props.noDataFallback}
                    </CSSTransition>
                ) : null
            ) : (
                <CSSTransition
                    key={childrenKey}
                    timeout={time}
                    classNames={"b-fade"}
                    mountOnEnter={true}
                    unmountOnExit={true}
                    in={!isLoading && props.error === null && !props.isNoData}
                >
                    {props.children}
                </CSSTransition>
            )}
        </ApiResBoundaryTransitionGroup>
    );
};

export default ApiResBoundary;

ApiResBoundary.defaultProps = {
    errorFallback: (error: any) => <DefaultErrorComponent error={error} />,
    defaultLoadingFallbackContainerHeight: "inherit",
    noDataFallback: <DefaultNoDataComponent msg={"데이터가 없습니다."} />,
    isAbsoluteLoading: true,
    isLoadingSpinnerShow: true,
    isUiTreeReset: false,
    debug: false,
};
