import { ReactElement, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { useLocalStorage } from "usehooks-ts";
import { useGetVersionQuery } from "../../../../query/common/useGetVersionQuery";
import OverLoadPage from "../../../../pages/OverLoadPage";

export const VersionCheckerContainer = styled.div``;

type Props = {
    children: ReactElement[];
};

const VersionChecker = (props: Props): ReactElement | null => {
    const location = useLocation();

    const {
        data: rawVersionData,
        refetch: refetchVersionData,
        isSuccess: isVersionDataSuccess,
        isFetched: isVersionDataFetched,
    } = useGetVersionQuery();

    const [version, setVersion] = useLocalStorage("VERSION", "");
    const isOverload = rawVersionData.db_overload_bool;

    useEffect(() => {
        refetchVersionData();
    }, [location]);

    useEffect(() => {
        if (isVersionDataSuccess) {
            if (version === "") {
                setVersion(rawVersionData.version);
            }
            //
            else {
                if (version !== rawVersionData.version) {
                    setVersion(rawVersionData.version);
                    window.location.reload();
                }
            }
        }
    }, [isVersionDataSuccess, rawVersionData]);

    if (isVersionDataFetched) {
        if (!isOverload) {
            return <>{props.children.map((c) => c)}</>;
        } else {
            return <OverLoadPage />;
        }
    } else return null;
};

export default VersionChecker;
