import { useAtom } from "jotai";
import { useMutation } from "react-query";
import { asyncPushSnackBarListItemAtom } from "../../../store/ui/snackBarListAtom";
import { getApiErrorMessage } from "../../../apis/ErrorHandler/getApiErrorMessage";
import { AdMyTemplateApi } from "../../../apis/adApi/AdMyTemplateApi";
import { TemplateTagInputListItemType } from "../../../components/ad/_adTemplate/getState/useGetTemplateTagListState";
import { useOpenCommonErrorModal } from "../../../hooks/modal/openModal/useOpenCommonErrorModal";
import { URL_CONSTANTS } from "../../../constants/UrlConstants";

export type AdMyTemplateAdjustAfterApproveParamsType =
    | {
          template_tag: TemplateTagInputListItemType[];
      }
    | {
          template_public_bool?: boolean;
      };

export const useAdMyTemplateAdjustAfterApproveMutation = (
    templateIdx: string,
    targetKey: string,
    targetValue: string,
    params: AdMyTemplateAdjustAfterApproveParamsType,
) => {
    const { openCommonErrorModal } = useOpenCommonErrorModal();
    const [, pushSnackBarListItem] = useAtom(asyncPushSnackBarListItemAtom);

    return useMutation<any, Error>(
        () =>
            AdMyTemplateApi.adjustAdMyTemplateAfterApprove(
                templateIdx,
                targetKey,
                params,
            ),
        {
            onError: async (error) => {
                const errorResult = getApiErrorMessage(error);
                await openCommonErrorModal(errorResult);
            },
            onSuccess: () => {
                pushSnackBarListItem({
                    title: "",
                    desc: `${targetValue} 성공적으로 수정되었습니다.`,
                    iconUrl: `${URL_CONSTANTS.ASSET_URL}/icon/icon-checked-in-circle.svg`,
                    confirmBtnTitle: "",
                });
            },
        },
    );
};
