import { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import AdRegisterAdjustTemplateRegisterAdjustCampaignTitle from "./components/AdRegisterAdjustTitle";
import AdRegisterAdjustIntroduce from "./components/AdRegisterAdjustIntroduce";
import AdRegisterAdjustImageArea from "./components/AdRegisterAdjustImageArea";
import AdRegisterAdjustTerms from "./components/AdRegisterAdjustTerms";
import { useNavigate, useParams } from "react-router-dom";
import AdRegisterAdjustPriceInputBox from "./components/adRegisterAdjustPriceInputBox/AdRegisterAdjustPriceInputBox";
import { useAdRegisterMutation } from "../../../query/ad/ad/useAdRegisterMutation";
import {
    CommonButtonStyle,
    CommonButtonType,
} from "../../common/commonStyle/CommonButtonStyle";
import { useCheckIsAllTermAgree } from "../../../hooks/common/useCheckIsAllTermAgree";
import { TermType } from "../../common/commonUnitComponent/terms/Terms";
import { useGetAdPriceState } from "./components/adRegisterAdjustPriceInputBox/useGetAdPriceState";
import { useExceptionUiHandlerBeforeCallApi } from "../../../apis/ErrorHandler/useExceptionUiHandlerBeforeCallApi";
import { exceptionUiHandleAfterCallApi } from "../../../apis/ErrorHandler/callApiExcpetionHandler";
import AdRegisterAdjustAutoAcceptSwitch from "./components/AdRegisterAdjustAutoAcceptSwitch";
import { useGetAdMinPriceState } from "./components/adRegisterAdjustPriceInputBox/useGetAdMinPriceState";
import AdRegisterAdjustBuyNowAvailableSwitch from "./components/AdRegisterAdjustBuyNowAvailableSwitch";
import AdRegisterAdjustRefundInfo from "./components/AdRegisterAdjustRefundInfo";
import { useAdCampaignInfoQuery } from "../../../query/ad/campaign/useAdCampaignInfoQuery";
import { useGetAdDaySaleStartTime } from "./components/adRegisterAdjustDaySaleSwitch/useGetAdDaySaleStartTime";
import { useGetAdDaySalePrice } from "./components/adRegisterAdjustDaySaleSwitch/useGetAdDaySalePrice";
import { numberToStringWithComma, removeComma } from "../../../utils/Utils";
import AdRegisterAdjustDaySaleSwitch from "./components/adRegisterAdjustDaySaleSwitch/AdRegisterAdjustDaySaleSwitch";
import { useOpenCommonErrorModal } from "../../../hooks/modal/openModal/useOpenCommonErrorModal";

export const AdRegisterContentsContainer = styled.div``;

export const AdRegisterAdjustHeaderTitle = styled.div`
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 18px */
`;

export const AdRegisterAdjustHeaderTitleDesc = styled.div`
    color: #777;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 19.5px */
    margin-top: 16px;
`;

export const AdRegisterAdjustBodyContainer = styled.div`
    margin-top: 24px;
`;
export const AdRegisterAdjustButtonContainer = styled.div`
    padding: 24px 32px;
    background: #ffffff;
    border-radius: 10px;
    cursor: pointer;
    @media ${(props) => props.theme.mobileL} {
        padding: 16px;
        width: calc(100% - 32px);
        position: fixed;
        bottom: 0;
        ${(props) => props.theme.zIndexFixedSingleUI};
        box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.05);
        border-radius: 16px 16px 0 0;
    }
`;

export const AdRegisterAdjustButton = styled.div<CommonButtonType>`
    font-size: 16px;
    line-height: 16px;

    ${CommonButtonStyle};
`;

export const AdRegisterTermsList = [
    {
        label: "광고 게재가 시작되면 해당 회차는 판매 중단·수정 및 철회가 불가능합니다.",
        isMust: true,
        state: false,
    },
    {
        label: "광고 템플릿의 최종 검수자는 광고 판매자이며, 광고 적용 이후에 발생하는 광고 입찰 정책에 위반되는 사항으로 인한 귀책은 광고 판매자에게 있습니다.",
        isMust: true,
        state: false,
    },
    {
        label: "세이버즈 광고입찰 정책을 읽고 이해했으며, 이에 동의합니다.",
        isMust: true,
        state: false,
        link: "https://about.saybuzz.io/ko/policy/ad",
    },
    {
        label: "취소 및 환불 규정에 동의합니다.",
        isMust: true,
        state: false,
    },
];
type Props = {};

const AdRegisterContents = (props: Props): ReactElement | null => {
    const params = useParams();
    const campaignIdx = params.campaign_id ? params.campaign_id : "0";
    const navigate = useNavigate();
    const {
        data: rawCampaignData,
        isSuccess: isCampaignDataSuccess,
        error: errorCampaignData,
        isLoading: isCampaignDataLoading,
    } = useAdCampaignInfoQuery(campaignIdx);

    // 재 등록시에는 기존 데이터 가져와야함.
    const campaignInfo = rawCampaignData.campaignInfo;

    const { adPriceState, adPriceExceptionUiHandler } = useGetAdPriceState();
    const { adMinPriceState, adMinPriceExceptionUiHandler } =
        useGetAdMinPriceState();
    const [isAdAutoAcceptOffer, setIsAdAutoAcceptOffer] = useState(true);
    const [isAdBuyNowAvailable, setIsAdBuyNowAvailable] = useState(true);
    const [isAdDaySale, setIsAdDaySale] = useState(true);

    const { adDaySaleStartTimeState, adDaySaleStartTimeExceptionUiHandler } =
        useGetAdDaySaleStartTime();
    const { adDaySalePriceState, adDaySalePriceExceptionUiHandler } =
        useGetAdDaySalePrice();

    const [adRegisterTermsList, setAdRegisterTermsList] =
        useState<TermType[]>(AdRegisterTermsList);
    const checkIsAllTermAgree = useCheckIsAllTermAgree(adRegisterTermsList);

    const { mutateAsync: addAd, isLoading: isAddingAd } = useAdRegisterMutation(
        {
            campaign_idx: campaignIdx,
            ad_minimum_bid_price: removeComma(adMinPriceState.adMinPrice.value),
            ad_reserve_price: removeComma(adPriceState.adPrice.value),
            buy_now_available_bool: isAdBuyNowAvailable,
            ...(isAdBuyNowAvailable && {
                buy_now_closetime_sale_bool: isAdDaySale,
                buy_now_closetime_sale_before: removeComma(
                    adDaySaleStartTimeState.adDaySaleStartTime.value,
                ),
                buy_now_closetime_sale_price: removeComma(
                    adDaySalePriceState.adDaySalePrice.value,
                ),
            }),
            ad_auto_offer_accept_bool: isAdAutoAcceptOffer,
        },
    );

    useEffect(() => {
        const price = removeComma(adPriceState.adPrice.value);
        adDaySalePriceState.setAdDaySalePrice({
            value: numberToStringWithComma(
                isAdDaySale
                    ? price / 2 >= 1000
                        ? Math.floor(price / 2000) * 1000
                        : 1000
                    : 3000,
            ),
            isWarning: false,
            warningMsg: "",
        });
    }, [adPriceState.adPrice.value]);

    useEffect(() => {
        adDaySalePriceState.setAdDaySalePrice({
            value: "3,000",
            isWarning: false,
            warningMsg: "",
        });
    }, []);

    const RegisterAdExceptionUiHandlerList = isAdDaySale
        ? [
              adPriceExceptionUiHandler,
              adMinPriceExceptionUiHandler,
              adDaySaleStartTimeExceptionUiHandler,
              adDaySalePriceExceptionUiHandler,
          ]
        : [adPriceExceptionUiHandler, adMinPriceExceptionUiHandler];
    const { exceptionUiHandleBeforeCallApi } =
        useExceptionUiHandlerBeforeCallApi(RegisterAdExceptionUiHandlerList);

    const { openCommonErrorModal } = useOpenCommonErrorModal();
    useEffect(() => {
        openCommonErrorModal({
            title: `현재 이용하실 수 없습니다. 자세한 내용은 공지사항을 참조해주세요`,
        });
        navigate(-1);
    }, []);

    return (
        <AdRegisterContentsContainer>
            <AdRegisterAdjustTemplateRegisterAdjustCampaignTitle
                campaignName={campaignInfo.campaign_name}
                brandId={campaignInfo.brand_id}
                thumbnail={campaignInfo.campaign_thumbnail_path}
            />

            <AdRegisterAdjustIntroduce
                campaignName={campaignInfo.campaign_name}
                campaignDesc={campaignInfo.campaign_description}
            />

            <AdRegisterAdjustImageArea
                campaignSpacePreviewBackgroundPath={
                    campaignInfo.campaign_ad_sell_preview
                        ? campaignInfo.campaign_ad_sell_preview.map(
                              (preview) =>
                                  preview.campaign_ad_sell_preview_background_path,
                          )
                        : []
                }
            />

            <AdRegisterAdjustPriceInputBox
                {...adPriceState}
                {...adMinPriceState}
                isAdjust={false}
                setIsAdDaySale={setIsAdDaySale}
            />

            {/*<AdRegisterAdjustSelectPostingTime*/}
            {/*    postingTimeDropdownList={postingTimeDropdownList}*/}
            {/*    setPostingTimeDropdownList={setPostingTimeDropdownList}*/}
            {/*/>*/}

            <AdRegisterAdjustAutoAcceptSwitch
                isAdAutoAcceptOffer={isAdAutoAcceptOffer}
                setIsAdAutoAcceptOffer={setIsAdAutoAcceptOffer}
            />

            <AdRegisterAdjustBuyNowAvailableSwitch
                isAdBuyNowAvailable={isAdBuyNowAvailable}
                setIsAdBuyNowAvailable={setIsAdBuyNowAvailable}
            />

            <AdRegisterAdjustDaySaleSwitch
                isAdBuyNowAvailable={isAdBuyNowAvailable}
                adPrice={removeComma(adPriceState.adPrice.value)}
                isAdDaySale={isAdDaySale}
                setIsAdDaySale={setIsAdDaySale}
                {...adDaySaleStartTimeState}
                {...adDaySalePriceState}
            />

            <AdRegisterAdjustRefundInfo />

            <AdRegisterAdjustTerms
                termsList={adRegisterTermsList}
                setTermsList={setAdRegisterTermsList}
            />

            <AdRegisterAdjustButtonContainer>
                <AdRegisterAdjustButton
                    btnType={"filled-primary"}
                    onClick={async () => {
                        if (!checkIsAllTermAgree()) {
                            return false;
                        }

                        if (!(await exceptionUiHandleBeforeCallApi())) {
                            return false;
                        }

                        if (!isAddingAd) {
                            await exceptionUiHandleAfterCallApi(async () => {
                                await addAd();
                                navigate("/ad/manage/sale/schedule");
                            }, RegisterAdExceptionUiHandlerList);
                        }

                        // if (isRestartingAd ? !isRestartingAd : !isAddingAd) {
                        //     await exceptionUiHandleAfterCallApi(async () => {
                        //         if (!isReRegister) {
                        //             await addAd();
                        //         } else {
                        //             await restartAd();
                        //         }
                        //         navigate("/ad/manage/sale/schedule");
                        //     }, RegisterAdExceptionUiHandlerList);
                        // }
                    }}
                >
                    판매 등록
                </AdRegisterAdjustButton>
            </AdRegisterAdjustButtonContainer>
        </AdRegisterContentsContainer>
    );
};

export default AdRegisterContents;
