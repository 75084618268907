import React from "react";
import AdTemplateRegisterStepConfirmModal from "./AdTemplateRegisterStepConfirmModal";
import {
    PathAfterResolveType,
    useOpenModal,
} from "../../../../../../hooks/modal/useOpenModal";

export function useOpenAdTemplateRegisterConfirmModal() {
    const { openModalWithReturnValue } = useOpenModal();

    const openAdTemplateRegisterConfirmModal = async (
        campaignIdx: string,
        isPathAfterNestedResolve = false,
    ): Promise<PathAfterResolveType | boolean> => {
        return openModalWithReturnValue(
            "ad-template-register-alert-modal",
            "modal",
            (resolve) => {
                return (
                    <AdTemplateRegisterStepConfirmModal
                        resolve={resolve}
                        campaignIdx={campaignIdx}
                        isPathAfterNestedResolve={isPathAfterNestedResolve}
                    />
                );
            },
        );
    };
    return {
        openAdTemplateRegisterConfirmModal,
    };
}
