import { ReactElement } from "react";
import styled from "styled-components";

export const ItemTransferModalWarningListWrapper = styled.div`
    display: flex;
    padding: 14px 16px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 10px;
    border: 1px solid #e5e5e5;
`;

export const ItemTransferModalWarningListTitle = styled.div`
    color: #333;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 13px */
`;

export const ItemTransferModalWarningListContainer = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 6px;
`;

export const ItemTransferModalWarningMsgContainer = styled.div`
    display: flex;
    gap: 4px;
    align-items: baseline;
`;

export const ItemSendUseLayerWarningMsg = styled.div`
    color: #666;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 100% */
    display: flex;
    align-items: center;
`;

type Props = {
    warningList: (string | ReactElement)[];
};

const ItemTransferWarningList = (props: Props): ReactElement | null => {
    return (
        <ItemTransferModalWarningListWrapper>
            <ItemTransferModalWarningListTitle>
                유의사항
            </ItemTransferModalWarningListTitle>
            <ItemTransferModalWarningListContainer>
                {props.warningList.map((warningItem, idx) => {
                    return (
                        <ItemTransferModalWarningMsgContainer key={idx}>
                            <ItemSendUseLayerWarningMsg>
                                ·
                            </ItemSendUseLayerWarningMsg>
                            <ItemSendUseLayerWarningMsg>
                                {warningItem}
                            </ItemSendUseLayerWarningMsg>
                        </ItemTransferModalWarningMsgContainer>
                    );
                })}
            </ItemTransferModalWarningListContainer>
        </ItemTransferModalWarningListWrapper>
    );
};

export default ItemTransferWarningList;
