import { useQuery } from "react-query";
import { COMMA_SEPARATION_STRING } from "../../../types/BasicType";
import { CampaignSimpleListItemType } from "../../../types/ad/camapaignInfo/CampaignSimpleListItemType";
import { AdCampaignApi } from "../../../apis/adApi/AdCampaignApi";

export type AdCampaignSimpleListApiResult = {
    campaignList: CampaignSimpleListItemType[];
    totalCount: number;
};

export type AdCampaignSimpleListApiParans = {
    [key: string]: any;
    brand_id_array: COMMA_SEPARATION_STRING;
    campaign_type_array?: COMMA_SEPARATION_STRING;
};

export const useAdCampaignSimpleListQuery = (
    params: AdCampaignSimpleListApiParans,
    isEnabled = true,
) => {
    const queryResult = useQuery<AdCampaignSimpleListApiResult, Error>({
        queryKey: [
            "ad-campaign-list-" + JSON.stringify(params),
            "ad-campaign-list",
        ],
        queryFn: ({ pageParam = 1 }) => {
            return AdCampaignApi.getCampaignList({
                ...params,
            });
        },
        refetchOnWindowFocus: false,
        suspense: false,
        onError: (err) => {},
        enabled: isEnabled,
    });

    const defaultData: AdCampaignSimpleListApiResult = {
        campaignList: [],
        totalCount: 0,
    };
    return {
        ...queryResult,
        data: queryResult.data || defaultData,
    };
};
