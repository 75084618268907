import { css } from "styled-components";

/*
 * filled-danger / filled-danger-pending
 * ok / ok-pending / ok-disabled : ok-closed
 * red / red-disabled /
 *
 * filled-primary / filled-primary-disabled / filled-primary-pending
 * filled-secondary / filled-secondary-disabled
 * outlined-primary / outlined-primary-disabled
 * filled-danger / filled-danger-disabled / filled-danger-pending
 * text / text-disabled
 * */

export type CommonBtnStringType =
    | "filled-primary"
    | "filled-primary-disabled"
    | "filled-primary-pending"
    | "filled-primary-light"
    | "filled-blue"
    | "filled-secondary"
    | "filled-secondary-disabled"
    | "filled-danger"
    | "filled-danger-disabled"
    | "filled-danger-pending"
    | "outlined-primary"
    | "outlined-primary-disabled"
    | "outlined-secondary"
    | "outlined-secondary-disabled"
    | "text-primary"
    | "text-primary-disabled"
    | "text-secondary"
    | "text-secondary-disabled";

export type CommonButtonType = {
    btnType: CommonBtnStringType;
};
export const CommonButtonStyle = css<CommonButtonType>`
    box-sizing: border-box;
    transition: all 300ms ease;
    height: 48px;
    width: 100%;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ btnType }) => {
        switch (btnType) {
            /*FILLED-PRIMARY*/
            case "filled-primary":
                return `
                    background: #FA54FA;
                    color: white;
                    border: none;
                    font-weight: 700;
                    cursor: pointer;
                `;
            case "filled-primary-disabled":
                return `
                    background: rgb(229,229,229);
                    color: rgba(85,85,85,0.3);
                    opacity: 1;
                    border: none;
                    font-weight: 700;
                    cursor: auto;
                `;
            case "filled-primary-pending":
                return `
                    background: #F0F0F2;
                    color: white;
                    border: none;
                    font-weight: 700;
                `;

            case "filled-primary-light":
                return `
                    border-radius: 8px;
                    color: #FA54FA;
                    cursor: pointer;
                    background: #FBF2FC;
                    border: none;
                `;

            /*FILLED-SECONDARY*/
            case "filled-secondary":
                return `
                    background: rgba(13, 13, 26, 0.06);
                    color: #555;
                    border: none;
                    font-weight: 500;  
                    cursor: pointer;
                `;

            case "filled-secondary-disabled":
                return `
                    opacity: 1;
                    background: rgb(229,229,229);
                    color: rgba(85,85,85,0.3);
                    border: none;
                    font-weight: 500;
                `;

            /*FILLED-DANGER*/
            case "filled-danger":
                return `
                    background: rgba(255, 70, 113, 0.1);
                    color: #FF4671;
                    border: none;
                    font-weight: 500;
                    cursor: pointer;
                `;
            case "filled-danger-disabled":
                return `
                    background: #FF467110;
                    color: #FF467130;
                    border: none;
                    font-weight: 500;
                `;
            case "filled-danger-pending":
                return `
                    background: #F0F0F2;
                    color: white;
                    border: none;
                    font-weight: 500;
                `;

            case "filled-blue":
                return `background: #2E8EFF; 
                        color: white;
                        border: none;
                        font-weight:500;
                `;

            /*OUTLINED-PRIMARY*/
            case "outlined-primary":
                return `
                    background: white;
                    color: #FA54FA;
                    border: #FA54FA 1px solid;
                    font-weight: 500;
                    cursor: pointer;
                `;
            case "outlined-primary-disabled":
                return `
                    background: white;
                    color: #FA54FA30;
                    border: #FA54FA30 1px solid;
                    font-weight: 500;
                `;

            /*OUTLINED-SECONDARY*/
            case "outlined-secondary":
                return `
                    background: white;
                    color: #333;
                    border: 1px solid #D9D9D9;
                    font-weight: 500;
                    cursor: pointer;
                `;
            case "outlined-secondary-disabled":
                return `
                    background: white;
                    color: #33333330;
                    border: 1px solid #D9D9D930;
                    font-weight: 500;
                `;

            /*text*/
            /*text*/
            case "text-primary":
                return `
                    background: white;
                    color: #FA54FA;
                    border: none;
                    font-weight: 700;
                    cursor: pointer;
                `;
            case "text-primary-disabled":
                return `
                    background: white;
                    color: #55555520;
                    border: none;
                    font-weight: 500;  
                `;

            case "text-secondary":
                return `
                    background: white;
                    color: #555555;
                    border: none;
                    font-weight: 500;
                    cursor: pointer;
                `;
            case "text-secondary-disabled":
                return `
                    background: white;
                    color: #55555520;
                    border: none;
                    font-weight: 500;  
                `;
        }
    }};
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            ${({ btnType }) => {
                switch (btnType) {
                    /*FILLED-PRIMARY*/
                    case "filled-primary":
                        return `
                    background: #FA54FAB2;
                    color: white;
                    border: none;
                    font-weight: 700;
                `;

                    case "filled-primary-light":
                        return `
                    color: #FA54FA;
                    background: #FBF2FC;
                `;

                    /*FILLED-SECONDARY*/
                    case "filled-secondary":
                        return `
                    background: rgba(13, 13, 26, 0.12);
                    color: #555;
                    border: none;
                    font-weight: 500;  
                `;

                    /*FILLED-DANGER*/
                    case "filled-danger":
                        return `
                    background:  rgba(255, 70, 113, 0.16);
                    color: #FF4671;
                    border: none;
                    font-weight: 500;
                `;

                    case "filled-blue":
                        return `
                    opacity: 0.7;
                    border: none;
                    font-weight: 500;
                `;

                    /*OUTLINED-PRIMARY*/
                    case "outlined-primary":
                        return `
                    background: rgba(250, 84, 250, 0.10);
                    color: #FA54FA;
                    border: #FA54FA 1px solid;
                    font-weight: 500;
                `;

                    /*OUTLINED-SECONDARY*/
                    case "outlined-secondary":
                        return `
                    background: rgba(240, 240, 242, 0.70);
                    color: #333;
                    border: 1px solid #D9D9D9;
                    font-weight: ;
                `;

                    /*text*/
                    case "text-primary":
                        return `
                    background: rgba(250, 84, 250, 0.10);
                    color: #FA54FA;
                    border: none;
                    font-weight: 700;
                `;

                    case "text-secondary":
                        return `
                    background: rgba(240, 240, 242, 0.70);
                    color: #555555;
                    border: none;
                    font-weight: 500;
                `;
                }
            }};
        }
    }
`;
