import { RefObject } from "react";
import { InputTextDataType } from "../../components/common/commonUnitComponent/commonInputText/CommonInputText";

export type ExceptionUiHandlerType = {
    errorTarget: ExceptionUiHandlerErrorTargetType;
    targetContainerRef?: RefObject<HTMLDivElement>;
    // inputTextType
    inputText?: InputTextDataType;
    setInputText?: (value: InputTextDataType) => void;
    isDuplicatePass?: boolean;
    isBlankCheck?: boolean;
    isWarningCheck?: boolean;
    isDuplicateCheck?: boolean;
    // fileType
    file?: File;
    isFileMinSizeCheck?: boolean;
    // idx Type
    selectedItemIdx?: string;
    // path type
    filePath?: string;
    // list type
    dataList?: any[];
    // object type
    selectedObject?: any;
};

export const DefaultExceptionUiHandlerType = {
    isBlankCheck: true,
    isWarningCheck: true,
    isDuplicateCheck: true,
};

export type ExceptionUiHandlerErrorTargetType = {
    targetName: string;
    targetColumn?: string;
    targetIdx?: string;
};

export const NEED_TO_DUPLICATE_CHECK_MSG = "중복확인을 해주시기 바랍니다.";

// 일단 금칙어 나오는 곳 예외처리해둔다.
export const exceptionUiHandleByApiErrorCode = function (
    handler: ExceptionUiHandlerType,
    error: any,
    errorTarget: ExceptionUiHandlerErrorTargetType,
) {
    const setErrorMsg = () => {
        if (handler.inputText && handler.setInputText) {
            handler.setInputText({
                ...handler.inputText,
                isWarning: true,
                warningMsg: error.response.data.message,
            });
        }
    };

    try {
        if (
            // response 에서 에러가 난 위치 체크
            error.response.data.data.errorCode.indexOf(
                errorTarget.targetName,
            ) !== -1
        ) {
            // reference 가 있는 경우 - 금칙어
            if (error.response.data.data.reference) {
                if (
                    error.response.data.data.reference.indexOf(
                        `${errorTarget.targetColumn} - ${errorTarget.targetIdx}`,
                    ) !== -1
                ) {
                    setErrorMsg();
                }
                // reference 와 errorTarget 이 맞지 않는 경우
                else {
                    return true;
                }
            }
            // reference 가 없는 경우 set error message
            else {
                setErrorMsg();
            }

            // 스크롤 이동 - 금지어 클릭 이벤트에 겹쳐져서 중간에 멈추는 문제 있어서 timeout 강제로줌
            if (handler.targetContainerRef) {
                setTimeout(() => {
                    if (handler.targetContainerRef) {
                        handler.targetContainerRef.current?.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                        });
                    }
                }, 300);
            }
            return false;
        }
    } catch (e) {
        console.info("banword - export - error");
        return false;
    }
    return true;
};

export async function exceptionUiHandleAfterCallApi(
    callApi: () => Promise<void>,
    exceptionUiHandlers: ExceptionUiHandlerType[],
) {
    try {
        await callApi();
        return true;
    } catch (error) {
        let breakFlag = false;
        for (let i = 0; i < exceptionUiHandlers.length; i++) {
            const handler = exceptionUiHandlers[i];
            // 금칙어 검증
            breakFlag = !exceptionUiHandleByApiErrorCode(
                handler,
                error,
                handler.errorTarget,
            );

            if (breakFlag) {
                break;
            }
        }
        return !breakFlag;
    }
}
