import { ReactElement } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../../../../../../../constants/UrlConstants";
import { useOpenAdTemplateRegisterConfirmModal } from "../../../../../../../../_adTemplate/adTemplateRegisterStep/components/adTemplateRegisterStepConfirmModal/useOpenAdTemplateRegisterConfirmModal";
import {
    hasPathAfterNestedResolve,
    PathAfterResolveType,
} from "../../../../../../../../../../hooks/modal/useOpenModal";

export const AdBidingSelectMyTemplateListHeaderContainer = styled.div`
    margin: 0 24px;
    width: calc(100% - 48px);
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f9f2ff;
    border-radius: 12px;

    @media ${(props) => props.theme.mobileL} {
        margin: 0 16px;
        width: calc(100% - 32px);
    }
`;

export const AdBidingSelectMyTemplateListHeaderLeftContainer = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #555555;

    @media ${(props) => props.theme.mobileL} {
        font-size: 13px;
        line-height: 18px;
    }
`;

export const AdBidingSelectMyTemplateListHeaderWarningIcon = styled.img`
    margin-left: 16px;
    margin-right: 4px;
`;

export const AdBidingSelectMyTemplateListHeaderMakeTemplateButton = styled.div`
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    padding: 16px;
    cursor: pointer;
    color: #fa54fa;
    flex-shrink: 0;
`;

type Props = {
    resolve: (value: PathAfterResolveType | boolean) => void;
    isNoData: boolean;
    campaignIdx: string;
};

const AdBidingSelectMyTemplateListHeader = (
    props: Props,
): ReactElement | null => {
    const location = useLocation();
    const { openAdTemplateRegisterConfirmModal } =
        useOpenAdTemplateRegisterConfirmModal();

    return !props.isNoData ? (
        <AdBidingSelectMyTemplateListHeaderContainer>
            <AdBidingSelectMyTemplateListHeaderLeftContainer>
                <AdBidingSelectMyTemplateListHeaderWarningIcon
                    src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-info.svg`}
                />
                광고 입찰은 검토완료된 템플릿만 사용 가능합니다.
            </AdBidingSelectMyTemplateListHeaderLeftContainer>
            <AdBidingSelectMyTemplateListHeaderMakeTemplateButton
                onClick={async () => {
                    const templateRegisterConfirmResult =
                        await openAdTemplateRegisterConfirmModal(
                            props.campaignIdx,
                            true,
                        );

                    if (
                        typeof templateRegisterConfirmResult !== "boolean" &&
                        hasPathAfterNestedResolve(templateRegisterConfirmResult)
                    ) {
                        props.resolve({
                            pathAfterResolve:
                                templateRegisterConfirmResult.pathAfterNestedResolve,
                            state: templateRegisterConfirmResult.state,
                        });
                    }
                }}
            >
                템플릿 만들기
            </AdBidingSelectMyTemplateListHeaderMakeTemplateButton>
        </AdBidingSelectMyTemplateListHeaderContainer>
    ) : null;
};

export default AdBidingSelectMyTemplateListHeader;
