import { ReactElement } from "react";
import styled from "styled-components";
import { BrandInfoType } from "../../../../../../../types/brand/BrandInfoType";
import {
    CommonButtonStyle,
    CommonButtonType,
} from "../../../../../commonStyle/CommonButtonStyle";
import { CampaignInfoType } from "../../../../../../../types/ad/camapaignInfo/CampaignInfoType";
import { TEMPLATE_INFO_MODAL_PREVIEW } from "../../../TemplateInfoModal";

export const TemplateInfoModalBrandInfoWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 24px;
    width: calc(100% - 48px);

    @media ${(props) => props.theme.mobileL} {
        padding: 0 16px;
        width: calc(100% - 32px);
    }
`;

export const TemplateInfoModalBrandInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
`;

export const TemplateInfoModalBrandLogo = styled.img`
    width: auto;
    height: 14px;
    object-fit: contain;
`;

export const TemplateInfoModalCampaignName = styled.div`
    color: #222;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
`;

export const TemplateInfoModalBrandInfoPreviewBtn = styled.div<CommonButtonType>`
    ${CommonButtonStyle};
    height: 34px;
    padding: 0 12px;
    width: auto;
    color: #555;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
`;

type Props = {
    brandInfo: BrandInfoType;
    campaignInfo: CampaignInfoType;
    setPageType: (value: string) => void;
};

const TemplateInfoModalBrandInfo = (props: Props): ReactElement | null => {
    return (
        <TemplateInfoModalBrandInfoWrapper>
            <TemplateInfoModalBrandInfoContainer>
                <TemplateInfoModalBrandLogo
                    src={props.brandInfo.brand_bi_full_path}
                />
                <TemplateInfoModalCampaignName>
                    {props.campaignInfo.campaign_name}
                </TemplateInfoModalCampaignName>
            </TemplateInfoModalBrandInfoContainer>
            <TemplateInfoModalBrandInfoPreviewBtn
                btnType={"filled-secondary"}
                onClick={() => {
                    props.setPageType(TEMPLATE_INFO_MODAL_PREVIEW);
                }}
            >
                노출 영역 예시
            </TemplateInfoModalBrandInfoPreviewBtn>
        </TemplateInfoModalBrandInfoWrapper>
    );
};

export default TemplateInfoModalBrandInfo;
