import { ReactElement } from "react";
import styled from "styled-components";
import { TemplateInfoSpaceTypeWithIsSelected } from "../TemplateInfoModalTemplateSpaceInfo";

export const TemplateInfoModalTemplateSpaceInfoContentsContainer = styled.div`
    width: calc(100% - 48px);
    display: flex;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 16px;
    background: #f5f5f7;

    @media ${(props) => props.theme.mobileL} {
        padding: 16px;
        margin: 0 16px;
        width: calc(100% - 64px);
    }
`;
export const TemplateInfoModalTemplateSpaceInfoContentsRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
`;

export const TemplateInfoModalTemplateSpaceInfoContentsRowKey = styled.div`
    color: #888;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */
`;

export const TemplateInfoModalTemplateSpaceInfoContentsRowImg = styled.img`
    max-width: 100%;

    @media ${(props) => props.theme.mobileL} {
        max-width: 100%;
    }
`;

export const TemplateInfoModalTemplateSpaceInfoContentsRowValue = styled.div``;

type Props = {
    selectedTemplateSpace: TemplateInfoSpaceTypeWithIsSelected;
};

const TemplateInfoModalTemplateSpaceInfoContents = (
    props: Props,
): ReactElement | null => {
    return (
        <TemplateInfoModalTemplateSpaceInfoContentsContainer>
            {props.selectedTemplateSpace.template_space_element.map(
                (spaceElement, spaceElementIdx) => {
                    return (
                        <TemplateInfoModalTemplateSpaceInfoContentsRow
                            key={spaceElement.campaign_space_element_idx}
                        >
                            <TemplateInfoModalTemplateSpaceInfoContentsRowKey>
                                {spaceElement.presv_campaign_space_element_name}
                            </TemplateInfoModalTemplateSpaceInfoContentsRowKey>
                            {spaceElement.presv_campaign_space_element_type ===
                            "1" ? (
                                <TemplateInfoModalTemplateSpaceInfoContentsRowImg
                                    src={
                                        spaceElement.template_space_element_img_path
                                    }
                                />
                            ) : (
                                <TemplateInfoModalTemplateSpaceInfoContentsRowValue>
                                    {spaceElement.template_space_element_text}
                                </TemplateInfoModalTemplateSpaceInfoContentsRowValue>
                            )}
                        </TemplateInfoModalTemplateSpaceInfoContentsRow>
                    );
                },
            )}
        </TemplateInfoModalTemplateSpaceInfoContentsContainer>
    );
};

export default TemplateInfoModalTemplateSpaceInfoContents;
