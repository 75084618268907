import React, { ReactElement } from "react";
import styled from "styled-components";
import { ModalTitle } from "../../../../../../../common/modal/ModalStyle";

export const AdBidingSelectMyTemplateListHeaderContainer = styled.div`
    position: relative;
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media ${(props) => props.theme.mobileL} {
        padding: 0;
    }
`;

export const AdBidingSelectMyTemplateListHeaderTabContainer = styled.div`
    margin-top: 24px;
    width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #ebebeb;
`;

export const AdBidingSelectMyTemplateListHeaderTab = styled.div<{
    isSelected: boolean;
}>`
    width: 272px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ isSelected }) => (isSelected ? "#FA54FA" : "#888")};
    font-size: 16px;
    font-style: normal;
    font-weight: ${({ isSelected }) => (isSelected ? 700 : 500)};
    line-height: 100%; /* 16px */
    border-bottom: ${({ isSelected }) =>
        isSelected ? "2px #fa54fa solid" : "none"};
    transition: all 300ms ease;
    box-sizing: border-box;
    cursor: pointer;

    @media ${(props) => props.theme.mobileL} {
        width: 100%;
        font-size: 15px;
    }
`;

type Props = {
    isPublicTemplateTab: boolean;
    setIsPublicTemplateTab: (value: boolean) => void;
};

const AdBidingSelectModalTabSelector = (props: Props): ReactElement | null => {
    return (
        <AdBidingSelectMyTemplateListHeaderContainer>
            <ModalTitle>광고 템플릿 선택</ModalTitle>
            <AdBidingSelectMyTemplateListHeaderTabContainer>
                <AdBidingSelectMyTemplateListHeaderTab
                    isSelected={!props.isPublicTemplateTab}
                    onClick={() => {
                        props.setIsPublicTemplateTab(
                            !props.isPublicTemplateTab,
                        );
                    }}
                >
                    내 템플릿
                </AdBidingSelectMyTemplateListHeaderTab>
                <AdBidingSelectMyTemplateListHeaderTab
                    isSelected={props.isPublicTemplateTab}
                    onClick={() => {
                        props.setIsPublicTemplateTab(
                            !props.isPublicTemplateTab,
                        );
                    }}
                >
                    공유 템플릿
                </AdBidingSelectMyTemplateListHeaderTab>
            </AdBidingSelectMyTemplateListHeaderTabContainer>
        </AdBidingSelectMyTemplateListHeaderContainer>
    );
};

export default AdBidingSelectModalTabSelector;
