export const AdBuyManagePublicTemplateListSortingDayOffsetList = [
    {
        title: "기간 7일",
        key: "7",
        isSelected: false,
    },
    {
        title: "기간 15일",
        key: "15",
        isSelected: false,
    },
    {
        title: "기간 30일",
        key: "30",
        isSelected: true,
    },
    {
        title: "기간 365일",
        key: "365",
        isSelected: false,
    },
    {
        title: "전체기간",
        key: "0",
        isSelected: false,
    },
];

// 최근 등록순
// 인기순 - 기간노출 - 템플릿 리스트 아이템에 기간 노출할 것
// 최근 게재순
export const AdBuyManagePublicTemplateListSortingTypeList = [
    {
        title: "최근 등록순",
        key: "created_at_desc",
        isSelected: true,
    },
    {
        title: "인기순",
        key: "template_success_count_desc",
        isSelected: false,
    },
    {
        title: "최근 게재순",
        key: "last_succeed_at_desc",
        isSelected: false,
    },
];

// 인기순 => 선택된 기간} 동안 이 광고상품에서 사용횟수
