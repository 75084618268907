import { ReactElement, useState } from "react";
import styled from "styled-components";
import { SortingItemType } from "../../../../../../../../common/commonUiComponent/doubleSideBar/leftSideBar/LeftSideBar";
import Scrollbar from "../../../../../../../../common/commonUiComponent/scrollbar/Scrollbar";
import {
    AdBuyManagePublicTemplateListSortingDayOffsetList,
    AdBuyManagePublicTemplateListSortingTypeList,
} from "../../../../../../../adManage/adBuyManage/adBuyManagePublicTemplates/components/adBuyManagePublicTemplateListHub/AdBuyManagePublicTemplateListHubConstants";
import AdBidingSelectPublicTemplateListHeader from "./components/AdBidingSelectPublicTemplateListHeader";
import { SCROLL_BAR_NORMAL_TYPE } from "../../../../../../../../common/commonUiComponent/scrollbar/ScrollbarType";
import viewHeightAtom from "../../../../../../../../../store/scroll/viewHeightAtom";
import { useAtom } from "jotai";
import { useDebounce } from "usehooks-ts";
import { PathAfterResolveType } from "../../../../../../../../../hooks/modal/useOpenModal";
import AdBidingSelectPublicTemplateList from "./components/AdBidingSelectPublicTemplateList";
import { getSelectedSortingList } from "../../../../../../../../../utils/Utils";
import AdBidingSelectPublicTemplateNoDataList from "./components/AdBidingSelectPublicTemplateNoDataList";

export const AdBidingSelectPublicTemplateListHubWrapper = styled.div`
    z-index: 1;
    margin: 16px 24px 0;

    @media ${(props) => props.theme.mobileL} {
        margin: 16px 16px 0 16px;
    }
`;

export const AdBidingSelectPublicTemplateListHubScrollWrapper = styled.div<{
    vh: number;
    isCheckBoxShow: boolean;
}>`
    margin-top: 24px;
    position: relative;
    height: ${({ vh, isCheckBoxShow }) =>
        "calc(" + 100 * vh + "px - " + (isCheckBoxShow ? 344 : 380) + "px)"};
    max-height: ${({ isCheckBoxShow }) => (isCheckBoxShow ? "464px" : "500px")};

    @media ${(props) => props.theme.mobileL} {
        max-height: 460px;
        margin-top: 16px;
    }
`;

export const AdBidingSelectPublicTemplateListHubContainer = styled.div`
    margin-bottom: 24px;
    @media ${(props) => props.theme.mobileL} {
        margin-bottom: 16px;
    }
`;

export const AdBidingSelectPublicTemplateListHubSearchResultContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
`;

type Props = {
    resolve: (value: PathAfterResolveType | boolean) => void;
    campaignIdx: string;
    adIdx: string;
    setSelectedPreviewTemplateIdx: (value: string) => void;
};

const AdBidingSelectPublicTemplateHub = (props: Props): ReactElement | null => {
    const [vh] = useAtom(viewHeightAtom);
    const [searchInput, setSearchInput] = useState("");
    const debouncedSearchInput = useDebounce<string>(searchInput, 500);
    const [sortingDayOffsetList, setSortingDayOffsetList] = useState<
        SortingItemType[]
    >(AdBuyManagePublicTemplateListSortingDayOffsetList);
    const [sortingTypeList, setSortingTypeList] = useState<SortingItemType[]>(
        AdBuyManagePublicTemplateListSortingTypeList,
    );
    const selectedSortingType = getSelectedSortingList(sortingTypeList);

    const [isOnlySuccessTemplate, setIsOnlySuccessTemplate] = useState(false);

    const publicTemplateListQueryParams = {
        campaign_idx: props.campaignIdx,
        daysOffset: getSelectedSortingList(sortingDayOffsetList),
        sort_type: getSelectedSortingList(sortingTypeList),
        ...(isOnlySuccessTemplate &&
            selectedSortingType === "template_success_count_desc" && {
                ad_idx: props.adIdx,
            }),
        limit: 8,
        page_no: 1,
    };

    const [templateListLength, setTemplateListLength] = useState(0);
    const [templateNameResultListLength, setTemplateNameResultListLength] =
        useState(0);
    const [
        templateTagNameResultListLength,
        setTemplateTagNameResultListLength,
    ] = useState(0);

    return (
        <AdBidingSelectPublicTemplateListHubWrapper>
            <AdBidingSelectPublicTemplateListHeader
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                sortingDayOffsetList={sortingDayOffsetList}
                setSortingDayOffsetList={setSortingDayOffsetList}
                sortingTypeList={sortingTypeList}
                setSortingTypeList={setSortingTypeList}
                isOnlySuccessTemplate={isOnlySuccessTemplate}
                setIsOnlySuccessTemplate={setIsOnlySuccessTemplate}
            />

            <AdBidingSelectPublicTemplateListHubScrollWrapper
                vh={vh}
                isCheckBoxShow={
                    selectedSortingType === "template_success_count_desc" &&
                    searchInput === ""
                }
            >
                <Scrollbar
                    type={SCROLL_BAR_NORMAL_TYPE}
                    id={"ad-biding-select-public-template-list"}
                    isThin={true}
                >
                    <AdBidingSelectPublicTemplateListHubContainer>
                        {debouncedSearchInput === "" ? (
                            <AdBidingSelectPublicTemplateList
                                {...props}
                                debouncedSearchInput={debouncedSearchInput}
                                searchInput={searchInput}
                                setSearchInput={setSearchInput}
                                publicTemplateListQueryParams={
                                    publicTemplateListQueryParams
                                }
                            />
                        ) : (
                            <AdBidingSelectPublicTemplateListHubSearchResultContainer>
                                {debouncedSearchInput !== "" &&
                                    templateNameResultListLength === 0 &&
                                    templateTagNameResultListLength === 0 && (
                                        <AdBidingSelectPublicTemplateNoDataList
                                            searchInput={debouncedSearchInput}
                                        />
                                    )}
                                <AdBidingSelectPublicTemplateList
                                    resolve={props.resolve}
                                    debouncedSearchInput={debouncedSearchInput}
                                    searchInput={searchInput}
                                    setSearchInput={setSearchInput}
                                    setSelectedPreviewTemplateIdx={
                                        props.setSelectedPreviewTemplateIdx
                                    }
                                    publicTemplateListQueryParams={{
                                        ...publicTemplateListQueryParams,
                                        template_name: debouncedSearchInput,
                                    }}
                                    setResultListLength={
                                        setTemplateNameResultListLength
                                    }
                                />
                                <AdBidingSelectPublicTemplateList
                                    resolve={props.resolve}
                                    debouncedSearchInput={debouncedSearchInput}
                                    searchInput={searchInput}
                                    setSearchInput={setSearchInput}
                                    setSelectedPreviewTemplateIdx={
                                        props.setSelectedPreviewTemplateIdx
                                    }
                                    publicTemplateListQueryParams={{
                                        ...publicTemplateListQueryParams,
                                        template_tag_name: debouncedSearchInput,
                                    }}
                                    setResultListLength={
                                        setTemplateTagNameResultListLength
                                    }
                                />
                            </AdBidingSelectPublicTemplateListHubSearchResultContainer>
                        )}
                    </AdBidingSelectPublicTemplateListHubContainer>
                </Scrollbar>
            </AdBidingSelectPublicTemplateListHubScrollWrapper>
        </AdBidingSelectPublicTemplateListHubWrapper>
    );
};

export default AdBidingSelectPublicTemplateHub;
