import { ReactElement } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { useGetExternalAdSpaceInfoQuery } from "../../../query/externalAd/useGetExternalAdSpaceInfoQuery";
import ApiResBoundary from "../../common/apiResBoundary/ApiResBoundary";
import { onErrorImg } from "../../../utils/Utils";

const ListItemTypeSelfAdContainerStyle = css`
    display: flex;
    gap: 10px;
    position: relative;

    width: calc(100% - 20px);
    height: 80px;

    padding: 0 10px;
    align-items: center;
    border-radius: 12px;
    transition: all 300ms ease;
    background: linear-gradient(0deg, #f2f5fa 0%, #f2f5fa 100%),
        linear-gradient(0deg, #f2f5fa 0%, #f2f5fa 100%), #f0f0f5;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: linear-gradient(0deg, #fbf2fc 0%, #fbf2fc 100%),
                linear-gradient(0deg, #fbf2fc 0%, #fbf2fc 100%), #f0f0f5;
        }
    }
`;

export const ListItemTypeSelfAdContainer = styled(Link)`
    ${ListItemTypeSelfAdContainerStyle}
`;

export const ListItemTypeSelfAdBadge = styled.div`
    top: 6px;
    right: 8px;
    position: absolute;
    padding: 3px 5px;
    border-radius: 20px;
    border: 1px solid #d9d9d9;
    color: #999;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 11px */
`;

export const ListItemTypeSelfAdImg = styled.img`
    width: 60px;
    height: 60px;
    border-radius: 8px;
`;

export const ListItemTypeSelfAdMsgContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6px;
    overflow: hidden;
`;

export const ListItemTypeSelfAdMsgTitle = styled.div`
    width: calc(100% - 42px);
    color: #222;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 21px */
    word-break: keep-all;
`;

export const ListItemTypeSelfAdMsgDesc = styled.div`
    overflow: hidden;
    color: #666;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 13px */
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const ListItemTypeSelfAdErrorContainer = styled.div`
    ${ListItemTypeSelfAdContainerStyle}
`;

export const ListItemTypeSelfAdErrorMsgContainer = styled.div`
    display: flex;
    gap: 2px;
    align-items: center;
    color: #666;

    text-overflow: ellipsis;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 16.9px */
`;

export const ListItemTypeSelfAdErrorIcon = styled.img`
    width: 32px;
    height: 32px;
`;

export type Props = {
    spaceId: string;
    isEnabled?: boolean;
};

const ListItemTypeSelfAd = (props: Props): ReactElement | null => {
    const {
        data: rawExternalAdSpaceInfo,
        isLoading: isExternalAdSpaceInfoLoading,
        error: errorExternalAdSpaceInfo,
    } = useGetExternalAdSpaceInfoQuery(
        {
            brandId: "saybuzz",
            campaignId: "saybuzz",
            spaceId: props.spaceId,
        },
        {
            enabled:
                typeof props.isEnabled !== "undefined" ? props.isEnabled : true,
        },
    );

    return (
        <ApiResBoundary
            error={errorExternalAdSpaceInfo}
            isLoading={isExternalAdSpaceInfoLoading}
            isNoData={rawExternalAdSpaceInfo.space_element.length === 0}
            errorFallback={(error) => <></>}
        >
            <ListItemTypeSelfAdContainer
                to={rawExternalAdSpaceInfo.log.click_url}
                target={"_blank"}
            >
                <ListItemTypeSelfAdBadge>AD</ListItemTypeSelfAdBadge>
                <ListItemTypeSelfAdImg
                    src={rawExternalAdSpaceInfo.space_element[0]?.image_url}
                    onError={onErrorImg}
                />
                <ListItemTypeSelfAdMsgContainer>
                    <ListItemTypeSelfAdMsgTitle>
                        {rawExternalAdSpaceInfo.space_element[1]?.text}
                    </ListItemTypeSelfAdMsgTitle>
                    <ListItemTypeSelfAdMsgDesc>
                        {rawExternalAdSpaceInfo.space_element[2]?.text}
                    </ListItemTypeSelfAdMsgDesc>
                </ListItemTypeSelfAdMsgContainer>
            </ListItemTypeSelfAdContainer>
        </ApiResBoundary>
    );
};

export default ListItemTypeSelfAd;
