import { ReactElement } from "react";
import styled from "styled-components";
import SubSearch from "../../../../../../../common/commonUiComponent/subSearch/SubSearch";
import SortingButton from "../../../../../../../common/commonUnitComponent/sortingButton/SortingButton";
import { SortingItemType } from "../../../../../../../common/commonUiComponent/doubleSideBar/leftSideBar/LeftSideBar";
import { getSelectedSortingList } from "../../../../../../../../utils/Utils";

export const AdBuyManagePublicTemplateListHeaderWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 16px;

    @media ${(props) => props.theme.mobileL} {
        width: 100%;
        flex-direction: column-reverse;
        align-items: flex-end;
        gap: 8px;
    }
`;

export const AdBuyManagePublicTemplateListHeaderSortingContainer = styled.div`
    display: flex;
    gap: 4px;
`;

export const AdBuyManagePublicTemplateListHeaderSearchContainer = styled.div`
    flex-shrink: 2;
    width: 330px;

    @media ${(props) => props.theme.mobileL} {
        width: 100%;
    }
`;

type Props = {
    searchInput: string;
    setSearchInput: (searchInput: string) => void;
    sortingDayOffsetList: SortingItemType[];
    setSortingDayOffsetList: (value: SortingItemType[]) => void;
    sortingTypeList: SortingItemType[];
    setSortingTypeList: (value: SortingItemType[]) => void;
    isAdBidingModal?: boolean;
};

const AdBuyManagePublicTemplateListHeader = (
    props: Props,
): ReactElement | null => {
    const selectedSortingType = getSelectedSortingList(props.sortingTypeList);

    return (
        <AdBuyManagePublicTemplateListHeaderWrapper>
            {props.searchInput === "" && (
                <AdBuyManagePublicTemplateListHeaderSortingContainer>
                    {selectedSortingType === "template_success_count_desc" && (
                        <SortingButton
                            sortingList={props.sortingDayOffsetList}
                            setSortingList={props.setSortingDayOffsetList}
                            isDownArrowBtn={true}
                            isLeftStartOpen={props.isAdBidingModal}
                        />
                    )}
                    <SortingButton
                        sortingList={props.sortingTypeList}
                        setSortingList={props.setSortingTypeList}
                        isDownArrowBtn={true}
                    />
                </AdBuyManagePublicTemplateListHeaderSortingContainer>
            )}
            <AdBuyManagePublicTemplateListHeaderSearchContainer>
                <SubSearch
                    searchInput={props.searchInput}
                    setSearchInput={props.setSearchInput}
                    placeholder={"템플릿 이름 또는 태그를 입력해주세요."}
                />
            </AdBuyManagePublicTemplateListHeaderSearchContainer>
        </AdBuyManagePublicTemplateListHeaderWrapper>
    );
};

export default AdBuyManagePublicTemplateListHeader;
