import { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import { ModalButton, ModalButtonContainer, ModalTitle } from "../ModalStyle";
import ModalCloseIcon from "../modalCloseIcon/ModalCloseIcon";
import { ItemListItemType } from "../../../../query/item/useItemListQuery";
import { useUseItemMutation } from "../../../../query/item/use/useUseItemMutation";
import { useGetOwnItemCnt } from "../../../../query/item/useGetOwnItemCnt";
import { useOpenCommonErrorModal } from "../../../../hooks/modal/openModal/useOpenCommonErrorModal";
import { Link } from "react-router-dom";
import ItemTransferUseModalItemInfo from "../itemTransferModal/components/ItemTransferUseModalItemInfo";
import ItemTransferCountInput from "../itemTransferModal/components/ItemTransferCountInput";
import ItemTransferWarningList from "../itemTransferModal/components/ItemTransferWarningList";

export const ItemUseModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 352px;
    margin: 0;

    cursor: auto;
    overflow: visible;
    @media ${(props) => props.theme.mobileL} {
        width: 100%;
    }
`;

export const ItemUseModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    margin-top: 24px;
`;

export const ItemSendLayerWarningDescLink = styled(Link)`
    &:focus,
    &:visited,
    &:link,
    &:active {
        text-decoration: underline;
        color: #666;
    }
`;

type Props = {
    resolve: (value: boolean) => void;
    item: ItemListItemType;
};

const ItemUseModal = (props: Props): ReactElement | null => {
    const { openCommonErrorModal } = useOpenCommonErrorModal();
    const {
        data: rawOwnItemCnt,
        refetch: refetchOwnItemCnt,
        isSuccess: isOwnItemCntSuccess,
    } = useGetOwnItemCnt(props.item.item_idx);

    const [inputNumber, setInputNumber] = useState(1);
    const { mutateAsync: useItem } = useUseItemMutation(props.item.item_idx, {
        count: inputNumber,
    });
    const warningList = [
        "사용한 아이템은 복구할 수 없으며, 환불이 불가능합니다.",
        "아이템은 사용 즉시 소모되며, 해당 브랜드에서 사용된 상태를 확인할 수 있습니다.",
        "‘SOOP  → 선물·후원 → 선물받은 별풍선’에서 일반, 베스트, 파트너 별풍선 환전 시 사용이 가능합니다.",
        <>
            <ItemSendLayerWarningDescLink
                to={"https://ch.sooplive.co.kr/saybuzz/posts/99386269"}
                target={"_blank"}
            >
                세이버즈 고객센터
            </ItemSendLayerWarningDescLink>
        </>,
    ];

    const isUseAble = inputNumber !== 0;

    useEffect(() => {
        if (inputNumber <= 0) {
            setInputNumber(1);
        }
    }, [inputNumber]);

    useEffect(() => {
        if (isOwnItemCntSuccess && rawOwnItemCnt.own_item_cnt === 0) {
            openCommonErrorModal({
                title: "전송 가능한 수량이 없습니다.",
            });
            props.resolve(false);
        }
    }, [isOwnItemCntSuccess, rawOwnItemCnt]);

    return (
        <ItemUseModalWrapper>
            <ModalTitle>아이템 사용</ModalTitle>
            <ModalCloseIcon resolve={props.resolve} />

            <ItemUseModalContainer>
                <ItemTransferUseModalItemInfo
                    thumbnailPath={props.item.item_thumbnail_full_path}
                    itemName={props.item.item_name}
                    projectName={props.item.project_name}
                />

                <ItemTransferCountInput
                    count={inputNumber}
                    setCount={setInputNumber}
                    maxCount={rawOwnItemCnt.own_item_cnt}
                    refetchMaxCount={refetchOwnItemCnt}
                    title={"사용"}
                />

                <ItemTransferWarningList warningList={warningList} />
            </ItemUseModalContainer>

            <ModalButtonContainer>
                <ModalButton
                    btnType={"filled-secondary"}
                    onClick={() => {
                        props.resolve(false);
                    }}
                >
                    취소
                </ModalButton>
                <ModalButton
                    btnType={
                        isUseAble ? "filled-primary" : "filled-primary-disabled"
                    }
                    onClick={async () => {
                        if (isUseAble) {
                            try {
                                const result = await useItem();
                                props.resolve(true);
                            } catch (e: any) {
                                props.resolve(false);
                            }
                        }
                    }}
                >
                    사용하기
                </ModalButton>
            </ModalButtonContainer>
        </ItemUseModalWrapper>
    );
};

export default ItemUseModal;
