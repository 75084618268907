import { ReactElement } from "react";
import styled from "styled-components";
import { CampaignSimpleListItemType } from "../../../../../../../types/ad/camapaignInfo/CampaignSimpleListItemType";
import AdBuyManagePublicTemplatesCampaignSelectorCampaignListItem from "./components/AdBuyManagePublicTemplatesCampaignSelectorCampaignListItem";
import Carousel, {
    RenderComponentDataType,
} from "../../../../../../common/commonUnitComponent/carousel/Carousel";
import { TemplateInfoModalPreviewCarouselArrowButton } from "../../../../../../common/modal/templateInfoModal/components/TemplateInfoModalPreview";

export const AdBuyManagePublicTemplatesCampaignSelectorWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
`;

export const AdBuyManagePublicTemplatesCampaignSelectorCampaignList = styled.div`
    width: calc(100% + 24px);
    margin-left: -12px;
    height: auto;

    @media ${(props) => props.theme.mobileL} {
        width: calc(100% + 32px);
        margin-left: -16px;
    }
`;

type Props = {
    selectedCampaignIdx: string;
    setSelectedCampaignIdx: (value: string) => void;
    campaignList: CampaignSimpleListItemType[];
};

const AdBuyManagePublicTemplatesCampaignSelector = (
    props: Props,
): ReactElement | null => {
    return (
        <AdBuyManagePublicTemplatesCampaignSelectorWrapper>
            <AdBuyManagePublicTemplatesCampaignSelectorCampaignList>
                <Carousel
                    isShowPagination={true}
                    responsiveDisplayLength={[4, 4, 3, 2.5, 1.5, 1.5]}
                    dataList={props.campaignList}
                    displayLength={1}
                    renderComponent={(data: RenderComponentDataType) => {
                        return (
                            <AdBuyManagePublicTemplatesCampaignSelectorCampaignListItem
                                key={
                                    data.listItem.campagin_idx + "-" + data.idx
                                }
                                campaignInfo={data.listItem}
                                selectedCampaignIdx={props.selectedCampaignIdx}
                                setSelectedCampaignIdx={
                                    props.setSelectedCampaignIdx
                                }
                            />
                        );
                    }}
                    renderArrow={(data) => (
                        <TemplateInfoModalPreviewCarouselArrowButton
                            direction={data.type}
                        />
                    )}
                />
            </AdBuyManagePublicTemplatesCampaignSelectorCampaignList>
        </AdBuyManagePublicTemplatesCampaignSelectorWrapper>
    );
};

export default AdBuyManagePublicTemplatesCampaignSelector;
