import { useOpenModal } from "../useOpenModal";
import TemplateInfoModal, {
    TemplateInfoModalTargetInfoType,
    TemplateResultType,
} from "../../../components/common/modal/templateInfoModal/TemplateInfoModal";

export function useOpenTemplateInfoModal() {
    const { openModalWithReturnValue } = useOpenModal();
    const openTemplateInfoModal = async (
        targetInfo: TemplateInfoModalTargetInfoType,
        templateResultType: TemplateResultType,
        searchInput?: string,
        setSearchInput?: (value: string) => void,
    ) => {
        return await openModalWithReturnValue(
            `template-info-modal-${JSON.stringify(targetInfo)}`,
            "bottomSheet",
            (resolve) => {
                return (
                    <TemplateInfoModal
                        resolve={resolve}
                        targetInfo={targetInfo}
                        searchInput={searchInput}
                        setSearchInput={setSearchInput}
                        templateResultType={templateResultType}
                    />
                );
            },
        );
    };

    return { openTemplateInfoModal };
}
