import { ReactElement, useEffect, useRef, useState } from "react";
import styled, { StyledComponent } from "styled-components";
import { URL_CONSTANTS } from "../../../../constants/UrlConstants";
import Tooltip from "../tooltip/Tooltip";
import { useHover } from "usehooks-ts";
import { useOpenMiniProfileModal } from "../../../../hooks/modal/openModal/useOpenMiniProfileModal";
import { CommonTCEllipsis } from "../commonTable/components/CommonTableStyle";
import {
    hasPathAfterNestedResolve,
    PathAfterResolveType,
} from "../../../../hooks/modal/useOpenModal";

export const DefaultNicknameStyledDiv = styled.div`
    font-size: inherit;
    color: inherit;
    font-weight: inherit;
    text-decoration-line: inherit;
`;

export const NicknameWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    text-decoration-line: inherit;
`;
export const NicknameContainer = styled.div<{ isCursorPointer: boolean }>`
    padding-bottom: 1px;
    cursor: ${({ isCursorPointer }) =>
        isCursorPointer ? "pointer" : "default"};

    ${CommonTCEllipsis};
    text-decoration-line: inherit;
`;

export const NicknameVerifiedIconContainer = styled.div`
    position: relative;
`;
export const NicknameVerifiedIcon = styled.img``;

type Props = {
    userIdx: string;
    nickname: string;
    nicknameStyledComponent: StyledComponent<any, any>;
    isShowVerifiedIcon: boolean;
    isVerified?: boolean;
    tooltipPosition: string;
    isOpenMiniProfile: boolean;
    isOpenBlankCollection: boolean;
    prevModalResolve?: (value: boolean | PathAfterResolveType) => void;
    isForceNicknameCursorPointer: boolean;
};

const Nickname = (props: Props): ReactElement | null => {
    const nicknameContainerRef = useRef<HTMLDivElement>(null);
    const verifiedIconRef = useRef<HTMLImageElement>(null);
    const regex = /[^0-9]/g;
    const isHover = useHover(verifiedIconRef);
    const { openMiniProfileModal } = useOpenMiniProfileModal(
        typeof props.prevModalResolve !== "undefined",
        props.isOpenBlankCollection,
    );
    const [nicknameFontSize, setNicknameFontSize] = useState(
        nicknameContainerRef.current
            ? Number(
                  window
                      .getComputedStyle(nicknameContainerRef.current)
                      .getPropertyValue("font-size")
                      .replace(regex, ""),
              )
            : 0,
    );

    useEffect(() => {
        setNicknameFontSize(
            nicknameContainerRef.current
                ? Number(
                      window
                          .getComputedStyle(nicknameContainerRef.current)
                          .getPropertyValue("font-size")
                          .replace(regex, ""),
                  )
                : 0,
        );
    }, []);

    const getVerifiedIconSize = () => {
        if (nicknameFontSize >= 21) {
            return "20px";
        } else if (nicknameFontSize >= 17) {
            return "16px";
        } else {
            return "12px";
        }
    };

    const getVerifiedIconMargin = () => {
        if (nicknameFontSize >= 21) {
            return "5px";
        } else if (nicknameFontSize >= 17) {
            return "4px";
        } else {
            return "3px";
        }
    };

    return (
        <props.nicknameStyledComponent ref={nicknameContainerRef}>
            <NicknameWrapper>
                <NicknameContainer
                    isCursorPointer={
                        props.isForceNicknameCursorPointer ||
                        props.isOpenMiniProfile
                    }
                    onClick={async (e) => {
                        if (
                            typeof props.userIdx === "undefined" ||
                            props.userIdx === "0"
                        ) {
                            console.info("no-user-idx openMiniProfile");
                            return;
                        }
                        if (props.isOpenMiniProfile) {
                            e.preventDefault();
                            e.stopPropagation();
                            const miniProfileMoveResult =
                                await openMiniProfileModal(
                                    props.userIdx,
                                    typeof props.prevModalResolve !==
                                        "undefined",
                                );

                            if (
                                props.prevModalResolve &&
                                hasPathAfterNestedResolve(miniProfileMoveResult)
                            ) {
                                // 여기서 잘못보내고 있음
                                props.prevModalResolve({
                                    pathAfterResolve:
                                        miniProfileMoveResult.pathAfterNestedResolve,
                                });
                            }
                        }
                    }}
                >
                    {props.nickname}
                </NicknameContainer>
                <NicknameVerifiedIconContainer
                    style={{
                        marginLeft: getVerifiedIconMargin(),
                    }}
                >
                    <NicknameVerifiedIcon
                        ref={verifiedIconRef}
                        src={
                            URL_CONSTANTS.ASSET_URL + "/icon/icon-verified.svg"
                        }
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        style={{
                            width: getVerifiedIconSize(),
                            display:
                                props.isShowVerifiedIcon && props.isVerified
                                    ? "block"
                                    : "none",
                        }}
                    />
                    <Tooltip isHover={isHover} position={props.tooltipPosition}>
                        인증됨
                    </Tooltip>
                </NicknameVerifiedIconContainer>
            </NicknameWrapper>
        </props.nicknameStyledComponent>
    );
};

export default Nickname;

Nickname.defaultProps = {
    nicknameStyledComponent: DefaultNicknameStyledDiv,
    tooltipPosition: "top",
    isOpenMiniProfile: true,
    isOpenBlankCollection: false,
    isShowVerifiedIcon: true,
    isForceNicknameCursorPointer: false,
};
