import { ReactElement } from "react";
import styled from "styled-components";

export const ItemTransferModalItemInfoContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 12px;
`;

export const ItemTransferModalItemInfoThumbnail = styled.img`
    width: 60px;
    height: 60px;
    border-radius: 8px;
`;

export const ItemTransferModalItemInfoTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
`;

export const ItemTransferModalItemInfoTitle = styled.div`
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 16px */
`;

export const ItemTransferModalItemInfoProjectTitle = styled.div`
    color: #777;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 13px */
`;

type Props = {
    thumbnailPath: string;
    itemName: string;
    projectName: string;
};

const ItemTransferUseModalItemInfo = (props: Props): ReactElement | null => {
    return (
        <ItemTransferModalItemInfoContainer>
            <ItemTransferModalItemInfoThumbnail src={props.thumbnailPath} />
            <ItemTransferModalItemInfoTitleContainer>
                <ItemTransferModalItemInfoTitle>
                    {props.itemName}
                </ItemTransferModalItemInfoTitle>
                <ItemTransferModalItemInfoProjectTitle>
                    {props.projectName}
                </ItemTransferModalItemInfoProjectTitle>
            </ItemTransferModalItemInfoTitleContainer>
        </ItemTransferModalItemInfoContainer>
    );
};

export default ItemTransferUseModalItemInfo;
