import { ReactElement } from "react";
import styled from "styled-components";
import { MyAdOfferInfoOfferSpaceElementType } from "../../../../../../../../types/ad/adMy/myAd__sale/myAdOfferInfo/MyAdOfferInfoOfferSpaceElementType";
import { MyAdOfferInfoOfferSpaceType } from "../../../../../../../../types/ad/adMy/myAd__sale/myAdOfferInfo/MyAdOfferInfoOfferSpaceType";
import Scrollbar from "../../../../../../../common/commonUiComponent/scrollbar/Scrollbar";
import { SCROLL_BAR_NORMAL_TYPE } from "../../../../../../../common/commonUiComponent/scrollbar/ScrollbarType";

export const AdSaleManageOfferListModalPreviewCarouselItemWrapper = styled.div``;

export const AdSaleManageOfferListModalPreviewCarouselItemScrollWrapper = styled.div`
    width: 320px;
    height: 320px;
    position: relative;

    @media ${(props) => props.theme.mobileL} {
        width: 100%;
        height: 240px;
    }
`;

export const AdSaleManageOfferListModalPreviewCarouselItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    position: relative;
    padding: 10px 0;
    min-height: 300px;

    border-radius: 8px;
    background: #f5f5f7;

    @media ${(props) => props.theme.mobileL} {
        min-height: 220px;
    }
`;

export const AdSaleManageOfferListModalPreviewCarouselItemImg = styled.img`
    max-width: 288px;

    @media ${(props) => props.theme.mobileL} {
        max-height: 216px;
    }
`;

export const AdSaleManageOfferListModalPreviewCarouselItemTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
`;

export const AdSaleManageOfferListModalPreviewCarouselItemTextTitle = styled.div`
    color: #888;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */
`;

export const AdSaleManageOfferListModalPreviewCarouselItemTextValue = styled.div`
    color: #222;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
`;

type Props = {
    offerSpace: MyAdOfferInfoOfferSpaceType;
};

const AdSaleManageOfferListModalPreviewCarouselItem = (
    props: Props,
): ReactElement | null => {
    return (
        <AdSaleManageOfferListModalPreviewCarouselItemWrapper>
            <AdSaleManageOfferListModalPreviewCarouselItemScrollWrapper>
                <Scrollbar
                    type={SCROLL_BAR_NORMAL_TYPE}
                    isThin={false}
                    id={"ad-sale-manage-offer-list-modal-preview"}
                >
                    <AdSaleManageOfferListModalPreviewCarouselItemContainer>
                        {props.offerSpace.offer_space_element.map(
                            (
                                ele: MyAdOfferInfoOfferSpaceElementType,
                                eleIdx: number,
                            ) =>
                                ele.presv_campaign_space_element_type ===
                                "1" ? (
                                    <AdSaleManageOfferListModalPreviewCarouselItemImg
                                        key={eleIdx}
                                        src={
                                            ele.presv_template_space_element_img_path
                                        }
                                    />
                                ) : (
                                    <AdSaleManageOfferListModalPreviewCarouselItemTextContainer
                                        key={eleIdx}
                                    >
                                        <AdSaleManageOfferListModalPreviewCarouselItemTextTitle>
                                            {
                                                ele.presv_campaign_space_element_name
                                            }
                                        </AdSaleManageOfferListModalPreviewCarouselItemTextTitle>
                                        <AdSaleManageOfferListModalPreviewCarouselItemTextValue>
                                            {
                                                ele.presv_template_space_element_text
                                            }
                                        </AdSaleManageOfferListModalPreviewCarouselItemTextValue>
                                        <AdSaleManageOfferListModalPreviewCarouselItemTextTitle>
                                            {
                                                ele.presv_campaign_space_element_name
                                            }
                                        </AdSaleManageOfferListModalPreviewCarouselItemTextTitle>
                                        <AdSaleManageOfferListModalPreviewCarouselItemTextValue>
                                            {
                                                ele.presv_template_space_element_text
                                            }
                                        </AdSaleManageOfferListModalPreviewCarouselItemTextValue>
                                        <AdSaleManageOfferListModalPreviewCarouselItemTextTitle>
                                            {
                                                ele.presv_campaign_space_element_name
                                            }
                                        </AdSaleManageOfferListModalPreviewCarouselItemTextTitle>
                                        <AdSaleManageOfferListModalPreviewCarouselItemTextValue>
                                            {
                                                ele.presv_template_space_element_text
                                            }
                                        </AdSaleManageOfferListModalPreviewCarouselItemTextValue>
                                        <AdSaleManageOfferListModalPreviewCarouselItemTextTitle>
                                            {
                                                ele.presv_campaign_space_element_name
                                            }
                                        </AdSaleManageOfferListModalPreviewCarouselItemTextTitle>
                                        <AdSaleManageOfferListModalPreviewCarouselItemTextValue>
                                            {
                                                ele.presv_template_space_element_text
                                            }
                                        </AdSaleManageOfferListModalPreviewCarouselItemTextValue>
                                        <AdSaleManageOfferListModalPreviewCarouselItemTextTitle>
                                            {
                                                ele.presv_campaign_space_element_name
                                            }
                                        </AdSaleManageOfferListModalPreviewCarouselItemTextTitle>
                                        <AdSaleManageOfferListModalPreviewCarouselItemTextValue>
                                            {
                                                ele.presv_template_space_element_text
                                            }
                                        </AdSaleManageOfferListModalPreviewCarouselItemTextValue>
                                        <AdSaleManageOfferListModalPreviewCarouselItemTextTitle>
                                            {
                                                ele.presv_campaign_space_element_name
                                            }
                                        </AdSaleManageOfferListModalPreviewCarouselItemTextTitle>
                                        <AdSaleManageOfferListModalPreviewCarouselItemTextValue>
                                            {
                                                ele.presv_template_space_element_text
                                            }
                                        </AdSaleManageOfferListModalPreviewCarouselItemTextValue>
                                        <AdSaleManageOfferListModalPreviewCarouselItemTextTitle>
                                            {
                                                ele.presv_campaign_space_element_name
                                            }
                                        </AdSaleManageOfferListModalPreviewCarouselItemTextTitle>
                                        <AdSaleManageOfferListModalPreviewCarouselItemTextValue>
                                            {
                                                ele.presv_template_space_element_text
                                            }
                                        </AdSaleManageOfferListModalPreviewCarouselItemTextValue>
                                    </AdSaleManageOfferListModalPreviewCarouselItemTextContainer>
                                ),
                        )}
                    </AdSaleManageOfferListModalPreviewCarouselItemContainer>
                </Scrollbar>
            </AdSaleManageOfferListModalPreviewCarouselItemScrollWrapper>
        </AdSaleManageOfferListModalPreviewCarouselItemWrapper>
    );
};

export default AdSaleManageOfferListModalPreviewCarouselItem;
