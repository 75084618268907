import { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import {
    AdPublicTemplateListParamsType,
    spreadPublicTemplateListPages,
    useAdPublicTemplateListQuery,
} from "../../../../../../../../query/ad/publicTemplate/useAdPublicTemplateListQuery";
import AdBuyManagePublicTemplateListItem from "./AdBuyManagePublicTemplateListItem";
import MoreButton from "../../../../../../../common/commonUnitComponent/moreButton/MoreButton";
import { useSetStateOnWindowWidth } from "../../../../../../../../hooks/common/useSetStateOnWindowWidth";
import ApiResBoundary from "../../../../../../../common/apiResBoundary/ApiResBoundary";
import AdBidingSelectPublicTemplateNoDataList from "../../../../../../adBiding/components/AdBidingSelectTemplate/components/AdBidingSelectTemplateModal/components/adBidingSelectPublicTemplateHub/components/AdBidingSelectPublicTemplateNoDataList";

export const AdBuyManagePublicTemplateListContentsContainer = styled.div`
    gap: 32px;
    display: flex;
    flex-direction: column;
`;

export const AdBuyManagePublicTemplateListContainer = styled.div<{
    perRow: number;
    listGap: number;
}>`
    width: 100%;
    display: grid;
    grid-template-columns: ${({ perRow }) => `repeat(${perRow / 2}, 1fr)`};
    gap: ${({ listGap }) => listGap + "px"};

    overflow: visible;
`;

export const AdBuyManagePublicTemplateListSearchInfo = styled.div`
    width: 100%;
    color: #555;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
    display: flex;
    gap: 4px;
    justify-content: center;
    flex-wrap: wrap;
`;

export const AdBuyManagePublicTemplateListSearchInfoBold = styled.span`
    color: #fa54fa;
    font-weight: 500;
`;

export const AdBuyManagePublicTemplateListMoreBtnWrapper = styled.div`
    margin-top: 24px;
    width: 100%;
    display: flex;
    justify-content: center;

    @media ${(props) => props.theme.mobileL} {
        margin-top: 16px;
    }
`;

export const AdBuyManagePublicTemplateListMoreBtnContainer = styled.div`
    width: 311px;

    @media ${(props) => props.theme.mobileL} {
        width: 100%;
    }
`;

type Props = {
    debouncedSearchInput: string;
    searchInput: string;
    setSearchInput: (searchInput: string) => void;
    publicTemplateListQueryParams: AdPublicTemplateListParamsType;
    setResultListLength?: (value: number) => void;
};

const AdBuyManagePublicTemplateList = (props: Props): ReactElement | null => {
    const [perRow, setPerRow] = useState(0);
    const [listGap, setListGap] = useState(0);
    const { isOnMountedSetDone: isOnMountedSetDonePerRow } =
        useSetStateOnWindowWidth(setPerRow, [8, 8, 8, 8, 6, 4, 4]);
    useSetStateOnWindowWidth(setListGap, [24, 24, 24, 24, 24, 16, 16]);

    const adPublicTemplateListQueryParams: AdPublicTemplateListParamsType = {
        ...props.publicTemplateListQueryParams,
        limit: perRow,
        page_no: 1,
    };

    const {
        data: rawPublicTemplateList,
        isLoading: isPublicTemplateLoading,
        isSuccess: isPublicTemplateSuccess,
        error: isPublicTemplateError,
        fetchNextPage: fetchPublicTemplateListNextPage,
        hasNextPage: hasPublicTemplateListNextPage,
    } = useAdPublicTemplateListQuery(adPublicTemplateListQueryParams, {
        enabled:
            isOnMountedSetDonePerRow &&
            props.publicTemplateListQueryParams.campaign_idx !== "-1",
        cacheTime: 60000,
        staleTime: 500,
    });

    const publicTemplateList = spreadPublicTemplateListPages(
        rawPublicTemplateList.pages,
    );

    useEffect(() => {
        if (isPublicTemplateSuccess && props.setResultListLength) {
            props.setResultListLength(publicTemplateList.length);
        }
    }, [isPublicTemplateSuccess, rawPublicTemplateList]);

    return publicTemplateList.length === 0 &&
        typeof props.setResultListLength === "undefined" ? (
        <AdBidingSelectPublicTemplateNoDataList
            searchInput={""}
            msg={"등록된 공유 템플릿이 없습니다."}
        />
    ) : (
        <ApiResBoundary
            isLoading={isPublicTemplateLoading}
            isNoData={publicTemplateList.length === 0}
            noDataFallback={null}
            error={isPublicTemplateError}
        >
            <AdBuyManagePublicTemplateListContentsContainer>
                {props.debouncedSearchInput !== "" && (
                    <AdBuyManagePublicTemplateListSearchInfo>
                        <AdBuyManagePublicTemplateListSearchInfoBold>
                            {props.debouncedSearchInput}
                        </AdBuyManagePublicTemplateListSearchInfoBold>
                        (으)로 검색한{" "}
                        <b>
                            {typeof props.publicTemplateListQueryParams
                                .template_tag_name === "undefined"
                                ? "템플릿 이름"
                                : "태그"}
                        </b>{" "}
                        검색 결과
                    </AdBuyManagePublicTemplateListSearchInfo>
                )}
                <AdBuyManagePublicTemplateListContainer
                    perRow={perRow}
                    listGap={listGap}
                >
                    {publicTemplateList.map((publicTemplate) => {
                        return (
                            <AdBuyManagePublicTemplateListItem
                                key={publicTemplate.templateInfo.template_idx}
                                templateListItem={publicTemplate}
                                searchInput={props.searchInput}
                                setSearchInput={props.setSearchInput}
                                adPublicTemplateListQueryParams={
                                    adPublicTemplateListQueryParams
                                }
                            />
                        );
                    })}
                </AdBuyManagePublicTemplateListContainer>
                {hasPublicTemplateListNextPage && (
                    <AdBuyManagePublicTemplateListMoreBtnWrapper>
                        <AdBuyManagePublicTemplateListMoreBtnContainer>
                            <MoreButton
                                isShow={true}
                                isLoading={isPublicTemplateLoading}
                                isSupportFold={false}
                                onClick={async () => {
                                    await fetchPublicTemplateListNextPage();
                                }}
                            />
                        </AdBuyManagePublicTemplateListMoreBtnContainer>
                    </AdBuyManagePublicTemplateListMoreBtnWrapper>
                )}
            </AdBuyManagePublicTemplateListContentsContainer>
        </ApiResBoundary>
    );
};

export default AdBuyManagePublicTemplateList;
