import { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import TemplateInfoModalTemplateSpaceInfoHeader from "./components/TemplateInfoModalTemplateSpaceInfoHeader";
import { TemplateInfoType } from "../../../../../../../../types/ad/templateInfo/TemplateInfoType";
import { TemplateInfoSpaceType } from "../../../../../../../../types/ad/templateInfo/TemplateInfoSpaceType";
import TemplateInfoModalTemplateSpaceInfoContents from "./components/TemplateInfoModalTemplateSpaceInfoContents";

export const TemplateInfoModalTemplateSpaceInfoContainer = styled.div`
    display: flex;
    gap: 16px;
    padding: 0 24px;
    width: calc(100% - 48px);

    @media ${(props) => props.theme.mobileL} {
        flex-direction: column;
        position: relative;
        overflow: visible;

        padding: 0;
        width: 100%;
    }
`;

export type TemplateInfoSpaceTypeWithIsSelected = TemplateInfoSpaceType & {
    isSelected: boolean;
};

type Props = {
    templateInfo: TemplateInfoType;
};

const TemplateInfoModalTemplateSpaceInfo = (
    props: Props,
): ReactElement | null => {
    const [templateSpaceList, setTemplateSpaceList] = useState<
        TemplateInfoSpaceTypeWithIsSelected[]
    >([]);

    useEffect(() => {
        setTemplateSpaceList(
            props.templateInfo.template_space.map((space, spaceIdx) => {
                return {
                    ...space,
                    isSelected: spaceIdx === 0,
                };
            }),
        );
    }, [props.templateInfo]);

    const selectedTemplateSpace =
        templateSpaceList.find((space) => space.isSelected) ||
        templateSpaceList[0];

    return (
        <TemplateInfoModalTemplateSpaceInfoContainer>
            <TemplateInfoModalTemplateSpaceInfoHeader
                templateSpaceList={templateSpaceList}
                setTemplateSpaceList={setTemplateSpaceList}
            />
            {selectedTemplateSpace && (
                <TemplateInfoModalTemplateSpaceInfoContents
                    selectedTemplateSpace={selectedTemplateSpace}
                />
            )}
        </TemplateInfoModalTemplateSpaceInfoContainer>
    );
};

export default TemplateInfoModalTemplateSpaceInfo;
