import { format } from "date-fns";
import { ReactElement } from "react";
import styled from "styled-components";
import { TemplateInfoType } from "../../../../../../../types/ad/templateInfo/TemplateInfoType";
import { numberToStringWithComma } from "../../../../../../../utils/Utils";
import { useIsMe } from "../../../../../../../hooks/common/useIsMe";
import Nickname from "../../../../../commonUnitComponent/nickname/Nickname";
import { PathAfterResolveType } from "../../../../../../../hooks/modal/useOpenModal";

export const TemplateInfoModalTemplateInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    border-radius: 16px;
    background: #f5f5f7;
    padding: 20px;
    margin: 0 24px;

    @media ${(props) => props.theme.mobileL} {
        margin: 0 16px;
    }
`;

export const TemplateInfoModalTemplateInfoRow = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
`;

export const TemplateInfoModalTemplateInfoRowKey = styled.div`
    width: 100px;
    overflow: hidden;
    color: #666;
    text-overflow: ellipsis;
    text-align: left;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 15.6px */
    flex-shrink: 0;
    word-break: keep-all;
`;

export const TemplateInfoModalTemplateInfoRowValue = styled.div`
    color: #222;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 15.6px */

    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
    text-align: left;
`;

export const TemplateInfoModalTemplateInfoRowValueUnderLine = styled(
    TemplateInfoModalTemplateInfoRowValue,
)`
    text-decoration: underline;
    cursor: pointer;
`;

export const TemplateInfoModalTemplateInfoTagContainer = styled.div`
    display: flex;
    gap: 6px;
    flex-wrap: wrap;
`;

export const TemplateInfoModalTemplateInfoTag = styled.div<{
    isSelected: boolean;
}>`
    display: flex;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 16px;
    background: ${({ isSelected }) =>
        isSelected ? "#FA54FA" : "rgba(13, 13, 26, 0.05)"};
    color: ${({ isSelected }) => (isSelected ? "white" : "black")};
    font-size: 12px;
    line-height: 1;
    font-style: normal;
    font-weight: ${({ isSelected }) => (isSelected ? 700 : 400)};

    transition: all 300ms ease;
`;

type Props = {
    templateInfo: TemplateInfoType;
    searchInput?: string;
    resolve: (value: PathAfterResolveType | boolean) => void;
};

const TemplateInfoModalTemplateInfo = (props: Props): ReactElement | null => {
    const isMe = useIsMe();
    const isMyTemplate = isMe(props.templateInfo.user_idx || "");

    return (
        <TemplateInfoModalTemplateInfoContainer>
            {!isMyTemplate && (
                <TemplateInfoModalTemplateInfoRow>
                    <TemplateInfoModalTemplateInfoRowKey>
                        제작자
                    </TemplateInfoModalTemplateInfoRowKey>
                    <Nickname
                        nickname={props.templateInfo.user_nick}
                        userIdx={props.templateInfo.user_idx}
                        isVerified={props.templateInfo.user_influencer_bool}
                        nicknameStyledComponent={
                            TemplateInfoModalTemplateInfoRowValueUnderLine
                        }
                        prevModalResolve={props.resolve}
                        isOpenBlankCollection={true}
                    />
                </TemplateInfoModalTemplateInfoRow>
            )}
            <TemplateInfoModalTemplateInfoRow>
                <TemplateInfoModalTemplateInfoRowKey>
                    등록일시
                </TemplateInfoModalTemplateInfoRowKey>
                <TemplateInfoModalTemplateInfoRowValue>
                    {format(
                        new Date(props.templateInfo.created_at || new Date()),
                        "yyyy-MM-dd HH:mm:ss",
                    )}
                </TemplateInfoModalTemplateInfoRowValue>
            </TemplateInfoModalTemplateInfoRow>
            <TemplateInfoModalTemplateInfoRow>
                <TemplateInfoModalTemplateInfoRowKey>
                    총 낙찰횟수
                </TemplateInfoModalTemplateInfoRowKey>
                <TemplateInfoModalTemplateInfoRowValue>
                    {numberToStringWithComma(
                        props.templateInfo.template_success_count,
                    )}
                </TemplateInfoModalTemplateInfoRowValue>
            </TemplateInfoModalTemplateInfoRow>
            <TemplateInfoModalTemplateInfoRow>
                <TemplateInfoModalTemplateInfoRowKey>
                    연결주소
                </TemplateInfoModalTemplateInfoRowKey>
                <TemplateInfoModalTemplateInfoRowValue>
                    <TemplateInfoModalTemplateInfoRowValueUnderLine
                        onClick={() => {
                            window.open(props.templateInfo.endPoint, "_blank");
                        }}
                    >
                        {props.templateInfo.endPoint}
                    </TemplateInfoModalTemplateInfoRowValueUnderLine>
                </TemplateInfoModalTemplateInfoRowValue>
            </TemplateInfoModalTemplateInfoRow>
            {props.templateInfo.broad_live_redirect_bool && (
                <TemplateInfoModalTemplateInfoRow>
                    <TemplateInfoModalTemplateInfoRowKey>
                        방송중 연결주소
                    </TemplateInfoModalTemplateInfoRowKey>
                    <TemplateInfoModalTemplateInfoRowValue>
                        <TemplateInfoModalTemplateInfoRowValueUnderLine
                            onClick={() => {
                                window.open(
                                    `https://play.sooplive.co.kr/${props.templateInfo.broad_live_redirect_user_id}`,
                                    "_blank",
                                );
                            }}
                        >
                            {props.templateInfo.broad_live_redirect_user_id}
                        </TemplateInfoModalTemplateInfoRowValueUnderLine>
                    </TemplateInfoModalTemplateInfoRowValue>
                </TemplateInfoModalTemplateInfoRow>
            )}
            {props.templateInfo.template_tag.length !== 0 && (
                <TemplateInfoModalTemplateInfoRow>
                    <TemplateInfoModalTemplateInfoRowKey>
                        태그
                    </TemplateInfoModalTemplateInfoRowKey>
                    <TemplateInfoModalTemplateInfoTagContainer>
                        {props.templateInfo.template_tag.map((tag) => (
                            <TemplateInfoModalTemplateInfoTag
                                isSelected={
                                    typeof props.searchInput !== "undefined" &&
                                    tag.template_tag_name === props.searchInput
                                }
                                key={tag.template_tag_idx}
                            >
                                {tag.template_tag_name}
                            </TemplateInfoModalTemplateInfoTag>
                        ))}
                    </TemplateInfoModalTemplateInfoTagContainer>
                </TemplateInfoModalTemplateInfoRow>
            )}
        </TemplateInfoModalTemplateInfoContainer>
    );
};

export default TemplateInfoModalTemplateInfo;
