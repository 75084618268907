import { ReactElement, useState } from "react";
import styled from "styled-components";
import AdBuyManagePublicTemplateListHubHeader from "./components/AdBuyManagePublicTemplateListHeader";
import { SortingItemType } from "../../../../../../common/commonUiComponent/doubleSideBar/leftSideBar/LeftSideBar";
import {
    AdBuyManagePublicTemplateListSortingDayOffsetList,
    AdBuyManagePublicTemplateListSortingTypeList,
} from "./AdBuyManagePublicTemplateListHubConstants";
import { useDebounce } from "usehooks-ts";
import AdBuyManagePublicTemplateList from "./components/AdBuyManagePublicTemplateList";
import { getSelectedSortingList } from "../../../../../../../utils/Utils";
import AdBidingSelectPublicTemplateNoDataList from "../../../../../adBiding/components/AdBidingSelectTemplate/components/AdBidingSelectTemplateModal/components/adBidingSelectPublicTemplateHub/components/AdBidingSelectPublicTemplateNoDataList";

export const AdBuyManagePublicTemplateListHubWrapper = styled.div`
    margin-top: 80px;

    @media ${(props) => props.theme.mobileL} {
        margin-top: 52px;
    }
`;

export const AdBuyManagePublicTemplateListHubContainer = styled.div`
    margin-top: 24px;

    @media ${(props) => props.theme.mobileL} {
        margin-top: 16px;
    }
`;

export const AdBuyManagePublicTemplateListHubSearchResultContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 80px;
`;

type Props = {
    selectedCampaignIdx: string;
};

const AdBuyManagePublicTemplateLitHub = (props: Props): ReactElement | null => {
    const [searchInput, setSearchInput] = useState("");
    const [sortingDayOffsetList, setSortingDayOffsetList] = useState<
        SortingItemType[]
    >(AdBuyManagePublicTemplateListSortingDayOffsetList);
    const [sortingTypeList, setSortingTypeList] = useState<SortingItemType[]>(
        AdBuyManagePublicTemplateListSortingTypeList,
    );
    const debouncedSearchInput = useDebounce<string>(searchInput, 500);

    const adPublicTemplateListQueryParamsBase = {
        campaign_idx: props.selectedCampaignIdx,
        daysOffset: getSelectedSortingList(sortingDayOffsetList),
        sort_type: getSelectedSortingList(sortingTypeList),
        limit: 0,
        page_no: 1,
    };

    const [templateListLength, setTemplateListLength] = useState(0);
    const [templateNameResultListLength, setTemplateNameResultListLength] =
        useState(0);
    const [
        templateTagNameResultListLength,
        setTemplateTagNameResultListLength,
    ] = useState(0);

    return (
        <AdBuyManagePublicTemplateListHubWrapper>
            <AdBuyManagePublicTemplateListHubHeader
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                sortingDayOffsetList={sortingDayOffsetList}
                setSortingDayOffsetList={setSortingDayOffsetList}
                sortingTypeList={sortingTypeList}
                setSortingTypeList={setSortingTypeList}
            />
            <AdBuyManagePublicTemplateListHubContainer>
                {debouncedSearchInput === "" ? (
                    <AdBuyManagePublicTemplateList
                        debouncedSearchInput={debouncedSearchInput}
                        searchInput={searchInput}
                        setSearchInput={setSearchInput}
                        publicTemplateListQueryParams={
                            adPublicTemplateListQueryParamsBase
                        }
                    />
                ) : (
                    <AdBuyManagePublicTemplateListHubSearchResultContainer>
                        {debouncedSearchInput !== "" &&
                            templateNameResultListLength === 0 &&
                            templateTagNameResultListLength === 0 && (
                                <AdBidingSelectPublicTemplateNoDataList
                                    searchInput={debouncedSearchInput}
                                />
                            )}

                        <AdBuyManagePublicTemplateList
                            debouncedSearchInput={debouncedSearchInput}
                            searchInput={searchInput}
                            setSearchInput={setSearchInput}
                            publicTemplateListQueryParams={{
                                ...adPublicTemplateListQueryParamsBase,
                                template_name: debouncedSearchInput,
                            }}
                            setResultListLength={
                                setTemplateNameResultListLength
                            }
                        />
                        <AdBuyManagePublicTemplateList
                            debouncedSearchInput={debouncedSearchInput}
                            searchInput={searchInput}
                            setSearchInput={setSearchInput}
                            publicTemplateListQueryParams={{
                                ...adPublicTemplateListQueryParamsBase,
                                template_tag_name: debouncedSearchInput,
                            }}
                            setResultListLength={
                                setTemplateTagNameResultListLength
                            }
                        />
                    </AdBuyManagePublicTemplateListHubSearchResultContainer>
                )}
            </AdBuyManagePublicTemplateListHubContainer>
        </AdBuyManagePublicTemplateListHubWrapper>
    );
};

export default AdBuyManagePublicTemplateLitHub;
