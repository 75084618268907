import React, { ReactElement } from "react";
import styled, { css } from "styled-components";
import CommonTable, {
    TableHeaderDataType,
} from "../../../../../common/commonUnitComponent/commonTable/CommonTable";
import {
    AdHistoryTableBodyColumn,
    AdHistoryTableHeaderColumn,
    AdHistoryTableHeaderQuestionMark,
} from "../../../commonComponents/adManageCommonHistoryTable/AdManageCommonHistoryTableStyle";
import { CommonTC } from "../../../../../common/commonUnitComponent/commonTable/components/CommonTableStyle";
import AdManageCommonHistoryColumnDate from "../../../commonComponents/adManageCommonHistoryTable/components/AdManageCommonHistoryColumnDate";
import AdManageCommonHistoryColumnOfferStatus from "../../../commonComponents/adManageCommonHistoryTable/components/AdManageCommonHistoryColumnOfferStatus";
import AdManageCommonHistoryColumnName from "../../../commonComponents/adManageCommonHistoryTable/components/AdManageCommonHistoryColumnName";
import AdManageCommonHistoryColumnBrand from "../../../commonComponents/adManageCommonHistoryTable/components/AdManageCommonHistoryColumnBrand";
import AdManageCommonHistoryColumnPrice from "../../../commonComponents/adManageCommonHistoryTable/components/AdManageCommonHistoryColumnPrice";
import AdManageCommonHistoryColumnAdBtn from "../../../commonComponents/adManageCommonHistoryTable/components/AdManageCommonHistoryColumnAdBtn";
import { DateRange } from "react-day-picker";
import AdManageCommonHistoryColumnAdStatus from "../../../commonComponents/adManageCommonHistoryTable/components/AdManageCommonHistoryColumnAdStatus";
import {
    MyAdBuyOfferStatusInfoParams,
    MyAdBuyRoundStatusInfoParams,
    useOpenCommonStatusInfoModal,
} from "../../../../../../hooks/modal/openModal/useOpenCommonStatusInfoModal";
import { AD_BUY_HISTORY } from "../../../_adManageLayout/AdManageTableContentsLayout";
import {
    getAdV2OfferReceiptInfo,
    useOpenAdBidReceiptModal,
} from "../../../../../../hooks/modal/openModal/useOpenAdBidReceiptModal";
import { AllAdMyBuyOfferListItemType } from "../../../../../../types/ad/adMy/allAdMyBuyOffer/AllAdMyBuyOfferListItemType";
import {
    getIsTodaySale,
    staticConvertAdStatusKR,
} from "../../../../../../hooks/ad/staticConvertAdStatusKR";
import { convertSafariAdSalesDate } from "../../../../../../utils/Utils";
import { useOpenTemplateInfoModal } from "../../../../../../hooks/modal/openModal/useOpenTemplateInfoModal";
import { TEMPLATE_RESULT_TYPE_PRESV } from "../../../../../common/modal/templateInfoModal/TemplateInfoModal";

const DateColumnWidth = css`
    width: 80px;
`;

const StateColumnWidth = css`
    width: 108px;
`;

const TitleColumnWidth = css`
    width: 220px;
`;
const BrandColumnWidth = css`
    width: 120px;
`;

const PriceColumnWidth = css`
    width: 92px;
`;

const AdStartDateColumnWidth = css`
    width: 80px;
`;
const ButtonColumnWidth = css`
    width: 104px;
`;

export const AdBuyManageAllOfferListableDateColumnContainer = styled(CommonTC)<{
    textAlign: string;
}>`
    ${DateColumnWidth};
`;

export const AdBuyManageAllOfferListableStateColumnContainer = styled(
    CommonTC,
)<{
    textAlign: string;
}>`
    ${StateColumnWidth};
`;

export const AdBuyManageAllOfferListableTitleColumnContainer = styled(
    CommonTC,
)<{
    textAlign: string;
}>`
    ${TitleColumnWidth};
`;

export const AdBuyManageAllOfferListableBrandColumnContainer = styled(
    CommonTC,
)<{
    textAlign: string;
}>`
    ${BrandColumnWidth};
`;

export const AdBuyManageAllOfferListablePriceColumnContainer = styled(
    CommonTC,
)<{
    textAlign: string;
}>`
    ${PriceColumnWidth};
`;

export const AdBuyManageAllOfferListableAdStartDateColumnContainer = styled(
    CommonTC,
)<{
    textAlign: string;
}>`
    ${AdStartDateColumnWidth};
`;

export const AdBuyManageAllOfferListableButtonColumnContainer = styled(
    CommonTC,
)<{
    textAlign: string;
}>`
    ${ButtonColumnWidth};
`;

export const AdBuyManageAllOfferListableContainer = styled.div`
    margin-bottom: 136px;

    @media ${(props) => props.theme.mobileL} {
        margin-bottom: 36px;
    }
`;

export const AdBuyManageAllOfferListPCTableContainer = styled.div`
    @media ${(props) => props.theme.tabletM} {
        display: none;
    }
`;

export const AdBuyManageAllOfferListMobileTableContainer = styled.div`
    display: none;

    @media ${(props) => props.theme.tabletM} {
        display: block;
    }
`;

type Props = {
    allAdMyOfferList: AllAdMyBuyOfferListItemType[];
    selectedDateRange: DateRange;
};

const AdBuyManageAllOfferListPcTable = (props: Props): ReactElement | null => {
    const type = AD_BUY_HISTORY;
    const { openCommonStatusInfoModal } =
        useOpenCommonStatusInfoModal("my-ad-buy-offer");
    const { openAdBidReceiptModal } = useOpenAdBidReceiptModal();
    const { openTemplateInfoModal } = useOpenTemplateInfoModal();

    const tableHeaderData: TableHeaderDataType[] = [
        {
            key: "dateColumn",
            component: (
                <AdBuyManageAllOfferListableDateColumnContainer
                    textAlign={"center"}
                    key={"dateColumn"}
                >
                    <AdHistoryTableHeaderColumn>
                        입찰일시
                    </AdHistoryTableHeaderColumn>
                </AdBuyManageAllOfferListableDateColumnContainer>
            ),
        },
        {
            key: "offerStatusColumn",
            component: (
                <AdBuyManageAllOfferListableStateColumnContainer
                    textAlign={"center"}
                    key={"offerStatusColumn"}
                >
                    <AdHistoryTableHeaderColumn>
                        입찰 상태
                        <AdHistoryTableHeaderQuestionMark
                            onClick={async () => {
                                await openCommonStatusInfoModal(
                                    MyAdBuyOfferStatusInfoParams,
                                );
                            }}
                        >
                            ?
                        </AdHistoryTableHeaderQuestionMark>
                    </AdHistoryTableHeaderColumn>
                </AdBuyManageAllOfferListableStateColumnContainer>
            ),
        },
        {
            key: "adRoundStatusColumn",
            component: (
                <AdBuyManageAllOfferListableStateColumnContainer
                    textAlign={"center"}
                    key={"adRoundStatusColumn"}
                >
                    <AdHistoryTableHeaderColumn>
                        광고 상태
                        <AdHistoryTableHeaderQuestionMark
                            onClick={async () => {
                                await openCommonStatusInfoModal(
                                    MyAdBuyRoundStatusInfoParams,
                                );
                            }}
                        >
                            ?
                        </AdHistoryTableHeaderQuestionMark>
                    </AdHistoryTableHeaderColumn>
                </AdBuyManageAllOfferListableStateColumnContainer>
            ),
        },
        {
            key: "titleColumn",
            component: (
                <AdBuyManageAllOfferListableTitleColumnContainer
                    textAlign={"center"}
                    key={"titleColumn"}
                >
                    <AdHistoryTableHeaderColumn>
                        광고 상품명
                    </AdHistoryTableHeaderColumn>
                </AdBuyManageAllOfferListableTitleColumnContainer>
            ),
        },
        {
            key: "brandColumn",
            component: (
                <AdBuyManageAllOfferListableBrandColumnContainer
                    textAlign={"center"}
                    key={"brandColumn"}
                >
                    <AdHistoryTableHeaderColumn>
                        브랜드
                    </AdHistoryTableHeaderColumn>
                </AdBuyManageAllOfferListableBrandColumnContainer>
            ),
        },
        {
            key: "priceColumn",
            component: (
                <AdBuyManageAllOfferListablePriceColumnContainer
                    textAlign={"right"}
                    key={"priceColumn"}
                >
                    <AdHistoryTableHeaderColumn>
                        입찰가
                    </AdHistoryTableHeaderColumn>
                </AdBuyManageAllOfferListablePriceColumnContainer>
            ),
        },
        {
            key: "adStartDateColumn",
            component: (
                <AdBuyManageAllOfferListableAdStartDateColumnContainer
                    textAlign={"center"}
                    key={"adStartDateColumn"}
                >
                    <AdHistoryTableHeaderColumn>
                        광고적용일
                    </AdHistoryTableHeaderColumn>
                </AdBuyManageAllOfferListableAdStartDateColumnContainer>
            ),
        },
        {
            key: "buttonColumn",
            component: (
                <AdBuyManageAllOfferListableButtonColumnContainer
                    textAlign={"center"}
                    key={"buttonColumn"}
                />
            ),
        },
    ];

    const tableBodyData = props.allAdMyOfferList.map(
        (offer: AllAdMyBuyOfferListItemType) => {
            return {
                dateColumn: (
                    <AdBuyManageAllOfferListableDateColumnContainer
                        textAlign={"center"}
                        key={"dateColumn"}
                    >
                        <AdHistoryTableBodyColumn>
                            <AdManageCommonHistoryColumnDate
                                date={new Date(offer.offerInfo.created_at)}
                                format={"yyyy.MM.dd."}
                            />
                            <AdManageCommonHistoryColumnDate
                                date={new Date(offer.offerInfo.created_at)}
                                format={"HH:mm:ss"}
                            />
                        </AdHistoryTableBodyColumn>
                    </AdBuyManageAllOfferListableDateColumnContainer>
                ),
                offerStatusColumn: (
                    <AdBuyManageAllOfferListableStateColumnContainer
                        textAlign={"center"}
                        key={"stateColumn"}
                    >
                        <AdHistoryTableBodyColumn>
                            <AdManageCommonHistoryColumnOfferStatus
                                offerStatus={offer.offerInfo.offer_status}
                                offerInvalidStatus={
                                    offer.offerInfo.offer_invalid_status
                                }
                                onClickHandler={async () => {
                                    await openAdBidReceiptModal(
                                        getAdV2OfferReceiptInfo(offer),
                                    );
                                }}
                            />
                        </AdHistoryTableBodyColumn>
                    </AdBuyManageAllOfferListableStateColumnContainer>
                ),
                adRoundStatusColumn: (
                    <AdBuyManageAllOfferListableStateColumnContainer
                        textAlign={"center"}
                        key={"roundStatusColumn"}
                    >
                        <AdHistoryTableBodyColumn>
                            <AdManageCommonHistoryColumnAdStatus
                                adDailyStatusKR={staticConvertAdStatusKR(
                                    offer.advertisementDailyInfo
                                        .ad_daily_status_code,
                                    getIsTodaySale(
                                        offer.advertisementDailyInfo
                                            .ad_sales_daily_status,
                                    ),
                                    offer.advertisementInfo.ad_stop_switch_bool,
                                )}
                            />
                        </AdHistoryTableBodyColumn>
                    </AdBuyManageAllOfferListableStateColumnContainer>
                ),
                titleColumn: (
                    <AdBuyManageAllOfferListableTitleColumnContainer
                        textAlign={"left"}
                        key={"titleColumn"}
                    >
                        <AdHistoryTableBodyColumn>
                            <AdManageCommonHistoryColumnName
                                adProductName={
                                    offer.advertisementInfo.ad_product_name
                                }
                                adProductSubName={
                                    offer.advertisementInfo.ad_product_sub_name
                                }
                                adThumbnail={
                                    offer.advertisementInfo.ad_thumbnail_path
                                }
                            />
                        </AdHistoryTableBodyColumn>
                    </AdBuyManageAllOfferListableTitleColumnContainer>
                ),
                brandColumn: (
                    <AdBuyManageAllOfferListableBrandColumnContainer
                        textAlign={"center"}
                        key={"brandColumn"}
                    >
                        <AdHistoryTableBodyColumn>
                            <AdManageCommonHistoryColumnBrand
                                brandId={offer.advertisementInfo.brand_id}
                                brandName={offer.advertisementInfo.brand_name}
                            />
                        </AdHistoryTableBodyColumn>
                    </AdBuyManageAllOfferListableBrandColumnContainer>
                ),
                priceColumn: (
                    <AdBuyManageAllOfferListablePriceColumnContainer
                        textAlign={"right"}
                        key={"priceColumn"}
                    >
                        <AdHistoryTableBodyColumn>
                            <AdManageCommonHistoryColumnPrice
                                offerPrice={offer.offerInfo.offer_price}
                            />
                        </AdHistoryTableBodyColumn>
                    </AdBuyManageAllOfferListablePriceColumnContainer>
                ),
                adStartDateColumn: (
                    <AdBuyManageAllOfferListableAdStartDateColumnContainer
                        textAlign={"center"}
                        key={"adStartDateColumn"}
                    >
                        <AdHistoryTableBodyColumn>
                            <AdManageCommonHistoryColumnDate
                                date={
                                    new Date(
                                        convertSafariAdSalesDate(
                                            offer.advertisementDailyInfo
                                                .ad_sales_date,
                                        ),
                                    )
                                }
                                format={"yyyy.MM.dd"}
                            />
                        </AdHistoryTableBodyColumn>
                    </AdBuyManageAllOfferListableAdStartDateColumnContainer>
                ),
                buttonColumn: (
                    <AdBuyManageAllOfferListableButtonColumnContainer
                        textAlign={"center"}
                        key={"buttonColumn"}
                    >
                        <AdHistoryTableBodyColumn>
                            <AdManageCommonHistoryColumnAdBtn
                                type={type}
                                clickHandler={async () => {
                                    return await openTemplateInfoModal(
                                        {
                                            adIdx: offer.advertisementInfo
                                                .ad_idx,
                                            offerIdx: offer.offerInfo.offer_idx,
                                        },
                                        TEMPLATE_RESULT_TYPE_PRESV,
                                    );
                                }}
                            />
                        </AdHistoryTableBodyColumn>
                    </AdBuyManageAllOfferListableButtonColumnContainer>
                ),
            };
        },
    );

    return (
        <CommonTable
            id={type}
            tableHeaderData={tableHeaderData}
            tableBodyData={tableBodyData}
            tableBodyHeight={"100%"}
        />
    );
};

export default AdBuyManageAllOfferListPcTable;
