import React, { ReactElement } from "react";
import styled from "styled-components";
import { checkUserAgent } from "../../../../../../../../../utils/Utils";
import { URL_CONSTANTS } from "../../../../../../../../../constants/UrlConstants";
import Carousel from "../../../../../../../commonUnitComponent/carousel/Carousel";
import { CarouselIndicator } from "../../../../../../../commonUnitComponent/carousel/CarouselStyle";
import { ModalTitle } from "../../../../../../../modal/ModalStyle";
import ModalCloseIcon from "../../../../../../../modal/modalCloseIcon/ModalCloseIcon";

export const AppInstallGuideModalContainer = styled.div`
    width: 100%;
    max-width: 592px;
`;

export const AppInstallGuideImgContainer = styled.div`
    margin-top: 26px;

    @media ${(props) => props.theme.mobileL} {
        margin-bottom: 40px;
        overflow: visible;
    }
`;

export const AppInstallGuideMacImg = styled.img`
    width: 592px;
    height: 431px;

    @media ${(props) => props.theme.mobileL} {
        width: 100%;
        height: auto;
    }
`;
export const AppInstallGuideIOSImgContainer = styled.div`
    width: 100%;
`;

export const AppInstallGuideIOSImg = styled.img`
    width: 100%;
    max-height: 370px;
`;

export const GuideMsgWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 12px;

    @media ${(props) => props.theme.mobileL} {
        margin-top: 14px;
    }
`;

export const GuideMsgContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: left;
    gap: 10px;
    padding: 0 16px;
`;

export const GuideMsg = styled.span`
    color: #333;
    font-size: 16px;
    font-weight: 500;
    line-height: 100%;
    display: flex;
    align-items: center;
`;
export const GuideMsgNumber = styled.span`
    display: flex;
    width: 24px;
    height: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #fa54fa;

    color: #fff;
    text-align: center;
    font-size: 15px;
    font-weight: 900;
    line-height: 100%;

    margin-right: 6px;
`;
export const GuideMsgBold = styled.span`
    color: #fa54fa;
    font-size: 16px;
    font-weight: 700;
    line-height: 100%;
    margin: auto 3px;
`;

type Props = {
    resolve: (value: boolean) => void;
};

const AppInstallGuideModal = (props: Props): ReactElement | null => {
    const macGuideMsgList: string[] = [
        "브라우저 상단 *공유하기* 선택",
        "메뉴 중 *홈 화면에 추가* 선택",
        "오른쪽 상단 *추가* 선택",
    ];
    const iOSGuideMsgList: string[] = [
        "하단 *공유하기* 선택",
        "*홈 화면에 추가* 선택",
        "오른쪽 상단 *추가* 선택",
    ];
    const iOSGuideImgList: string[] = [
        "app-install-guide-ios-1.svg",
        "app-install-guide-ios-2.svg",
        "app-install-guide-ios-3.svg",
    ];
    return (
        <AppInstallGuideModalContainer>
            <ModalTitle>홈 화면에 앱 추가하기</ModalTitle>
            <ModalCloseIcon resolve={props.resolve} />
            <AppInstallGuideImgContainer>
                {checkUserAgent.isMac() && (
                    <AppInstallGuideMacImg
                        src={
                            URL_CONSTANTS.ASSET_URL +
                            "/guide/app-install-guide-mac.svg"
                        }
                    />
                )}
                {checkUserAgent.isIOS() && checkUserAgent.isTouchDevice() && (
                    <Carousel
                        dataList={iOSGuideImgList}
                        displayLength={1}
                        isShowPagination={true}
                        responsiveDisplayLength={[1, 1, 1, 1, 1, 1]}
                        isShowIndicator={true}
                        isInfinite={false}
                        IndicatorBottomPositionList={[
                            "-28px",
                            "-28px",
                            "-28px",
                            "-28px",
                            "-28px",
                            "-28px",
                        ]}
                        renderIndicator={(data) => {
                            return (
                                <CarouselIndicator
                                    key={data.idx}
                                    length={data.dataLength}
                                    currentIndex={data.currentIndex}
                                    indicatorIndex={data.indicatorIndex}
                                    color={"#000000"}
                                />
                            );
                        }}
                        renderComponent={(data) => {
                            return (
                                <AppInstallGuideIOSImgContainer key={data.idx}>
                                    <AppInstallGuideIOSImg
                                        src={`${URL_CONSTANTS.ASSET_URL}/guide/${data.listItem}`}
                                    />
                                    <GuideMsgWrapper>
                                        <GuideMsg>
                                            <GuideMsgNumber>
                                                {data.idx + 1}
                                            </GuideMsgNumber>
                                            {iOSGuideMsgList[data.idx]
                                                .split("*")
                                                .map((splitMsg, idx) => {
                                                    if (idx === 1) {
                                                        return (
                                                            <GuideMsgBold
                                                                key={idx}
                                                            >
                                                                {splitMsg}
                                                            </GuideMsgBold>
                                                        );
                                                    } else return splitMsg;
                                                })}
                                        </GuideMsg>
                                    </GuideMsgWrapper>
                                </AppInstallGuideIOSImgContainer>
                            );
                        }}
                        renderArrow={() => {
                            return <div></div>;
                        }}
                    />
                )}
            </AppInstallGuideImgContainer>
            <GuideMsgWrapper>
                <GuideMsgContainer>
                    {checkUserAgent.isMac() &&
                        macGuideMsgList.map((msg, idx) => {
                            return (
                                <GuideMsg key={idx}>
                                    <GuideMsgNumber>{idx + 1}</GuideMsgNumber>
                                    {msg
                                        .split("*")
                                        .map((splitMsg, innerIdx) => {
                                            if (innerIdx === 1) {
                                                return (
                                                    <GuideMsgBold
                                                        key={innerIdx}
                                                    >
                                                        {splitMsg}
                                                    </GuideMsgBold>
                                                );
                                            } else return splitMsg;
                                        })}
                                </GuideMsg>
                            );
                        })}
                </GuideMsgContainer>
            </GuideMsgWrapper>
        </AppInstallGuideModalContainer>
    );
};

export default AppInstallGuideModal;
