import { ReactElement, useEffect } from "react";
import styled from "styled-components";
import {
    CommonButtonStyle,
    CommonButtonType,
} from "../../commonStyle/CommonButtonStyle";
import { URL_CONSTANTS } from "../../../../constants/UrlConstants";
import hasHeaderAtom from "../../../../store/ui/hasHeaderAtom";
import { useSetAtom } from "jotai";
import isShowMobileBottomButtonAtom from "../../../../store/ui/isShowMobileBottomButtonAtom";

export const OverLoadComponentContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const OverLoadImg = styled.img`
    width: 200px;
    height: 200px;

    @media ${(props) => props.theme.mobileL} {
        width: 160px;
        height: 160px;
    }
`;

export const OverLoadTitle = styled.div`
    margin-top: 40px;
    color: #000;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 20px */

    @media ${(props) => props.theme.mobileL} {
        margin-top: 28px;
    }
`;

export const OverLoadDesc = styled.div`
    color: #666;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin: 14px 16px 0 16px;
`;

export const OverLoadBtnContainer = styled.div`
    margin-top: 28px;

    @media ${(props) => props.theme.mobileL} {
        margin-top: 24px;
    }
`;

export const OverLoadBtn = styled.div<CommonButtonType>`
    ${CommonButtonStyle};
    width: 162px;
`;

type Props = {};

const OverLoadComponent = (props: Props): ReactElement | null => {
    const setHasHeader = useSetAtom(hasHeaderAtom);
    const setIsShowMobileBottomButton = useSetAtom(
        isShowMobileBottomButtonAtom,
    );

    useEffect(() => {
        setHasHeader(false);
        setIsShowMobileBottomButton(false);
        return () => {
            setHasHeader(true);
            setIsShowMobileBottomButton(true);
        };
    }, []);

    return (
        <OverLoadComponentContainer>
            <OverLoadImg
                src={URL_CONSTANTS.ASSET_URL + "/icon/icon-surprised.svg"}
            />
            <OverLoadTitle>잠시 후 다시 접속해주세요</OverLoadTitle>
            <OverLoadDesc>
                현재 이용자가 과도한 관계로 원활한 서비스를 위해 일시적으로
                서비스를 이용하실 수 없습니다. <br /> 서비스 이용에 불편을
                드려서 죄송합니다.
            </OverLoadDesc>
            <OverLoadBtnContainer>
                <OverLoadBtn
                    btnType={"filled-primary"}
                    onClick={() => {
                        window.open(
                            "https://about.saybuzz.io/ko/busy",
                            "_blank",
                        );
                    }}
                >
                    공지사항 바로가기
                </OverLoadBtn>
            </OverLoadBtnContainer>
        </OverLoadComponentContainer>
    );
};

export default OverLoadComponent;
