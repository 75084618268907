import { ReactElement, useEffect, useRef, useState } from "react";
import ModalCloseIcon from "../../../../../../common/modal/modalCloseIcon/ModalCloseIcon";
import {
    ModalButton,
    ModalButtonContainer,
    ModalContainer,
    ModalTitle,
} from "../../../../../../common/modal/ModalStyle";
import CommonInputText, {
    firstEndBlankCheckValidation,
    InputTextDataType,
    specialCharCheckOnlyUnderBarHyphenValidation,
} from "../../../../../../common/commonUnitComponent/commonInputText/CommonInputText";
import {
    WalletCollectionGroupAddModalContainer,
    WalletCollectionGroupAddModalDesc,
    WalletCollectionGroupAddModalDescContainer,
    WalletCollectionGroupAddModalWrapper,
} from "./WalletCollectionGroupAddModal";
import { useAdjustTitleItemInventoryGroupMutation } from "../../../../../../../query/itemInventory/useAdjustTitleItemInventoryGroupMutation";
import { ItemInventoryGroupListItemType } from "../../../../../../../types/itemInventory/ItemInventoryGroupListItemType";

type Props = {
    groupInfo: ItemInventoryGroupListItemType;
    resolve: (value: boolean) => void;
};

const WalletCollectionGroupTitleAdjustModal = (
    props: Props,
): ReactElement | null => {
    const [inputText, setInputText] = useState<InputTextDataType>({
        value: props.groupInfo.item_inventory_group_name,
        isWarning: false,
        warningMsg: "",
    });
    const {
        mutateAsync: adjustTitleGroup,
        isLoading: isAdjustTitleGroupLoading,
    } = useAdjustTitleItemInventoryGroupMutation(
        props.groupInfo.item_inventory_group_idx,
        { item_inventory_group_name: inputText.value },
    );

    const descList = [
        "공백포함 최대 12자까지 작성가능",
        "‘-’, ‘_’ 외 특수문자 불가",
    ];
    const [isPending, setIsPending] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    return (
        <ModalContainer isNoPadding={false}>
            <WalletCollectionGroupAddModalWrapper>
                <ModalCloseIcon resolve={props.resolve} isMobileHide={false} />
                <ModalTitle>그룹 이름 변경</ModalTitle>
                <WalletCollectionGroupAddModalContainer>
                    <CommonInputText
                        ref={inputRef}
                        inputText={inputText}
                        setInputText={setInputText}
                        placeholder={"그룹 이름을 입력해주세요."}
                        minLength={0}
                        maxLength={12}
                        checkAdditionalValidation={() => {
                            return (
                                firstEndBlankCheckValidation(
                                    inputText,
                                    setInputText,
                                ) &&
                                specialCharCheckOnlyUnderBarHyphenValidation(
                                    inputText,
                                    setInputText,
                                )
                            );
                        }}
                    />
                    <WalletCollectionGroupAddModalDescContainer>
                        {descList.map((desc, idx) => {
                            return (
                                <WalletCollectionGroupAddModalDesc key={idx}>
                                    · {desc}
                                </WalletCollectionGroupAddModalDesc>
                            );
                        })}
                    </WalletCollectionGroupAddModalDescContainer>
                </WalletCollectionGroupAddModalContainer>
                <ModalButtonContainer>
                    <ModalButton
                        btnType={"filled-secondary"}
                        onClick={() => {
                            props.resolve(false);
                        }}
                    >
                        취소
                    </ModalButton>
                    <ModalButton
                        className={
                            inputText.isWarning
                                ? "filled-primary-disabled"
                                : !isPending && !isAdjustTitleGroupLoading
                                ? "filled-primary"
                                : "filled-primary-pending"
                        }
                        btnType={
                            inputText.isWarning
                                ? "filled-primary-disabled"
                                : !isPending && !isAdjustTitleGroupLoading
                                ? "filled-primary"
                                : "filled-primary-pending"
                        }
                        onClick={async () => {
                            if (
                                !isAdjustTitleGroupLoading &&
                                !isPending &&
                                !inputText.isWarning &&
                                inputText.value !== ""
                            ) {
                                try {
                                    setIsPending(true);
                                    await adjustTitleGroup();
                                    props.resolve(true);
                                } catch (e) {
                                    console.info(e);
                                    // 실패시에만 pending false // 열린 모달 안 닫기
                                    setIsPending(false);
                                }
                            }
                        }}
                    >
                        이름 변경
                    </ModalButton>
                </ModalButtonContainer>
            </WalletCollectionGroupAddModalWrapper>
        </ModalContainer>
    );
};

export default WalletCollectionGroupTitleAdjustModal;
