import { ReactElement } from "react";
import styled from "styled-components";

export const RadioButtonContainer = styled.div`
    display: flex;
    align-items: center;
`;

const Label = styled.label`
    display: inline-flex;
    align-items: center;
    cursor: pointer;
`;

export const RadioIconBackground = styled.div<{
    isDisabled: boolean;
    checked: boolean;
}>`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 17px;
    height: 17px;

    flex-shrink: 0;
    background: ${({ isDisabled }) => (isDisabled ? "#F5F5F5" : "#ffffff")};
    border: ${({ isDisabled }) => (isDisabled ? "#EBEBEB" : "#c8c8c8")} 1.5px
        solid;
    cursor: pointer;
    border-radius: 50%;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: #f0f0f2;
        }
    }

    transition: background-color 300ms ease;

    & > svg {
        position: absolute;
    }
`;

export const HiddenRadioButton = styled.input`
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

export const RadioIcon = styled.div<{
    selectedColor: string;
}>`
    width: 10px;
    height: 10px;
    background: ${({ selectedColor }) => selectedColor};
    border-radius: 50%;
`;

export const RadioButtonTitle = styled.div<{
    selectedColor: string;
    isSelected: boolean;
}>`
    margin-left: 10px;
    width: 100%;
    color: ${({ isSelected, selectedColor }) =>
        isSelected ? selectedColor : "#555555"};
    font-weight: ${({ isSelected }) => (isSelected ? "700" : "500")};
    font-size: 16px;
    line-height: 16px;
    transition: all 300ms ease;
`;

export type SelectType = {
    key: string;
    title: string;
    isSelected: boolean;
};

type Props = {
    id: string;
    isSelected: boolean;
    isDisabled: boolean;
    onChange: () => void;
    labelComponent: ReactElement | string;
    selectedColor: string;
};

const RadioButton = (props: Props): ReactElement | null => {
    return (
        <RadioButtonContainer>
            <Label htmlFor={props.id}>
                <HiddenRadioButton
                    id={props.id}
                    type="checkbox"
                    onChange={props.onChange}
                />

                <RadioIconBackground
                    checked={props.isSelected}
                    isDisabled={props.isDisabled}
                >
                    {props.isSelected && (
                        <RadioIcon selectedColor={props.selectedColor} />
                    )}
                </RadioIconBackground>

                {typeof props.labelComponent === "string" && (
                    <RadioButtonTitle
                        isSelected={props.isSelected}
                        selectedColor={props.selectedColor}
                    >
                        {props.labelComponent}
                    </RadioButtonTitle>
                )}
                {typeof props.labelComponent !== "string" &&
                    props.labelComponent}
            </Label>
        </RadioButtonContainer>
    );
};

export default RadioButton;

RadioButton.defaultProps = {
    selectedColor: "#fa54fa",
    isDisabled: false,
};
