import CssFilterConverter from "css-filter-converter";
import { ReactElement, useRef, useState } from "react";
import styled from "styled-components";
import { useOnClickOutside } from "usehooks-ts";
import { URL_CONSTANTS } from "../../../../../constants/UrlConstants";
import {
    getUserSearchQuery,
    spreadListUserSearchResult,
} from "../../../../../query/search/getUserSearchQuery";

export const ItemTransferTargetInputWrapper = styled.div`
    width: 100%;
    position: relative;
`;

export const ItemTransferTargetInputTitle = styled.div`
    color: #222;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 15px */
`;

export const ItemTransferTargetInputContainer = styled.div`
    position: relative;
    padding: 12px 10px;
    width: calc(100% - 22px);
    margin-top: 10px;
    border-radius: 8px;
    background: #f5f5f7;
    display: flex;
    border: 1px solid transparent;
    align-items: center;
    overflow: visible;
`;

export const ItemTransferTargetInputSelectedUserInfoContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const ItemTransferTargetInputSelectedUserInfoNickname = styled.div`
    color: #000;
    font-size: 16px;
    font-style: normal;
    overflow: visible;
    font-weight: 400;
    line-height: 100%;
`;
export const ItemTransferTargetInputSelectedUserInfoUserId = styled.div`
    color: #999;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    margin-left: 2px;
`;
export const ItemTransferTargetInputTag = styled.input`
    background: transparent;
    color: #000;
    width: 100%;
    font-family: Noto Sans KR, serif;
    font-size: 16px;
    height: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    padding: 0;
    border: none;
    overflow: visible;
    padding-bottom: 2px;

    &:focus,
    &:active {
        outline: none;
        border: none;
    }

    // border: 1px solid #fa54fa;

    ::placeholder {
        color: rgba(0, 0, 0, 0.4);
        font-family: Noto Sans KR, serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
    }
`;

export const ItemTransferTargetInputSelectedIcon = styled.img`
    width: 14px;
    height: 11px;
    filter: ${CssFilterConverter.hexToFilter("#FA54FA").color};
    margin-left: 4px;
`;

export const ItemTransferTargetInputNicknameListWrapper = styled.div<{
    isShow: boolean;
}>`
    position: absolute;
    bottom: 0;
    transform: translate(0, 100%);
    width: 100%;
    z-index: 3;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0 1px 3px 0 rgba(36, 36, 36, 0.12),
        0 8px 24px 0 rgba(83, 83, 83, 0.12);
    max-height: ${({ isShow }) => (isShow ? "220px" : 0)};
    transition: all 300ms ease;
    overflow: hidden;
`;

export const ItemTransferTargetInputNicknameListContainer = styled.div<{
    listLength: number;
}>`
    padding: ${({ listLength }) => (listLength === 0 ? 0 : "10px")};
    display: flex;
    flex-direction: column;
`;

export const ItemTransferTargetInputNicknameListItem = styled.div`
    display: flex;
    padding: 12px 6px;
    align-items: center;
    gap: 3px;
    border-radius: 6px;
    cursor: pointer;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: #f0f0f2;
        }
    }

    transition: all 300ms ease;
`;

export const ItemTransferTargetInputNicknameListItemNickname = styled.div`
    color: #222;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
`;

export const ItemTransferTargetInputNicknameListItemUserId = styled.div`
    color: #999;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
`;

export type SelectedUserInfoType = {
    user_id: string;
    user_idx: string;
    user_nick: string;
};

type Props = {
    selectedUserInfo: SelectedUserInfoType;
    setSelectedUserInfo: (selectedUserInfo: SelectedUserInfoType) => void;
};

const ItemTransferTargetInput = (props: Props): ReactElement | null => {
    const nicknameInputContainerRef = useRef<HTMLDivElement>(null);
    const nicknameInputRef = useRef<HTMLInputElement>(null);
    const [isOpenNicknameList, setIsOpenNicknameList] = useState(false);
    const [inputNickname, setInputNickname] = useState("");

    useOnClickOutside(nicknameInputContainerRef, () => {
        setIsOpenNicknameList(false);
    });

    const { data: rawUserSearchResult, refetch: refetchUserSearchResult } =
        getUserSearchQuery(
            {
                keyword: inputNickname,
                limit: 5,
                page_no: 1,
            },
            {
                enabled: inputNickname !== "",
                cacheTime: 0,
                staleTime: 0,
            },
        );

    const memoizedUserSearchResult = spreadListUserSearchResult(
        rawUserSearchResult.pages,
    );

    function initSelectedUserInfo() {
        if (props.selectedUserInfo.user_idx !== "-1") {
            props.setSelectedUserInfo({
                user_id: "",
                user_idx: "-1",
                user_nick: "",
            });
        }
    }

    return (
        <ItemTransferTargetInputWrapper ref={nicknameInputContainerRef}>
            <ItemTransferTargetInputTitle>
                전송 대상자
            </ItemTransferTargetInputTitle>
            <ItemTransferTargetInputContainer>
                {props.selectedUserInfo.user_id !== "" && (
                    <ItemTransferTargetInputSelectedUserInfoContainer
                        onClick={() => {
                            initSelectedUserInfo();
                            // 너무빨리 focusing 되서 focus 가 안걸림...
                            setTimeout(() => {
                                nicknameInputRef.current?.focus();
                            }, 200);
                        }}
                    >
                        <ItemTransferTargetInputSelectedUserInfoNickname>
                            {props.selectedUserInfo.user_nick}
                        </ItemTransferTargetInputSelectedUserInfoNickname>
                        <ItemTransferTargetInputSelectedUserInfoUserId>
                            ({props.selectedUserInfo.user_id})
                        </ItemTransferTargetInputSelectedUserInfoUserId>
                        <ItemTransferTargetInputSelectedIcon
                            src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-checked-small.svg`}
                        />
                    </ItemTransferTargetInputSelectedUserInfoContainer>
                )}
                {props.selectedUserInfo.user_id === "" && (
                    <ItemTransferTargetInputTag
                        ref={nicknameInputRef}
                        placeholder={"닉네임 및 아이디를 입력해주세요."}
                        value={inputNickname}
                        onChange={(e) => {
                            initSelectedUserInfo();
                            setInputNickname(e.target.value);
                        }}
                        onFocus={() => {
                            setIsOpenNicknameList(true);
                        }}
                    />
                )}
            </ItemTransferTargetInputContainer>
            <ItemTransferTargetInputNicknameListWrapper
                isShow={isOpenNicknameList}
            >
                <ItemTransferTargetInputNicknameListContainer
                    listLength={memoizedUserSearchResult.length}
                >
                    {memoizedUserSearchResult.map((searchUserInfo, idx) => {
                        return (
                            <ItemTransferTargetInputNicknameListItem
                                key={idx}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setInputNickname(searchUserInfo.user_nick);
                                    props.setSelectedUserInfo(searchUserInfo);
                                    setIsOpenNicknameList(false);
                                }}
                            >
                                <ItemTransferTargetInputNicknameListItemNickname>
                                    {searchUserInfo.user_nick}
                                </ItemTransferTargetInputNicknameListItemNickname>
                                <ItemTransferTargetInputNicknameListItemUserId>
                                    ({searchUserInfo.user_id})
                                </ItemTransferTargetInputNicknameListItemUserId>
                            </ItemTransferTargetInputNicknameListItem>
                        );
                    })}
                </ItemTransferTargetInputNicknameListContainer>
            </ItemTransferTargetInputNicknameListWrapper>
        </ItemTransferTargetInputWrapper>
    );
};

export default ItemTransferTargetInput;
