import { ReactElement } from "react";
import styled from "styled-components";
import { CampaignSimpleListItemType } from "../../../../../../../../types/ad/camapaignInfo/CampaignSimpleListItemType";
import { URL_CONSTANTS } from "../../../../../../../../constants/UrlConstants";

export const AdBuyManagePublicTemplatesCampaignSelectorCampaignListItemContainer = styled.div<{
    isSelected: boolean;
}>`
    cursor: pointer;
    display: flex;
    padding: 10px;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
    border-radius: 24px;
    transition: all 300ms ease;
    box-sizing: content-box;

    border: ${({ isSelected }) =>
        isSelected
            ? "2px solid rgba(250, 84, 250, 0.60)"
            : "2px solid #ebebeb"};
    background: ${({ isSelected }) =>
        isSelected
            ? "radial-gradient(61.31% 85.42% at 50% 100%, rgba(250, 84, 250, 0.06) 0%, rgba(250, 84, 250, 0.00) 100%), #FFF"
            : "#fff"};

    margin: 0 12px;

    @media ${(props) => props.theme.tabletL} {
        border-radius: 20px;
    }

    @media ${(props) => props.theme.mobileL} {
        margin: 0 0 0 12px;
        border: ${({ isSelected }) =>
            isSelected
                ? "2px solid rgba(250, 84, 250, 0.60)"
                : "1px solid #ebebeb"};
    }
`;

export const AdBuyManagePublicTemplatesCampaignSelectorCampaignListItemCampaignThumbnail = styled.img`
    width: 100px;
    border-radius: 16px;
    overflow: hidden;
    flex-shrink: 0;

    @media ${(props) => props.theme.tabletL} {
        width: 80px;
        border-radius: 12px;
    }

    @media ${(props) => props.theme.mobileL} {
        width: 60px;
        border-radius: 8px;
    }
`;

export const AdBuyManagePublicTemplatesCampaignSelectorCampaignListItemCampaignInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
`;

export const AdBuyManagePublicTemplatesCampaignSelectorCampaignListItemCampaignInfoLogo = styled.img`
    height: 16px;

    @media ${(props) => props.theme.tabletL} {
        height: 14px;
    }

    @media ${(props) => props.theme.mobileL} {
        height: 10px;
    }
`;

export const AdBuyManagePublicTemplatesCampaignSelectorCampaignListItemCampaignInfoTitle = styled.div<{
    isSelected: boolean;
}>`
    color: ${({ isSelected }) => (isSelected ? "#FA54FA" : "#333")};
    font-size: 18px;
    font-style: normal;
    font-weight: ${({ isSelected }) => (isSelected ? 700 : 400)};
    line-height: 140%; /* 25.2px */

    @media ${(props) => props.theme.tabletL} {
        font-size: 16px;
    }

    @media ${(props) => props.theme.mobileL} {
        font-size: 14px;
    }
`;

type Props = {
    campaignInfo: CampaignSimpleListItemType;
    selectedCampaignIdx: string;
    setSelectedCampaignIdx: (value: string) => void;
};

const AdBuyManagePublicTemplatesCampaignSelectorCampaignListItem = (
    props: Props,
): ReactElement | null => {
    const isSelected =
        props.selectedCampaignIdx === props.campaignInfo.campaign_idx;
    return (
        <AdBuyManagePublicTemplatesCampaignSelectorCampaignListItemContainer
            isSelected={isSelected}
            onClick={() => {
                props.setSelectedCampaignIdx(props.campaignInfo.campaign_idx);
            }}
        >
            <AdBuyManagePublicTemplatesCampaignSelectorCampaignListItemCampaignThumbnail
                src={props.campaignInfo.campaign_thumbnail_path}
            />

            <AdBuyManagePublicTemplatesCampaignSelectorCampaignListItemCampaignInfoContainer>
                <AdBuyManagePublicTemplatesCampaignSelectorCampaignListItemCampaignInfoLogo
                    src={`${URL_CONSTANTS.ASSET_URL}/logo/logo-${props.campaignInfo.brand_id}.svg`}
                />

                <AdBuyManagePublicTemplatesCampaignSelectorCampaignListItemCampaignInfoTitle
                    isSelected={isSelected}
                >
                    {props.campaignInfo.campaign_name
                        .replace("SOOP ", "")
                        .replace("세이버즈 ", "")}
                </AdBuyManagePublicTemplatesCampaignSelectorCampaignListItemCampaignInfoTitle>
            </AdBuyManagePublicTemplatesCampaignSelectorCampaignListItemCampaignInfoContainer>
        </AdBuyManagePublicTemplatesCampaignSelectorCampaignListItemContainer>
    );
};

export default AdBuyManagePublicTemplatesCampaignSelectorCampaignListItem;
