import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import {
    CommonButtonStyle,
    CommonButtonType,
} from "../commonStyle/CommonButtonStyle";

export const FadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

export const FadeOut = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;

export const ModalWrapper = styled.div<{
    isOpen: boolean;
    vh: number;
}>`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: ${({ vh }) => 100 * vh + "px"};
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.2);
    overflow: hidden;

    ${(props) => props.theme.zIndexModalOrLayer};
    visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
    animation: ${({ isOpen }) => (isOpen ? FadeIn : FadeOut)} 0.3s ease-out;
    transition: visibility 0.3s ease-out;
`;

export const ModalContainer = styled.div<{ isNoPadding: boolean }>`
    position: relative;
    background: #ffffff;
    box-shadow: 0 8px 24px rgba(83, 83, 83, 0.12),
        0 1px 3px rgba(36, 36, 36, 0.12);
    border-radius: 16px;
    width: ${({ isNoPadding }) => (isNoPadding ? "354px" : "306px")};
    padding: ${({ isNoPadding }) => (isNoPadding ? 0 : "24px")};

    text-align: center;
`;

export const ModalTitle = styled.div<{
    mt?: string;
}>`
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    margin-top: ${({ mt }) => (mt ? mt : "4px")};
    color: #000;
`;

export const ModalSmallTitle = styled.div`
    color: #333;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
`;

export const ModalLargeDesc = styled.div`
    margin-top: 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    color: #333333;
`;

export const ModalDesc = styled.div<{
    mt?: string;
}>`
    margin-top: ${({ mt }) => (mt ? mt : "16px")};
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #555555;
`;

export const ModalDescOfDesc = styled.div<{
    mt?: string;
}>`
    margin-top: ${({ mt }) => (mt ? mt : "10px")};
    color: #888;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 18.2px */
`;

export const ModalSmallDesc = styled.div`
    margin-top: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    text-align: center;
    color: #777777;
`;

export const ModalDescWarning = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    color: #888888;
`;

export const ModalButtonFixedSpace = styled.div`
    display: none;
    @media ${(props) => props.theme.mobileL} {
        display: block;
        width: 100%;
        height: 98px;
    }
`;

export const ModalButtonFixedContainer = styled.div`
    margin-top: 24px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 10px;

    @media ${(props) => props.theme.mobileL} {
        margin-top: 0;
        position: fixed;
        bottom: 0;
        left: 0;
        width: calc(100% - 50px);
        padding: 24px;
        background: white;
        border: 1px solid #ebebeb;
    }
`;

export const ModalButtonContainer = styled.div<{
    mt?: string;
}>`
    margin-top: ${({ mt }) => (mt ? mt : "24px")};
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 10px;
`;

export const ModalButtonLink = styled(Link)`
    display: block;
    width: 100%;
`;

export const ModalButton = styled.div<CommonButtonType>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    height: 48px;
    gap: 10px;
    border-radius: 8px;

    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    width: 100%;
    cursor: pointer;

    ${CommonButtonStyle};
`;

export const ModalInfoContainer = styled.div<{ mt: string; width: string }>`
    margin-top: ${({ mt }) => mt};
    padding: 16px;

    width: ${({ width }) => width};
    background: #f5f5f7;
    border-radius: 10px;

    @media ${(props) => props.theme.mobileL} {
        width: calc(100% - 32px);
    }
`;
