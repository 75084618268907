import { ReactElement } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../../../../../../../constants/UrlConstants";
import { DefaultNoDataComponentImg } from "../../../../../../../../../common/apiResBoundary/DefaultNoDataComponent";

export const AdBidingSelectPublicTemplateNoDataListContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: inherit;
    height: inherit;
    min-height: inherit;
    flex-direction: column;
    padding: 160px 0;
    gap: 24px;
`;

export const AdBidingSelectPublicTemplateNoDataListMsg = styled.div`
    color: #555;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 16px */

    & b {
        color: #fa54fa;
    }
`;

type Props = {
    searchInput: string;
    msg?: string;
};

const AdBidingSelectPublicTemplateNoDataList = (
    props: Props,
): ReactElement | null => {
    return (
        <AdBidingSelectPublicTemplateNoDataListContainer>
            <DefaultNoDataComponentImg
                src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-default-empty.svg`}
            />
            <AdBidingSelectPublicTemplateNoDataListMsg>
                {props.msg ? (
                    props.msg
                ) : (
                    <>
                        <b>{props.searchInput}</b>에 대한 검색결과가 없습니다.
                    </>
                )}
            </AdBidingSelectPublicTemplateNoDataListMsg>
        </AdBidingSelectPublicTemplateNoDataListContainer>
    );
};

export default AdBidingSelectPublicTemplateNoDataList;
