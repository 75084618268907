import { useAtom } from "jotai";
import { useMutation } from "react-query";
import { getApiErrorMessage } from "../../../apis/ErrorHandler/getApiErrorMessage";
import { useOpenCommonErrorModal } from "../../../hooks/modal/openModal/useOpenCommonErrorModal";
import { URL_CONSTANTS } from "../../../constants/UrlConstants";
import { asyncPushSnackBarListItemAtom } from "../../../store/ui/snackBarListAtom";
import { AdMyTemplateApi } from "../../../apis/adApi/AdMyTemplateApi";

export type AdMyTemplateOrderChangeParamsType = {
    campaign_idx: string;
    template_order: AdMyTemplateOrderType[];
};

export type AdMyTemplateOrderType = {
    template_idx: string;
    order_no: number;
};

export const useAdMyTemplateOrderChangeMutation = (
    params: AdMyTemplateOrderChangeParamsType,
) => {
    const { openCommonErrorModal } = useOpenCommonErrorModal();
    const [, pushSnackBarListItem] = useAtom(asyncPushSnackBarListItemAtom);

    return useMutation<any, Error>(
        () => AdMyTemplateApi.changeMyTemplateOrder(params),
        {
            onError: async (error) => {
                const errorResult = getApiErrorMessage(error);
                await openCommonErrorModal(errorResult);
            },
            onSuccess: async () => {
                pushSnackBarListItem({
                    title: "",
                    desc: "정상적으로 순서 변경이 완료되었습니다.",
                    iconUrl:
                        URL_CONSTANTS.ASSET_URL +
                        "/icon/icon-checked-in-circle.svg",
                    confirmBtnTitle: "",
                });
            },
        },
    );
};
