import { ReactElement } from "react";
import styled from "styled-components";
import OverLoadComponent from "../components/common/commonUiComponent/overLoadComponent/OverLoadComponent";

export const OverLoadPageContainer = styled.div``;

type Props = {};

const OverLoadPage = (props: Props): ReactElement | null => {
    return (
        <OverLoadPageContainer>
            <OverLoadComponent />
        </OverLoadPageContainer>
    );
};

export default OverLoadPage;
