import { ReactElement } from "react";
import styled from "styled-components";
import { CommonButtonType } from "../../../../../../../common/commonStyle/CommonButtonStyle";
import { TemplateListItemType } from "../../../../../../../../types/ad/templateInfo/TemplateListItemType";
import ChipsNoScroll from "../../../../../../../common/commonUnitComponent/chips/ChipsNoScroll";
import { TemplateInfoType } from "../../../../../../../../types/ad/templateInfo/TemplateInfoType";
import Nickname from "../../../../../../../common/commonUnitComponent/nickname/Nickname";
import {
    numberToStringWithComma,
    splitByTargetWord,
} from "../../../../../../../../utils/Utils";
import { TemplateInfoModalTemplateInfoTag } from "../../../../../../../common/modal/templateInfoModal/components/templateInfoModalContents/components/TemplateInfoModalTemplateInfo";
import { PathAfterResolveType } from "../../../../../../../../hooks/modal/useOpenModal";

export const AdBidingSelectTemplateListItemWrapper = styled.div<{
    isPublicTemplateTab: boolean;
}>`
    box-sizing: border-box;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    border: 1px solid #ebebeb;
    background: #fff;

    cursor: pointer;
`;

export const AdBidingSelectTemplateListItemThumbnailContainer = styled.div`
    position: relative;
    width: 100%;
    height: 200px;
    background: #f5f5f7;
    border-radius: 11px 11px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;

    @media ${(props) => props.theme.mobileL} {
        height: 163.5px;
    }
`;

export const AdBidingSelectTemplateListItemThumbnail = styled.img`
    width: auto;
    height: auto;
    max-width: 95%;
    max-height: 95%;
    object-fit: fill;
`;

export const AdBidingSelectTemplateListItemStatusBadge = styled.div<{
    status: string;
}>`
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 2;
    background: ${({ status }) => {
        switch (status) {
            case "1":
                return "#B4B4B4";
            case "2":
                return "#0cd28b";
            case "3":
                return "#FF4671";
        }
    }};
    color: #ffffff;
    border-radius: 16px;
    padding: 5px 10px;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
`;

export const AdBidingSelectTemplateListItemInfoWrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: calc(100% - 200px);
    padding: 16px;

    @media ${(props) => props.theme.mobileL} {
        height: calc(100% - 163.5px);
        padding: 12px 8px;
    }
`;

export const AdBidingSelectTemplateListItemInfoContainer = styled.div``;

export const AdBidingSelectTemplateListItemInfoTitle = styled.div`
    font-weight: 700;
    font-size: 18px;
    line-height: 100%;
    color: #000000;

    @media ${(props) => props.theme.mobileL} {
        font-size: 15px;
    }
`;

export const AdBidingSelectTemplateListItemInfoTitleBoldSpan = styled.span`
    color: #fa54fa;
`;

export const AdBidingSelectTemplateListItemInfoDescContainer = styled.div`
    margin-top: 13px;
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const AdBidingSelectTemplateListItemInfoDesc = styled.div`
    display: flex;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.4;
    color: #999999;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
`;

export const AdBidingSelectTemplateListItemInfoDescUnderLineSpan = styled.span`
    text-decoration: underline;
    width: auto;
`;

export const AdBidingSelectTemplateListItemInfoSelectButton = styled.div<CommonButtonType>`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 8px;
    background: #fbf2fc;
    font-size: 15px;
    margin-top: 20px;
    height: 40px;
    color: #fa54fa;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 15px */
`;

export const AdBidingSelectTemplateModalBodyListItemTimeSaleAlertModalBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const AdBidingSelectTemplateModalBodyListItemTimeSaleAlertModalBodyBold = styled.div`
    color: #000;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const AdBidingSelectPublicTemplateListItemInfoTagContainer = styled.div`
    display: flex;
    gap: 4px;
    margin-top: 14px;
`;

export const AdBidingSelectPublicTemplateListItemInfoTag = styled(
    TemplateInfoModalTemplateInfoTag,
)<{
    isSelected: boolean;
    isPublicTemplate: boolean;
}>`
    @media (hover: hover) and (pointer: fine) {
        ${({ isSelected, isPublicTemplate }) => {
            if (!isSelected)
                return `&:hover {
                        background: ${
                            isPublicTemplate
                                ? "#E2E2E3"
                                : "rgba(13, 13, 26, 0.05)"
                        };
                    }`;
        }}
    }
`;

type Props = {
    resolve: (value: PathAfterResolveType | boolean) => void;
    isPublicTemplate: boolean;
    templateListItem: TemplateListItemType;
    setSelectedPreviewTemplateIdx: (value: string) => void;
    // public template 한정
    searchInput?: string;
    setSearchInput?: (value: string) => void;
    selectedDayOffset?: string;
    selectedSortingType?: string;
    // public template 한정 + 인기순 + 체크 true 한정
    hasAdIdxQuery?: boolean;
    // my template 한정
    selectTemplate?: (templateInfo: TemplateInfoType) => void;
};

const AdBidingSelectTemplateListItem = (props: Props): ReactElement | null => {
    const templateInfo = props.templateListItem.templateInfo;
    const spaceList = templateInfo.template_space
        .map((spaceList) =>
            spaceList.template_space_element.filter(
                (space) => space.presv_campaign_space_element_type === "1",
            ),
        )
        .flat();

    return (
        <AdBidingSelectTemplateListItemWrapper
            isPublicTemplateTab={props.isPublicTemplate}
            onClick={() => {
                props.setSelectedPreviewTemplateIdx(templateInfo.template_idx);
            }}
        >
            <AdBidingSelectTemplateListItemThumbnailContainer>
                {!props.isPublicTemplate && (
                    <AdBidingSelectTemplateListItemStatusBadge
                        status={templateInfo.template_status}
                    >
                        {templateInfo.template_status === "1" && "검토중"}
                        {templateInfo.template_status === "2" && "사용가능"}
                        {templateInfo.template_status === "3" && "반려"}
                    </AdBidingSelectTemplateListItemStatusBadge>
                )}
                <AdBidingSelectTemplateListItemThumbnail
                    src={spaceList[0].template_space_element_img_path}
                />
            </AdBidingSelectTemplateListItemThumbnailContainer>
            <AdBidingSelectTemplateListItemInfoWrapper>
                <AdBidingSelectTemplateListItemInfoContainer>
                    <AdBidingSelectTemplateListItemInfoTitle>
                        {props.searchInput
                            ? splitByTargetWord(
                                  templateInfo.template_name,
                                  props.searchInput,
                              ).map((str: string, idx: number) => {
                                  return str === props.searchInput ? (
                                      <AdBidingSelectTemplateListItemInfoTitleBoldSpan
                                          key={idx}
                                      >
                                          {str}
                                      </AdBidingSelectTemplateListItemInfoTitleBoldSpan>
                                  ) : (
                                      <span key={idx}>{str}</span>
                                  );
                              })
                            : templateInfo.template_name}
                    </AdBidingSelectTemplateListItemInfoTitle>

                    <AdBidingSelectTemplateListItemInfoDescContainer>
                        {props.isPublicTemplate && (
                            <AdBidingSelectTemplateListItemInfoDesc>
                                · 제작자 :{" "}
                                <Nickname
                                    prevModalResolve={props.resolve}
                                    nickname={templateInfo.user_nick}
                                    userIdx={templateInfo.user_idx}
                                    nicknameStyledComponent={
                                        AdBidingSelectTemplateListItemInfoDescUnderLineSpan
                                    }
                                    isVerified={
                                        templateInfo.user_influencer_bool
                                    }
                                />
                            </AdBidingSelectTemplateListItemInfoDesc>
                        )}

                        {props.isPublicTemplate &&
                        props.selectedSortingType ===
                            "template_success_count_desc" ? (
                            <AdBidingSelectTemplateListItemInfoDesc>
                                {`· ${
                                    props.selectedDayOffset !== "0"
                                        ? props.selectedDayOffset + "일 동안"
                                        : ""
                                } ${
                                    props.hasAdIdxQuery
                                        ? "이 광고상품에서 사용횟수"
                                        : "낙찰횟수"
                                }
                                  : ${numberToStringWithComma(
                                      templateInfo.template_success_count || 0,
                                  )}
                                `}
                            </AdBidingSelectTemplateListItemInfoDesc>
                        ) : (
                            <AdBidingSelectTemplateListItemInfoDesc>
                                · 총 낙찰횟수 :{" "}
                                {numberToStringWithComma(
                                    templateInfo.template_success_count,
                                )}
                            </AdBidingSelectTemplateListItemInfoDesc>
                        )}
                    </AdBidingSelectTemplateListItemInfoDescContainer>

                    {templateInfo.template_tag.length !== 0 && (
                        <AdBidingSelectPublicTemplateListItemInfoTagContainer>
                            <ChipsNoScroll
                                userIdx={"-9999"}
                                chips={templateInfo.template_tag.map((tag) => {
                                    return {
                                        title: tag.template_tag_name,
                                        key: tag.template_tag_idx,
                                        isSelected: false,
                                    };
                                })}
                                showingRowIndex={1}
                                setChips={() => null}
                                isAllShow={false}
                                isOnlyOneSelected={false}
                                containerHeight={"22px"}
                                renderCustomChip={(idx, chip) => {
                                    return (
                                        <AdBidingSelectPublicTemplateListItemInfoTag
                                            key={chip.key}
                                            isPublicTemplate={
                                                props.isPublicTemplate
                                            }
                                            isSelected={
                                                typeof props.searchInput !==
                                                    "undefined" &&
                                                props.searchInput === chip.title
                                            }
                                            onClick={(e) => {
                                                if (
                                                    typeof props.setSearchInput !==
                                                    "undefined"
                                                ) {
                                                    e.preventDefault();
                                                    e.stopPropagation();

                                                    if (
                                                        typeof props.searchInput !==
                                                            "undefined" &&
                                                        props.searchInput ===
                                                            chip.title
                                                    ) {
                                                        props.setSearchInput(
                                                            "",
                                                        );
                                                    } else {
                                                        props.setSearchInput(
                                                            chip.title,
                                                        );
                                                    }
                                                }
                                            }}
                                        >
                                            {chip.title}
                                        </AdBidingSelectPublicTemplateListItemInfoTag>
                                    );
                                }}
                            />
                        </AdBidingSelectPublicTemplateListItemInfoTagContainer>
                    )}
                </AdBidingSelectTemplateListItemInfoContainer>
                {!props.isPublicTemplate &&
                    templateInfo.template_status === "2" && (
                        <AdBidingSelectTemplateListItemInfoSelectButton
                            btnType={"filled-primary"}
                            onClick={() => {
                                if (props.selectTemplate) {
                                    props.selectTemplate(templateInfo);
                                }
                            }}
                        >
                            템플릿 선택
                        </AdBidingSelectTemplateListItemInfoSelectButton>
                    )}
            </AdBidingSelectTemplateListItemInfoWrapper>
        </AdBidingSelectTemplateListItemWrapper>
    );
};

export default AdBidingSelectTemplateListItem;
