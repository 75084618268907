import {
    AdMyTemplateListApiParamsType,
    AdMyTemplateListApiResultType,
} from "../../query/ad/myTemplate/useAdMyTemplateListQuery";
import { URL_CONSTANTS } from "../../constants/UrlConstants";
import { getAuthKey } from "../../store/auth/authKeyStore";
import { AdMyTemplateInfoApiResultType } from "../../query/ad/myTemplate/useAdMyTemplateInfoQuery";
import { AdMyTemplateOrderChangeParamsType } from "../../query/ad/myTemplate/useAdMyTemplateOrderChangeMutation";
import { AdRegisterMyTemplateParams } from "../../query/ad/myTemplate/useAdMyTemplateRegisterMutation";
import { AdAdjustMyTemplateParams } from "../../query/ad/myTemplate/useAdMyTemplateAdjustMutation";
import { MyTemplateNameDuplicateCheckParams } from "../../query/ad/myTemplate/useAdMyTemplateNameDuplicateCheckMutation";
import axios from "axios";
import { AdMyTemplateAdjustAfterApproveParamsType } from "../../query/ad/myTemplate/useAdMyTemplateAdjustAfterApproveMutation";

export const AdMyTemplateApi = {
    getMyTemplateList: async (
        params: AdMyTemplateListApiParamsType,
    ): Promise<AdMyTemplateListApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(URL_CONSTANTS.API_URL + "/v2/ad/my/template/list", {
                    params: params,
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    const data = res.data.data;
                    resolve({
                        ...data,
                        nextPageNumber:
                            params.limit &&
                            params.page_no &&
                            res.data.data.totalCount >
                                params.limit * params.page_no
                                ? params.page_no + 1
                                : undefined,
                    });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    getMyTemplateInfo: async (
        template_idx: string,
    ): Promise<AdMyTemplateInfoApiResultType> => {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${URL_CONSTANTS.API_URL}/v2/ad/my/template/${template_idx}`,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    changeMyTemplateOrder: async (
        params: AdMyTemplateOrderChangeParamsType,
    ): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .put(
                    URL_CONSTANTS.API_URL + "/v2/ad/my/template/order",
                    params,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    registerMyTemplate: async (
        params: AdRegisterMyTemplateParams,
    ): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .post(URL_CONSTANTS.API_URL + "/v2/ad/my/template", params, {
                    headers: {
                        Authorization: "Bearer " + getAuthKey(),
                    },
                })
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    adjustMyTemplate: async (
        templateIdx: string,
        params: AdAdjustMyTemplateParams,
    ): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .patch(
                    URL_CONSTANTS.API_URL + "/v2/ad/my/template/" + templateIdx,
                    params,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    deleteMyTemplate: async (templateIdx: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .delete(
                    URL_CONSTANTS.API_URL + "/v2/ad/my/template/" + templateIdx,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    checkDuplicateMyTemplateValue: async (
        columnKey: string,
        value: string,
        params: MyTemplateNameDuplicateCheckParams,
    ): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${URL_CONSTANTS.API_URL}/v2/ad/my/template/${columnKey}/check`,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                        params: {
                            ...params,
                            [columnKey]: value,
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    adjustAdMyTemplateAfterApprove: async (
        templateIdx: string,
        targetKey: string,
        params: AdMyTemplateAdjustAfterApproveParamsType,
    ): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios
                .patch(
                    `${URL_CONSTANTS.API_URL}/v2/ad/my/template/${templateIdx}/${targetKey}`,
                    params,
                    {
                        headers: {
                            Authorization: "Bearer " + getAuthKey(),
                        },
                    },
                )
                .then((res: any) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
