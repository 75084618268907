import { useInfiniteQuery } from "react-query";
import { AdTemplateApi } from "../../../apis/adApi/AdTemplateApi";
import { COMMON_QUERY_PARAMS } from "../../../types/BasicType";
import { TemplateListItemType } from "../../../types/ad/templateInfo/TemplateListItemType";

export type AdPublicTemplateListApiResultType = {
    templateList: TemplateListItemType[];
    totalCount: number;
    nextPageNumber?: number;
};

export type AdPublicTemplateListParamsType = {
    campaign_idx: string;
    ad_idx?: string;
    sort_type: string;
    daysOffset: string;
    template_name?: string;
    template_tag_name?: string;
    limit: number;
    page_no: number;
};

export const getAdPublicTemplateListQueryKey = (
    params: AdPublicTemplateListParamsType,
) => {
    return `ad-public-template-list-${params.campaign_idx}-${params.sort_type}-${params.daysOffset}-${params.template_name}-${params.template_tag_name}-${params.limit}`;
};

export const useAdPublicTemplateListQuery = (
    params: AdPublicTemplateListParamsType,
    queryParams: COMMON_QUERY_PARAMS,
) => {
    const queryResult = useInfiniteQuery<
        AdPublicTemplateListApiResultType,
        Error
    >({
        queryKey: [
            "ad-public-template-list-" + JSON.stringify(params),
            getAdPublicTemplateListQueryKey(params),
            "ad-public-template-list",
        ],
        queryFn: ({ pageParam = 1 }) =>
            AdTemplateApi.getTemplateList({
                ...params,
                page_no: pageParam,
            }),
        suspense: false,
        cacheTime: queryParams.cacheTime,
        staleTime: queryParams.staleTime,
        enabled: queryParams.enabled,
        refetchOnWindowFocus: false,
        getNextPageParam: (lastPage) => {
            return lastPage.nextPageNumber;
        },
        onError: () => {},
    });

    const defaultData: AdPublicTemplateListApiResultType[] = [
        {
            templateList: [],
            totalCount: 0,
        },
    ];

    return {
        ...queryResult,
        data: queryResult.data || {
            pages: defaultData,
            pageParams: {},
        },
    };
};

export function spreadPublicTemplateListPages(
    pages: AdPublicTemplateListApiResultType[],
) {
    return pages
        .map((result) => result.templateList)
        .reduce((prev, next) => prev.concat(next));
}
