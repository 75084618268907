import { ReactElement } from "react";
import styled from "styled-components";
import { SortingItemType } from "../../../../../../../../../common/commonUiComponent/doubleSideBar/leftSideBar/LeftSideBar";
import SortingButton from "../../../../../../../../../common/commonUnitComponent/sortingButton/SortingButton";
import SubSearch from "../../../../../../../../../common/commonUiComponent/subSearch/SubSearch";
import { getSelectedSortingList } from "../../../../../../../../../../utils/Utils";
import SquareCheckBox from "../../../../../../../../../common/commonUnitComponent/checkbox/SquareCheckBox";

export const AdBidingSelectPublicTemplateListHeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const AdBidingSelectPublicTemplateListHeaderContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 16px;

    @media ${(props) => props.theme.mobileL} {
        width: 100%;
        flex-direction: column-reverse;
        align-items: flex-end;
        gap: 8px;
    }
`;

export const AdBidingSelectPublicTemplateListHeaderSortingContainer = styled.div`
    display: flex;
    gap: 4px;
`;

export const AdBidingSelectPublicTemplateListHeaderSearchContainer = styled.div`
    flex-shrink: 2;
    width: 330px;

    @media ${(props) => props.theme.mobileL} {
        width: 100%;
    }
`;

export const AdBidingSelectPublicTemplateListHeaderCheckBoxContainer = styled.div`
    display: inline-flex;
`;

export const AdBidingSelectPublicTemplateListHeaderCheckBoxText = styled.div`
    margin-left: 6px;
    color: #555;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
`;

type Props = {
    searchInput: string;
    setSearchInput: (searchInput: string) => void;
    sortingDayOffsetList: SortingItemType[];
    setSortingDayOffsetList: (value: SortingItemType[]) => void;
    sortingTypeList: SortingItemType[];
    setSortingTypeList: (value: SortingItemType[]) => void;

    isOnlySuccessTemplate: boolean;
    setIsOnlySuccessTemplate: (value: boolean) => void;
};

const AdBidingSelectPublicTemplateListHeader = (
    props: Props,
): ReactElement | null => {
    const selectedSortingType = getSelectedSortingList(props.sortingTypeList);

    return (
        <AdBidingSelectPublicTemplateListHeaderWrapper>
            <AdBidingSelectPublicTemplateListHeaderContainer>
                {props.searchInput === "" && (
                    <AdBidingSelectPublicTemplateListHeaderSortingContainer>
                        {selectedSortingType ===
                            "template_success_count_desc" && (
                            <SortingButton
                                sortingList={props.sortingDayOffsetList}
                                setSortingList={props.setSortingDayOffsetList}
                                isDownArrowBtn={true}
                                isLeftStartOpen={true}
                            />
                        )}
                        <SortingButton
                            sortingList={props.sortingTypeList}
                            setSortingList={props.setSortingTypeList}
                            isDownArrowBtn={true}
                        />
                    </AdBidingSelectPublicTemplateListHeaderSortingContainer>
                )}
                <AdBidingSelectPublicTemplateListHeaderSearchContainer>
                    <SubSearch
                        searchInput={props.searchInput}
                        setSearchInput={props.setSearchInput}
                        placeholder={"템플릿 이름 또는 태그를 입력해주세요."}
                    />
                </AdBidingSelectPublicTemplateListHeaderSearchContainer>
            </AdBidingSelectPublicTemplateListHeaderContainer>
            {selectedSortingType === "template_success_count_desc" &&
                props.searchInput === "" && (
                    <AdBidingSelectPublicTemplateListHeaderCheckBoxContainer>
                        <SquareCheckBox
                            id={
                                "ad-biding-select-public-template-list-header-check-box"
                            }
                            checked={props.isOnlySuccessTemplate}
                            onChange={() => {
                                props.setIsOnlySuccessTemplate(
                                    !props.isOnlySuccessTemplate,
                                );
                            }}
                            labelComponent={
                                <AdBidingSelectPublicTemplateListHeaderCheckBoxText>
                                    이 광고상품에서 낙찰된 템플릿
                                </AdBidingSelectPublicTemplateListHeaderCheckBoxText>
                            }
                        />
                    </AdBidingSelectPublicTemplateListHeaderCheckBoxContainer>
                )}
        </AdBidingSelectPublicTemplateListHeaderWrapper>
    );
};

export default AdBidingSelectPublicTemplateListHeader;
