import { useOpenModal } from "../useOpenModal";
import AdManageCommonAnalysisTableModal, {
    MyAnalysisModalProps,
} from "../../../components/ad/adManage/commonComponents/adManageCommonAnalysisTable/components/adManageCommonAnalysisTableModal/AdManageCommonAnalysisTableModal";

export function useOpenAdManageCommonAnalysisTableModal(type: string) {
    const { openModal } = useOpenModal();
    const openAdHistoryAnalysisModal = async (
        myAnalysisModalInfo: MyAnalysisModalProps,
    ) => {
        return await openModal(type + "-modal", "bottomSheet", (resolve) => {
            return (
                <AdManageCommonAnalysisTableModal
                    type={type}
                    resolve={resolve}
                    myAnalysisModalInfo={myAnalysisModalInfo}
                />
            );
        });
    };

    return { openAdHistoryAnalysisModal };
}
