import { ReactElement } from "react";
import styled from "styled-components";
import { URL_CONSTANTS } from "../../../../constants/UrlConstants";

export const SubSearchWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
export const SubSearchContainer = styled.div`
    position: relative;
    display: flex;

    @media ${(props) => props.theme.mobileL} {
        flex-grow: 2;
    }
`;

export const SubSearchInput = styled.input<{
    width: string;
    mobileWidth: string;
    isPassed: boolean;
    isRoundEdge?: boolean;
}>`
    width: ${({ width }) => width};
    height: 40px;
    background: #f5f5f7;
    border-radius: ${({ isRoundEdge }) =>
        isRoundEdge ? "20px / 50%" : "10px"};
    outline: none;
    border: ${({ isPassed }) => (isPassed ? "none" : "1px solid #FF4671;")};
    padding: ${({ isRoundEdge }) =>
        isRoundEdge ? "13px 44px 13px 20px" : "13px 44px 13px 10px"};
    box-sizing: border-box;

    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */

    display: flex;
    align-items: center;

    color: rgba(0, 0, 0, 1);
    font-family: "Noto Sans KR", serif;
    font-style: normal;

    ::placeholder {
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        /* identical to box height, or 14px */

        display: flex;
        align-items: center;

        color: rgba(0, 0, 0, 0.3);
        font-family: "Noto Sans KR", serif;
        font-style: normal;
    }

    @media ${(props) => props.theme.mobileL} {
        width: 100%;
    }
`;

export const SubSearchIcon = styled.img`
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    right: 6px;
    cursor: pointer;
`;

export const SubSearchWarningMsg = styled.div<{ isShow: boolean }>`
    color: #ff4671;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 13px */
    transition: all 300ms ease;
    visibility: ${({ isShow }) => (isShow ? "visible" : "hidden")};
    opacity: ${({ isShow }) => (isShow ? 1 : 0)};
    height: ${({ isShow }) => (isShow ? "auto" : 0)};
    margin-top: ${({ isShow }) => (isShow ? "6px" : 0)};
`;
type Props = {
    searchInput: string;
    setSearchInput: (value: string) => void;
    placeholder: string;
    width: string;
    mobileWidth: string;
    validationCheck: {
        isPassed: boolean;
        id: string;
        msg: string;
    };
    isRoundEdge?: boolean;
    blurHandler?: () => Promise<void>;
};

const SubSearch = (props: Props): ReactElement | null => {
    return (
        <SubSearchWrapper>
            <SubSearchContainer>
                <SubSearchInput
                    isRoundEdge={props.isRoundEdge}
                    onBlur={async () => {
                        if (props.blurHandler) {
                            await props.blurHandler();
                        }
                    }}
                    value={props.searchInput}
                    onChange={(e) => {
                        props.setSearchInput(e.target.value);
                    }}
                    placeholder={props.placeholder}
                    width={props.width}
                    mobileWidth={props.mobileWidth}
                    isPassed={props.validationCheck.isPassed}
                />
                <SubSearchIcon
                    src={`${URL_CONSTANTS.ASSET_URL}/icon/icon-search-black.png`}
                    onClick={() => {
                        //
                    }}
                />
            </SubSearchContainer>

            <SubSearchWarningMsg isShow={!props.validationCheck.isPassed}>
                {props.validationCheck.msg}
            </SubSearchWarningMsg>
        </SubSearchWrapper>
    );
};

export default SubSearch;

SubSearch.defaultProps = {
    width: "100%",
    mobileWidth: "100%",
    validationCheck: {
        isPassed: true,
        id: "",
        msg: "",
    },
};
