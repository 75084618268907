import { ReactElement } from "react";
import styled from "styled-components";
import {
    ModalButton,
    ModalButtonContainer,
    ModalContainer,
    ModalDesc,
    ModalTitle,
} from "../../../../../common/modal/ModalStyle";
import ModalCloseIcon from "../../../../../common/modal/modalCloseIcon/ModalCloseIcon";
import { PathAfterResolveType } from "../../../../../../hooks/modal/useOpenModal";
import { useLocation } from "react-router-dom";

export const AdTemplateRegisterStepConfirmModalContainer = styled.div``;

export type AdTemplateRegisterStepConfirmResolveType = {
    isOnlyClose: boolean;
    hasGuide: boolean;
};

type Props = {
    resolve: (value: PathAfterResolveType | boolean) => void;
    campaignIdx: string;
    isPathAfterNestedResolve: boolean;
};

const AdTemplateRegisterStepConfirmModal = (
    props: Props,
): ReactElement | null => {
    const location = useLocation();

    return (
        <ModalContainer isNoPadding={false}>
            <ModalCloseIcon
                resolve={(value: boolean) => {
                    props.resolve(false);
                }}
            />

            <ModalTitle
                style={{
                    lineHeight: 1.4,
                }}
            >
                템플릿 등록이 처음이신가요?
            </ModalTitle>
            <ModalDesc
                style={{
                    marginTop: "16px",
                }}
            >
                초보자용 가이드와 함께 <br />
                템플릿 등록 안내를 받아보시겠습니까?
            </ModalDesc>
            <ModalButtonContainer>
                <ModalButton
                    btnType={"filled-secondary"}
                    onClick={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        props.resolve(
                            props.isPathAfterNestedResolve
                                ? {
                                      pathAfterNestedResolve: `/ad/template-register/${props.campaignIdx}`,
                                      state: {
                                          beforePath: location.pathname,
                                      },
                                  }
                                : {
                                      pathAfterResolve: `/ad/template-register/${props.campaignIdx}`,
                                      state: {
                                          beforePath: location.pathname,
                                      },
                                  },
                        );
                    }}
                >
                    아니오
                </ModalButton>
                <ModalButton
                    btnType={"filled-primary"}
                    onClick={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        props.resolve(
                            props.isPathAfterNestedResolve
                                ? {
                                      pathAfterNestedResolve: `/ad/template-register/${props.campaignIdx}/step/1`,
                                      state: {
                                          beforePath: location.pathname,
                                      },
                                  }
                                : {
                                      pathAfterResolve: `/ad/template-register/${props.campaignIdx}/step/1`,
                                      state: {
                                          beforePath: location.pathname,
                                      },
                                  },
                        );
                    }}
                >
                    가이드 받기
                </ModalButton>
            </ModalButtonContainer>
        </ModalContainer>
    );
};

export default AdTemplateRegisterStepConfirmModal;
