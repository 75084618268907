import React, { ReactElement, useState } from "react";
import styled from "styled-components";
import { DateRange } from "react-day-picker";
import { addDays } from "date-fns";
import MarketProjectHistoryTable from "./MarketProjectHistoryTable";
import { FilterType } from "../../../../common/commonUiComponent/searchFilter/SearchFilter";
import { ExchangeListFilters } from "../../../../../constants/exchangeListFilters/ExchagneListFilters";
import DayPickerLayer from "../../../../common/commonUnitComponent/dayPickerLayer/DayPickerLayer";

export const MarketProjectHistoryContainer = styled.div`
    margin-top: 10px;
`;

export const MarketProjectHistoryHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media ${(props) => props.theme.mobileL} {
        flex-wrap: wrap;
        gap: 10px;
    }
`;

export const MarketProjectHistoryLeftHeader = styled.div`
    display: flex;
    align-items: center;
    @media ${(props) => props.theme.mobileL} {
        width: 100%;
    }
`;

export const MarketProjectHistoryRightHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    @media ${(props) => props.theme.mobileL} {
        width: 100%;
        gap: 10px;
    }
`;

export const MarketProjectHistoryBody = styled.div`
    margin-top: 40px;
`;

const marketHistoryFilters: FilterType[] = ExchangeListFilters;

type Props = {};

const MarketProjectHistory = (props: Props): ReactElement | null => {
    const [searchInput, setSearchInput] = useState("");
    const [filters, setFilters] = useState(marketHistoryFilters);
    const today = new Date();
    const defaultSelected: DateRange = {
        from: addDays(today, -7),
        to: addDays(today, +0),
    };
    const [selectedDateRange, setSelectedDateRange] =
        useState<DateRange>(defaultSelected);

    return (
        <MarketProjectHistoryContainer>
            <MarketProjectHistoryHeader>
                <MarketProjectHistoryLeftHeader>
                    <DayPickerLayer
                        isMobileTitleHide={false}
                        selectedDateRange={selectedDateRange}
                        setSelectedDateRange={setSelectedDateRange}
                    />
                </MarketProjectHistoryLeftHeader>
                <MarketProjectHistoryRightHeader>
                    {/*<SearchFilter*/}
                    {/*    id={"market-project-item-list"}*/}
                    {/*    title={"아이템 검색 필터"}*/}
                    {/*    layerPositionStyleString={"right: 10px;"}*/}
                    {/*    filters={filters}*/}
                    {/*    setFilters={setFilters}*/}
                    {/*    filterItemWidth={"200px"}*/}
                    {/*/>*/}
                    {/*<MarketProjectHistoryRightHeaderSubSearchContainer>*/}
                    {/*    <SubSearch*/}
                    {/*        searchInput={searchInput}*/}
                    {/*        setSearchInput={setSearchInput}*/}
                    {/*        placeholder={"아이템명을 입력해주세요."}*/}
                    {/*    />*/}
                    {/*</MarketProjectHistoryRightHeaderSubSearchContainer>*/}
                </MarketProjectHistoryRightHeader>
            </MarketProjectHistoryHeader>
            <MarketProjectHistoryBody>
                <MarketProjectHistoryTable
                    selectedDateRange={selectedDateRange}
                    filters={filters}
                />
            </MarketProjectHistoryBody>
        </MarketProjectHistoryContainer>
    );
};

export default MarketProjectHistory;
