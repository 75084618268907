import React, { ReactElement, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import AdManageCommonMoreBtn from "../../../commonComponents/AdManageCommonMoreBtn";
import AdManageMyTemplateListItem from "./AdManageMyTemplateListItem";
import { useLocation } from "react-router-dom";
import XYOrderChangDndList from "../../../../../common/commonUiComponent/xYOrderChangeDndList/XYOrderChangDndList";
import {
    spreadAdTemplateListPages,
    useAdMyTemplateListQuery,
} from "../../../../../../query/ad/myTemplate/useAdMyTemplateListQuery";
import { ChipType } from "../../../../../common/commonUiComponent/doubleSideBar/leftSideBar/LeftSideBar";
import { checkUserAgent, getSelectedChip } from "../../../../../../utils/Utils";
import { useSetStateOnWindowWidth } from "../../../../../../hooks/common/useSetStateOnWindowWidth";
import ApiResBoundary from "../../../../../common/apiResBoundary/ApiResBoundary";
import { useAdMyTemplateOrderChangeMutation } from "../../../../../../query/ad/myTemplate/useAdMyTemplateOrderChangeMutation";
import {
    CommonButtonStyle,
    CommonButtonType,
} from "../../../../../common/commonStyle/CommonButtonStyle";
import { useOnClickOutside } from "usehooks-ts";
import { DropdownListItemType } from "../../../../../common/commonUnitComponent/commonDropdownBtn/CommonDropdownBtn";
import isShowMobileBottomButtonAtom from "../../../../../../store/ui/isShowMobileBottomButtonAtom";
import { useSetAtom } from "jotai";
import { useOpenAdTemplateRegisterConfirmModal } from "../../../../_adTemplate/adTemplateRegisterStep/components/adTemplateRegisterStepConfirmModal/useOpenAdTemplateRegisterConfirmModal";
import { CampaignSimpleListItemType } from "../../../../../../types/ad/camapaignInfo/CampaignSimpleListItemType";

export const AdManageMyTemplateListWrapper = styled.div`
    margin-top: 60px;

    @media ${(props) => props.theme.mobileM} {
        margin-top: 32px;
    }
`;

export const AdManageMyTemplateListTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media ${(props) => props.theme.mobileL} {
        flex-wrap: wrap;
        gap: 20px;
    }
`;

export const AdManageMyTemplateListTitle = styled.div`
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    /* identical to box height, or 24px */

    color: #222222;

    @media ${(props) => props.theme.mobileM} {
        font-size: 20px;
    }
`;

export const AdManageMyTemplateListTitleRightContainer = styled.div`
    display: flex;
    gap: 10px;

    @media ${(props) => props.theme.mobileL} {
        width: 100%;
        justify-content: space-between;
    }
`;

export const AdManageMyTemplateListOrderChangeButton = styled.div<
    CommonButtonType & { isMobileDevice: boolean }
>`
    ${CommonButtonStyle};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    display: none;
    height: 34px;
    flex-shrink: 1;
    text-align: center;

    @media ${(props) => props.theme.tabletM} {
        display: ${({ isMobileDevice }) => (isMobileDevice ? "flex" : "none")};
        width: auto;
        padding: 0 12px;
    }

    @media ${(props) => props.theme.mobileL} {
        width: 50%;
    }
`;

export const AdManageMyTemplateListAddButton = styled.div<{
    $isMobileDevice: boolean;
}>`
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */
    width: auto;
    color: #fa54fa;
    display: flex;
    align-items: center;
    padding: 0 14px;
    height: 32px;
    border: 1px solid #fa54fa;
    border-radius: 8px;
    cursor: pointer;
    flex-shrink: 1;

    @media ${(props) => props.theme.mobileL} {
        padding: 0 12px;
        width: ${({ $isMobileDevice }) =>
            $isMobileDevice ? "calc(50% - 24px)" : "calc(100% - 24px)"};
        justify-content: center;
    }

    transition: 300ms ease background;
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: #fa54fa10;
        }
    }
`;

export const AdManageMyTemplateListRelativeContainer = styled.div`
    position: relative;
    z-index: 2;
`;

export const AdManageMyTemplateListContainer = styled.div`
    margin-top: 38px;
    height: 100%;

    @media ${(props) => props.theme.mobileL} {
        margin-top: 36px;
    }
`;

export const AdManageMyTemplateNoDataList = styled.div`
    margin-top: 32px;
    width: calc(25% - 18px);
    height: 200px;

    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: #f5f5f7;
    border-radius: 12px;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    text-align: center;
    color: #000000;

    cursor: pointer;

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background: #e4e4e5;
        }
    }
    transition: background 300ms ease;

    @media ${(props) => props.theme.tabletM} {
        width: calc(33% - 26px);
    }

    @media ${(props) => props.theme.mobileL} {
        width: 50%;
    }

    @media ${(props) => props.theme.mobileM} {
        width: 100%;
    }
`;

export const AdManageMyTemplateListPlusBtnContainer = styled.div`
    width: 50px;
    height: 50px;
    font-size: 50px;
    display: flex;

    justify-content: center;
    color: white;
    line-height: 0.95;
    border-radius: 50px;
    background: #cccccc;
`;

export const AdManageMyTemplateListUnderLine = styled.div`
    border-bottom: 1px solid #e5e5e5;
    margin: 60px 0;

    @media ${(props) => props.theme.mobileM} {
        margin: 40px 0;
    }
`;

export const AdManageMyTemplateBackgroundColors = [
    "#F7F0FC",
    "#F0F1FD",
    "#ECF4FB",
    "#EDF5F7",
    "#FBF5E9",
    "#FAF0EB",
    "#FAEDEE",
    "#FAF0F7",
];

type Props = {
    campaignSimpleListItem: CampaignSimpleListItemType;
    campaignDataOrderIdx: number;
    templateChips: ChipType[];
    brandDropdownList: DropdownListItemType[];
};

const AdManageMyTemplateList = (props: Props): ReactElement | null => {
    const [boxPerRow, setBoxPerRow] = useState(4);
    const { isOnMountedSetDone: isOnMountedSetDoneBoxPerRow } =
        useSetStateOnWindowWidth(setBoxPerRow, [4, 4, 4, 4, 3, 2, 1]);
    const [rowGap, setRowGap] = useState(26);
    const { isOnMountedSetDone: isOnMountedSetDoneRowGap } =
        useSetStateOnWindowWidth(setRowGap, [26, 26, 24, 24, 20, 12, 0]);
    const [columnGap, setColumnGap] = useState(49);
    const { isOnMountedSetDone: isOnMountedSetDoneColumnGap } =
        useSetStateOnWindowWidth(setColumnGap, [49, 49, 41, 41, 40, 33, 24]);
    const setIsShowMobileBottomButton = useSetAtom(
        isShowMobileBottomButtonAtom,
    );
    const limit = 8;

    const {
        data: rawTemplateList,
        fetchNextPage: nextFetchTemplateList,
        hasNextPage: hasNextTemplateList,
        error: errorTemplateList,
        isSuccess: isTemplateListSuccess,
        isLoading: isTemplateListLoading,
    } = useAdMyTemplateListQuery(
        {
            campaign_idx: props.campaignSimpleListItem.campaign_idx,
            limit: boxPerRow === 3 ? 9 : 8,
            page_no: 1,
            template_status: getSelectedChip(props.templateChips, ""),
        },
        isOnMountedSetDoneBoxPerRow &&
            isOnMountedSetDoneRowGap &&
            isOnMountedSetDoneColumnGap,
    );

    const { openAdTemplateRegisterConfirmModal } =
        useOpenAdTemplateRegisterConfirmModal();
    const [templateList, setTemplateList] = useState(
        spreadAdTemplateListPages(rawTemplateList.pages),
    );

    useEffect(() => {
        if (isTemplateListSuccess) {
            setTemplateList(spreadAdTemplateListPages(rawTemplateList.pages));
        }
    }, [isTemplateListSuccess, rawTemplateList]);

    const [isMobileOrderChanging, setIsMobileOrderChanging] = useState(false);

    const { mutateAsync: changeTemplateOrder } =
        useAdMyTemplateOrderChangeMutation({
            campaign_idx: props.campaignSimpleListItem.campaign_idx,
            template_order: templateList.map((template, idx) => {
                return {
                    template_idx: template.templateInfo.template_idx,
                    order_no: idx,
                };
            }),
        });

    const location = useLocation();
    const templateAreaBodyContainer = useRef<HTMLDivElement>(null);

    useOnClickOutside(templateAreaBodyContainer, () => {
        setIsMobileOrderChanging(false);
    });

    useEffect(() => {
        setIsMobileOrderChanging(false);

        // 템플릿 상세 => 뒤로가기시 UI 깨지는 문제있어서 강제 초기화
        // queryClient.resetQueries({
        //     predicate: (query: Query) =>
        //         query.queryKey.includes("ad-template-list"),
        // });
    }, [location.pathname]);

    useEffect(() => {
        setIsShowMobileBottomButton(!isMobileOrderChanging);
        return () => {
            setIsShowMobileBottomButton(true);
        };
    }, [isMobileOrderChanging]);

    return (
        <AdManageMyTemplateListWrapper ref={templateAreaBodyContainer}>
            <AdManageMyTemplateListTitleContainer>
                <AdManageMyTemplateListTitle>
                    {props.campaignSimpleListItem.campaign_name}
                </AdManageMyTemplateListTitle>
                {templateList.length !== 0 && (
                    <AdManageMyTemplateListTitleRightContainer>
                        <AdManageMyTemplateListOrderChangeButton
                            btnType={"outlined-secondary"}
                            isMobileDevice={checkUserAgent.isMobileDevice()}
                            onClick={() => {
                                setIsMobileOrderChanging(true);
                            }}
                        >
                            순서 변경
                        </AdManageMyTemplateListOrderChangeButton>
                        <AdManageMyTemplateListAddButton
                            $isMobileDevice={checkUserAgent.isMobileDevice()}
                            onClick={async () => {
                                await openAdTemplateRegisterConfirmModal(
                                    props.campaignSimpleListItem.campaign_idx,
                                );
                            }}
                        >
                            + 새 템플릿 추가
                        </AdManageMyTemplateListAddButton>
                    </AdManageMyTemplateListTitleRightContainer>
                )}
            </AdManageMyTemplateListTitleContainer>
            <AdManageMyTemplateListRelativeContainer>
                <ApiResBoundary
                    error={errorTemplateList}
                    isLoading={isTemplateListLoading}
                    isNoData={templateList.length === 0}
                    defaultLoadingFallbackContainerHeight={"400px"}
                    noDataFallback={
                        <AdManageMyTemplateNoDataList
                            onClick={async () => {
                                await openAdTemplateRegisterConfirmModal(
                                    props.campaignSimpleListItem.campaign_idx,
                                );
                            }}
                        >
                            <AdManageMyTemplateListPlusBtnContainer>
                                +
                            </AdManageMyTemplateListPlusBtnContainer>
                            내 광고 템플릿 만들기
                        </AdManageMyTemplateNoDataList>
                    }
                >
                    <AdManageMyTemplateListContainer>
                        <XYOrderChangDndList
                            exceptionalContainerStyle={{
                                paddingBottom: "50px",
                                zIndex: "none",
                            }}
                            id={
                                "ad-template-manage-" +
                                props.campaignDataOrderIdx
                            }
                            list={templateList}
                            setList={setTemplateList}
                            onSortEnd={async () => {
                                await changeTemplateOrder();
                            }}
                            boxPerRow={boxPerRow}
                            rowGap={rowGap}
                            columnGap={columnGap}
                            isMobileOrderChanging={isMobileOrderChanging}
                            setIsMobileOrderChanging={setIsMobileOrderChanging}
                            renderListItem={({
                                dndListItemInfo: templateData,
                                index,
                            }) => {
                                return (
                                    <AdManageMyTemplateListItem
                                        key={
                                            templateData.templateInfo
                                                .template_idx
                                        }
                                        idx={index}
                                        campaignIdx={
                                            props.campaignSimpleListItem
                                                .campaign_idx
                                        }
                                        selectedTemplateStatus={getSelectedChip(
                                            props.templateChips,
                                            "",
                                        )}
                                        templateListItem={templateData}
                                        isMobileOrderChanging={
                                            isMobileOrderChanging
                                        }
                                        background={
                                            AdManageMyTemplateBackgroundColors[
                                                props.campaignDataOrderIdx
                                            ]
                                        }
                                        boxPerRow={boxPerRow}
                                    />
                                );
                            }}
                        />
                    </AdManageMyTemplateListContainer>
                </ApiResBoundary>
            </AdManageMyTemplateListRelativeContainer>
            {templateList.length !== 0 && hasNextTemplateList && (
                <AdManageCommonMoreBtn
                    clickEvent={async () => {
                        await nextFetchTemplateList();
                    }}
                    mb={65}
                />
            )}
            {(templateList.length === 0 || !hasNextTemplateList) && (
                <AdManageMyTemplateListUnderLine />
            )}
        </AdManageMyTemplateListWrapper>
    );
};

export default AdManageMyTemplateList;
