import { ReactElement, useEffect } from "react";
import styled from "styled-components";
import AdBidingSelectTemplateListItem from "../../AdBidingSelectTemplateListItem";
import {
    AdPublicTemplateListParamsType,
    spreadPublicTemplateListPages,
    useAdPublicTemplateListQuery,
} from "../../../../../../../../../../query/ad/publicTemplate/useAdPublicTemplateListQuery";
import { PathAfterResolveType } from "../../../../../../../../../../hooks/modal/useOpenModal";
import MoreButton from "../../../../../../../../../common/commonUnitComponent/moreButton/MoreButton";
import AdBidingSelectPublicTemplateNoDataList from "./AdBidingSelectPublicTemplateNoDataList";

export const AdBidingSelectPublicTemplateListWrapper = styled.div`
    gap: 24px;
    display: flex;
    flex-direction: column;
`;

export const AdBidingSelectPublicTemplateListContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 24px;

    @media ${(props) => props.theme.mobileL} {
        gap: 16px 12px;
    }
`;

export const AdBidingSelectPublicTemplateListSearchInfo = styled.div`
    width: 100%;
    color: #555;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    justify-content: center;
`;

export const AdBidingSelectPublicTemplateListSearchInfoBold = styled.span`
    color: #fa54fa;
    font-weight: 500;
`;

export const AdBidingSelectPublicTemplateListMoreBtnWrapper = styled.div`
    margin-top: 24px;
    width: 100%;
    display: flex;
    justify-content: center;

    @media ${(props) => props.theme.mobileL} {
        margin-top: 16px;
    }
`;

export const AdBidingSelectPublicTemplateListMoreBtnContainer = styled.div`
    width: 311px;

    @media ${(props) => props.theme.mobileL} {
        width: 100%;
    }
`;

type Props = {
    resolve: (value: PathAfterResolveType | boolean) => void;
    debouncedSearchInput: string;
    searchInput: string;
    setSearchInput: (value: string) => void;
    publicTemplateListQueryParams: AdPublicTemplateListParamsType;
    setResultListLength?: (value: number) => void;
    setSelectedPreviewTemplateIdx: (value: string) => void;
};

const AdBidingSelectPublicTemplateList = (
    props: Props,
): ReactElement | null => {
    const {
        data: rawPublicTemplateList,
        isLoading: isPublicTemplateLoading,
        isSuccess: isPublicTemplateSuccess,
        error: isPublicTemplateError,
        fetchNextPage: fetchPublicTemplateListNextPage,
        hasNextPage: hasPublicTemplateListNextPage,
    } = useAdPublicTemplateListQuery(props.publicTemplateListQueryParams, {
        enabled: props.publicTemplateListQueryParams.campaign_idx !== "-1",
        cacheTime: 60000,
        staleTime: 500,
    });

    const publicTemplateList = spreadPublicTemplateListPages(
        rawPublicTemplateList.pages,
    );

    useEffect(() => {
        if (isPublicTemplateSuccess && props.setResultListLength) {
            props.setResultListLength(publicTemplateList.length);
        }
    }, [isPublicTemplateSuccess, rawPublicTemplateList]);

    return publicTemplateList.length === 0 &&
        typeof props.setResultListLength === "undefined" ? (
        <AdBidingSelectPublicTemplateNoDataList
            searchInput={""}
            msg={"등록된 공유 템플릿이 없습니다."}
        />
    ) : (
        <AdBidingSelectPublicTemplateListWrapper>
            {props.debouncedSearchInput !== "" && (
                <AdBidingSelectPublicTemplateListSearchInfo>
                    <AdBidingSelectPublicTemplateListSearchInfoBold>
                        {props.debouncedSearchInput}
                    </AdBidingSelectPublicTemplateListSearchInfoBold>
                    (으)로 검색한{" "}
                    <b>
                        {typeof props.publicTemplateListQueryParams
                            .template_tag_name === "undefined"
                            ? "템플릿 이름"
                            : "태그"}
                    </b>{" "}
                    검색 결과
                </AdBidingSelectPublicTemplateListSearchInfo>
            )}

            <AdBidingSelectPublicTemplateListContainer>
                {publicTemplateList.map((template, templateIdx) => {
                    return (
                        <AdBidingSelectTemplateListItem
                            key={`${templateIdx}-${template.templateInfo.template_idx}`}
                            resolve={props.resolve}
                            isPublicTemplate={true}
                            hasAdIdxQuery={
                                typeof props.publicTemplateListQueryParams
                                    .ad_idx !== "undefined"
                            }
                            templateListItem={template}
                            setSelectedPreviewTemplateIdx={
                                props.setSelectedPreviewTemplateIdx
                            }
                            searchInput={props.searchInput}
                            setSearchInput={props.setSearchInput}
                            selectedSortingType={
                                props.publicTemplateListQueryParams.sort_type
                            }
                            selectedDayOffset={
                                props.publicTemplateListQueryParams.daysOffset
                            }
                        />
                    );
                })}
            </AdBidingSelectPublicTemplateListContainer>
            {hasPublicTemplateListNextPage && (
                <AdBidingSelectPublicTemplateListMoreBtnWrapper>
                    <AdBidingSelectPublicTemplateListMoreBtnContainer>
                        <MoreButton
                            isShow={true}
                            isLoading={isPublicTemplateLoading}
                            isSupportFold={false}
                            onClick={async () => {
                                await fetchPublicTemplateListNextPage();
                            }}
                        />
                    </AdBidingSelectPublicTemplateListMoreBtnContainer>
                </AdBidingSelectPublicTemplateListMoreBtnWrapper>
            )}
        </AdBidingSelectPublicTemplateListWrapper>
    );
};

export default AdBidingSelectPublicTemplateList;
