import { useQuery } from "react-query";
import {
    BrandInfoType,
    DEFAULT_BRAND_INFO_TYPE_DATA,
} from "../../../types/brand/BrandInfoType";
import {
    CampaignInfoType,
    DEFAULT_CAMPAIGN_INFO_TYPE_DATA,
} from "../../../types/ad/camapaignInfo/CampaignInfoType";
import {
    DEFAULT_TEMPLATE_INFO_TYPE_DATA,
    TemplateInfoType,
} from "../../../types/ad/templateInfo/TemplateInfoType";
import { AdTemplateApi } from "../../../apis/adApi/AdTemplateApi";
import { COMMON_QUERY_PARAMS } from "../../../types/BasicType";

export type AdPublicTemplateInfoApiResultType = {
    brandInfo: BrandInfoType;
    campaignInfo: CampaignInfoType;
    templateInfo: TemplateInfoType;
};

const DefaultAdPublicTemplateData: AdPublicTemplateInfoApiResultType = {
    campaignInfo: DEFAULT_CAMPAIGN_INFO_TYPE_DATA,
    brandInfo: DEFAULT_BRAND_INFO_TYPE_DATA,
    templateInfo: DEFAULT_TEMPLATE_INFO_TYPE_DATA,
};

export const useAdPublicTemplateInfoQuery = (
    templateIdx: string,
    queryParams: COMMON_QUERY_PARAMS = {
        enabled: true,
        cacheTime: 60000,
        staleTime: 500,
    },
) => {
    const queryResult = useQuery<AdPublicTemplateInfoApiResultType, Error>({
        queryKey: ["ad-public-template-" + templateIdx],
        queryFn: () => AdTemplateApi.getTemplateInfo(templateIdx),
        suspense: false,
        onError: () => {},
        enabled: queryParams.enabled,
        cacheTime: queryParams.cacheTime,
        staleTime: queryParams.staleTime,
    });

    return {
        ...queryResult,
        data: queryResult.data || DefaultAdPublicTemplateData,
    };
};
