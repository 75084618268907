import { CSSProperties, ReactElement } from "react";
import { SortableContainer } from "react-sortable-hoc";
import styled from "styled-components";
import DndListItem from "./DndListItem";

export const DndListWrapper = styled.div<{
    gap: string;
    itemPerRow: number;
    isMoving?: boolean;
}>`
    width: inherit;
    position: relative;

    display: grid;
    grid-template-columns: ${({ itemPerRow }) =>
        `repeat(${itemPerRow}, minmax(0, 1fr))`};
    grid-gap: ${({ gap }) => gap};

    -ms-overflow-style: none; /* 인터넷 익스플로러 */
    scrollbar-width: none; /* 파이어폭스 */

    ::-webkit-scrollbar {
        display: none;
    }
`;

type Props = {
    dndList: any[];
    renderListItem: (value?: any) => ReactElement;
    id: string;
    itemPerRow: number;
    columnGap: number;
    rowGap: number;
    isMoving?: boolean;

    exceptionalContainerStyle?: CSSProperties;
    exceptionalItemStyle?: CSSProperties;
};

const DndListContainer = SortableContainer<Props>((props: Props) => {
    return (
        <DndListWrapper
            gap={props.columnGap + "px " + props.rowGap + "px"}
            itemPerRow={props.itemPerRow}
            isMoving={props.isMoving}
            style={props.exceptionalContainerStyle}
        >
            {props.dndList.map((item, idx) => {
                return (
                    <DndListItem
                        key={props.id + "-dnd-list-item-" + idx}
                        index={idx}
                        idx={idx}
                        dndItem={item}
                        renderListItem={props.renderListItem}
                        columnGap={props.columnGap}
                        rowGap={props.rowGap}
                        itemPerRow={props.itemPerRow}
                        dndListLength={props.dndList.length}
                        exceptionalItemStyle={props.exceptionalItemStyle}
                    />
                );
            })}
        </DndListWrapper>
    );
});

export default DndListContainer;
