import { ReactElement } from "react";
import styled from "styled-components";
import { numberToStringWithComma } from "../../../../../../utils/Utils";
import {
    ModalButton,
    ModalButtonContainer,
    ModalInfoContainer,
} from "../../../../modal/ModalStyle";
import {
    InfoTableColumnBoldValue,
    InfoTableColumnUnit,
    InfoTableColumnValue,
    InfoTableMajorRow,
    InfoTableRowContainer,
    InfoTableSubColumnValue,
    InfoTableSubRow,
    InfoTableUnderLine,
} from "../../../../../../styles/InfoTableStyle";
import { useOpenCommonConfirmModal } from "../../../../../../hooks/modal/openModal/useOpenCommonConfirmModal";
import { OrderReceiptModalWarning } from "../../OrderReceiptCommonStyle";
import { useOrderCancelMutation } from "../../../../../../query/market/common/useOrderCancelMutation";
import { OrderItemReceiptInfoType } from "../OrderItemReceiptModal";

export const OrderItemReceiptSellCancelInfoContainer = styled.div`
    width: 100%;
`;

type Props = {
    receiptInfo: OrderItemReceiptInfoType;
    resolve: (value: boolean) => void;
};

const OrderItemReceiptSellCancelInfo = (props: Props): ReactElement | null => {
    const { mutateAsync: cancelOrder } = useOrderCancelMutation(
        "item",
        props.receiptInfo.order_idx,
        props.receiptInfo.product_idx,
    );

    const { openCommonConfirmModal } = useOpenCommonConfirmModal();

    const settledQuantity = props.receiptInfo.settled_quantity_sum;

    const itemBuyBrandRoyalty =
        props.receiptInfo.unit_brand_royalty *
        (props.receiptInfo.order_quantity - settledQuantity);

    const itemBuyCreatorRoyalty =
        props.receiptInfo.unit_creator_royalty *
        (props.receiptInfo.order_quantity - settledQuantity);

    return (
        <OrderItemReceiptSellCancelInfoContainer>
            <ModalInfoContainer width={"280px"} mt={"13px"}>
                <InfoTableRowContainer>
                    <InfoTableMajorRow>
                        등록 수량
                        <InfoTableColumnValue>
                            {numberToStringWithComma(
                                props.receiptInfo.order_quantity,
                            )}
                            <InfoTableColumnUnit>개</InfoTableColumnUnit>
                        </InfoTableColumnValue>
                    </InfoTableMajorRow>
                    <InfoTableMajorRow>
                        판매 단가
                        <InfoTableColumnValue>
                            {numberToStringWithComma(
                                props.receiptInfo.order_unit_price,
                            )}
                            <InfoTableColumnUnit>
                                {props.receiptInfo.currency_point_name}
                            </InfoTableColumnUnit>
                        </InfoTableColumnValue>
                    </InfoTableMajorRow>
                    <InfoTableMajorRow>
                        체결 수량
                        <InfoTableColumnValue>
                            {numberToStringWithComma(settledQuantity)}
                            <InfoTableColumnUnit>개</InfoTableColumnUnit>
                        </InfoTableColumnValue>
                    </InfoTableMajorRow>
                </InfoTableRowContainer>

                <InfoTableUnderLine />
                <InfoTableRowContainer>
                    <InfoTableMajorRow>
                        환급 로열티
                        <InfoTableColumnBoldValue>
                            {numberToStringWithComma(
                                itemBuyBrandRoyalty + itemBuyCreatorRoyalty,
                            )}
                            <InfoTableColumnUnit>RP</InfoTableColumnUnit>
                        </InfoTableColumnBoldValue>
                    </InfoTableMajorRow>
                    <InfoTableSubRow>
                        {props.receiptInfo.item_brand_name}
                        <InfoTableSubColumnValue>
                            {numberToStringWithComma(itemBuyBrandRoyalty)}
                            <InfoTableColumnUnit>RP</InfoTableColumnUnit>
                        </InfoTableSubColumnValue>
                    </InfoTableSubRow>
                    <InfoTableSubRow>
                        크리에이터
                        <InfoTableSubColumnValue>
                            {numberToStringWithComma(itemBuyCreatorRoyalty)}
                            <InfoTableColumnUnit>RP</InfoTableColumnUnit>
                        </InfoTableSubColumnValue>
                    </InfoTableSubRow>
                </InfoTableRowContainer>
                <InfoTableUnderLine />

                <InfoTableRowContainer>
                    <InfoTableMajorRow>
                        확정 수익
                        <InfoTableColumnBoldValue>
                            {numberToStringWithComma(
                                settledQuantity *
                                    props.receiptInfo.order_unit_price,
                            )}
                            <InfoTableColumnUnit>
                                {props.receiptInfo.currency_point_name}
                            </InfoTableColumnUnit>
                        </InfoTableColumnBoldValue>
                    </InfoTableMajorRow>
                </InfoTableRowContainer>
            </ModalInfoContainer>
            <OrderReceiptModalWarning>
                *실시간 판매 현황에 따라 내용이 변경될 수 있습니다.
            </OrderReceiptModalWarning>
            <ModalButtonContainer>
                <ModalButton
                    btnType={"filled-secondary"}
                    onClick={() => {
                        props.resolve(false);
                    }}
                >
                    닫기
                </ModalButton>
                <ModalButton
                    btnType={"filled-danger"}
                    onClick={async () => {
                        const confirmResult = await openCommonConfirmModal({
                            desc: "판매를 정말로 취소시겠습니까?",
                        });

                        if (confirmResult) {
                            await cancelOrder();
                            props.resolve(false);
                        }
                    }}
                >
                    판매 취소
                </ModalButton>
            </ModalButtonContainer>
        </OrderItemReceiptSellCancelInfoContainer>
    );
};

export default OrderItemReceiptSellCancelInfo;
