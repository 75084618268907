import { ReactElement, useEffect } from "react";
import {
    spreadAdCampaignManagedListPages,
    useAdCampaignManagedListQuery,
} from "../../../../query/ad/campaign/useAdCampaignManagedListQuery";
import {
    BRAND_ID_SAYBUZZ,
    BRAND_ID_SOOP,
} from "../../../../constants/brand/Brand";
import { getIsLogin } from "../../../../store/auth/authKeyStore";
import { useOpenCommonErrorModal } from "../../../../hooks/modal/openModal/useOpenCommonErrorModal";
import { useNavigate } from "react-router-dom";

type Props = {};

const AdRegisterRoutingHelper = (props: Props): ReactElement | null => {
    const isLogin = getIsLogin();
    const navigate = useNavigate();
    const { openCommonErrorModal } = useOpenCommonErrorModal();

    const {
        data: rawAdCampaignManagedList,
        isSuccess: isAdCampaignManagedListSuccess,
    } = useAdCampaignManagedListQuery(
        {
            campaign_type_array: "1",
            brand_id_array: `${BRAND_ID_SOOP}, ${BRAND_ID_SAYBUZZ}`,
            page_no: 1,
            limit: 4,
        },
        isLogin,
    );
    const campaignManagedList = spreadAdCampaignManagedListPages(
        rawAdCampaignManagedList.pages,
    );

    useEffect(() => {
        if (!isLogin) {
            openCommonErrorModal({
                title: "로그인이 필요한 서비스입니다.",
            });
            navigate(-1);
        }
    }, [isLogin]);

    useEffect(() => {
        if (isAdCampaignManagedListSuccess && campaignManagedList.length > 0) {
            const campaignInfo = campaignManagedList[0].campaignInfo;
            const adInfo = campaignManagedList[0].adInfo;

            if (adInfo.registered_bool) {
                if (adInfo.ad_stop_switch_bool) {
                    navigate(`/ad/re-register/${adInfo.ad_idx}`, {
                        replace: true,
                        state: {
                            campaignIdx: "3",
                        },
                    });
                }
                //
                else {
                    navigate(-1);
                    setTimeout(() => {
                        openCommonErrorModal({
                            title: "이미 판매등록하셨습니다.",
                        });
                    }, 300);
                }
            } else {
                navigate(`/ad/register/${campaignInfo.campaign_idx}`, {
                    replace: true,
                });
            }
        }
    }, [rawAdCampaignManagedList, isAdCampaignManagedListSuccess]);

    return null;
};

export default AdRegisterRoutingHelper;
