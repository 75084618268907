import { MyAdOfferInfoOfferSpaceElementType } from "./MyAdOfferInfoOfferSpaceElementType";
import { TemplateInfoSpaceType } from "../../../templateInfo/TemplateInfoSpaceType";

export type MyAdOfferInfoOfferSpaceType = {
    ad_round_space_idx: string;
    created_at: string;
    offer_idx: string;
    offer_space_element: MyAdOfferInfoOfferSpaceElementType[]; // assuming any array for now
    offer_space_idx: string;
    presv_campaign_space_id: string;
    presv_campaign_space_name: string;
    presv_campaign_space_no: number;
    presv_campaign_space_preview_background_path: string;
    updated_at: string;
};

export const changeOfferSpaceListToTemplateSpaceList = function (
    offerSpaceList: MyAdOfferInfoOfferSpaceType[],
): TemplateInfoSpaceType[] {
    return offerSpaceList.map((offerSpace) => {
        return {
            campaign_space_idx: offerSpace.ad_round_space_idx,
            created_at: offerSpace.created_at,
            presv_campaign_space_guide_url: "",
            presv_campaign_space_id: offerSpace.presv_campaign_space_id,
            presv_campaign_space_name: offerSpace.presv_campaign_space_name,
            presv_campaign_space_no: offerSpace.presv_campaign_space_no,
            presv_campaign_space_preview_background_path:
                offerSpace.presv_campaign_space_preview_background_path,
            template_idx: "-1",
            template_space_element: offerSpace.offer_space_element.map(
                (offerSpaceEle) => {
                    return {
                        campaign_space_element_idx:
                            offerSpaceEle.ad_round_space_element_idx,
                        created_at: offerSpaceEle.created_at,
                        presv_campaign_space_element_background_bool:
                            offerSpaceEle.presv_campaign_space_element_background_bool,
                        presv_campaign_space_element_idx:
                            offerSpaceEle.offer_space_element_idx,
                        presv_campaign_space_element_img_height:
                            offerSpaceEle.presv_campaign_space_element_img_height,
                        presv_campaign_space_element_img_width:
                            offerSpaceEle.presv_campaign_space_element_img_width,
                        presv_campaign_space_element_name:
                            offerSpaceEle.presv_campaign_space_element_name,
                        presv_campaign_space_element_preview_offset:
                            offerSpaceEle.presv_campaign_space_element_preview_offset,
                        presv_campaign_space_element_text_length: -1,
                        presv_campaign_space_element_type:
                            offerSpaceEle.presv_campaign_space_element_type,
                        presv_campaign_space_element_img_additional_text_bool:
                            false,
                        presv_campaign_space_element_img_border_radius_bool:
                            false,
                        template_idx: "-1",
                        template_space_element_background_color:
                            offerSpaceEle.presv_template_space_element_background_color,
                        template_space_element_idx:
                            offerSpaceEle.offer_space_element_idx,
                        template_space_element_img_path:
                            offerSpaceEle.presv_template_space_element_img_path,
                        template_space_element_text:
                            offerSpaceEle.presv_template_space_element_text,
                        template_space_idx: offerSpaceEle.offer_space_idx,
                        updated_at: offerSpaceEle.updated_at,
                        presv_campaign_space_element_actual_ad_img_width: 0,
                        presv_campaign_space_element_actual_ad_img_height: 0,
                    };
                },
            ),
            template_space_idx: offerSpace.offer_space_idx,
            updated_at: offerSpace.updated_at,
        };
    });
};
